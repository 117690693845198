import { patchState, signalStore, withMethods, withState } from '@ngrx/signals'
import { IConfig } from '@modules/config/interfaces/config'

type IStateConfig = {
  config: IConfig | null
}

const initialStateConfig: IStateConfig = {
  config: null,
}
export const ConfigStore = signalStore(
  { providedIn: 'root' },
  withState(initialStateConfig),
  withMethods(store => {
    return {
      setConfig: (config: IConfig) => {
        patchState(store, {
          config,
        })
      },
    }
  })
)
