import { Directive, ElementRef, inject, Input, OnDestroy, OnInit, ViewChild } from '@angular/core'
import { Observable, of, Subject, switchMap } from 'rxjs'
import { Store } from '@ngrx/store'
import {
  EBenefitCategoryKey,
  EBenefitPaymentType,
  EBenefitPlanStatus,
  IBenefit,
  IBenefitCategory,
  IBenefitPurchase,
  IBenefitState,
  IBenefitSubscriptionDetails,
} from '@modules/benefit/interfaces/benefit'
import { IConfig } from '@modules/config/interfaces/config'
import {
  benefitCardsSelect,
  benefitIsLoadingSelect,
  benefitPurchaseSelect,
  benefitSelect,
  benefitsSelect,
  benefitSubscriptionDetailsSelect,
} from '@modules/benefit/selectors/benefit.selectors'
import { Browser } from '@capacitor/browser'
import { catchError, distinctUntilChanged, finalize, map, take, takeUntil, tap, withLatestFrom } from 'rxjs/operators'
import { NavigationExtras, Router } from '@angular/router'
import { Location } from '@angular/common'
import {
  benefitLoading,
  clearBenefitCards,
  setBenefit,
  setBenefitCategories,
  setBenefitList,
  setBenefitSubscriptionDetails,
} from '@modules/benefit/actions/benefit.actions'
import { ISuccessResponse } from '@services/resource.base.service'
import { BenefitResource } from '@modules/benefit/resources/benefit.resource'
import { IUser } from '@modules/user/interfaces/user'
import { IGiftCard } from '@modules/benefit/interfaces/gift-card'
import { UpdateAppService } from '@services/update-app.service'
import posthog from 'posthog-js'
import { ICard } from '@modules/benefit/interfaces/card'
import { ModalController, NavController } from '@ionic/angular'
import { BenefitTermsModalComponent } from '@modules/benefit/components/benefit-terms-modal/benefit-terms-modal.component'
import { BenefitCardListComponent } from '@modules/benefit/components/cards/card-list/card-list.component'
import {
  selectUser,
  servicesAvailablePaymentPlanSelector,
  showPennyCheckAlertSelector,
  userBalanceSelector,
} from '@modules/user/selectors/user.selectors'
import { ErrorService } from '@services/error.service'
import { IErrorResponse } from '@declarations/error-response'
import { UserModel } from '@modules/user/models/user.model'
import { SplitPaneService } from '@services/split-pane.service'
import { ETypePaymentMethod } from '@modules/checkout/checkout'
import { selectCheckoutCard } from '@modules/checkout/state/checkout.selectors'
import { StorageService } from '@services/storage.service'
import { Actions } from '@ngrx/effects'
import { ServiceAvailableService } from 'src/app/pages/application/tabs/home/modal/intermittence-app-modal/service-available.service'
import { EServicesAvailableStatus } from '@modules/user/models/services'
import { ICoupon } from '@modules/benefit/interfaces/coupon'
import { addIcons } from 'ionicons'
import { checkmarkOutline, chevronDownOutline, chevronUpOutline, folderOutline } from 'ionicons/icons'

@Directive()
export class BenefitService implements OnDestroy, OnInit {
  paymentMethod!: ETypePaymentMethod
  EBenefitPaymentType = EBenefitPaymentType
  public isLoading$
  user!: IUser
  @Input() public benefit!: IBenefit
  protected _unsubscribeAll: Subject<any> = new Subject<any>()
  benefit$
  cards$!: Observable<IGiftCard[] | ICard[] | null>
  benefits$!: Observable<IBenefit[]>
  purchase$!: Observable<IBenefitPurchase>
  user$: Observable<UserModel | null>
  showPennyCheckAlert$: Observable<boolean>
  chipsCards: number[] | null = null
  balance: number | null | undefined = null
  insufficientBalance = false
  coupon: ICoupon | null = null
  protected elementRef!: ElementRef
  @ViewChild('benefitCardListComponent') benefitCardListComponent!: BenefitCardListComponent
  protected _navController = inject(NavController)
  errorService = inject(ErrorService)
  protected _splitPaneService: SplitPaneService = inject(SplitPaneService)
  protected _modalController: ModalController = inject(ModalController)
  public config$
  public card$ = this._store.select(selectCheckoutCard)
  public actions$: Actions = inject(Actions)
  private storage: StorageService = inject(StorageService)
  public userBalance$ = this._store.select(userBalanceSelector)
  _intermittenceService = inject(ServiceAvailableService)
  servicesAvailablePaymentPlan$ = this._store.select(servicesAvailablePaymentPlanSelector)
  subscriptionDetails$ = this._store.select(benefitSubscriptionDetailsSelect)

  constructor(
    protected _store: Store<{ benefit: IBenefitState; config: IConfig }>,
    protected _router: Router,
    protected _benefitResource: BenefitResource,
    protected _location?: Location,
    protected _updateAppService?: UpdateAppService
  ) {
    this.isLoading$ = this._store.select(benefitIsLoadingSelect)
    this.purchase$ = this._store.select(benefitPurchaseSelect)
    this.benefit$ = this._store.select(benefitSelect)
    this.config$ = this._store.select('config')
    this.benefits$ = this._store.select(benefitsSelect)
    this.cards$ = this._store.select(benefitCardsSelect)
    this.showPennyCheckAlert$ = this._store.select(showPennyCheckAlertSelector)
    this._splitPaneService?.setPaneStatus(true)
    this.user$ = this._store.select(selectUser)
    addIcons({ chevronDownOutline, chevronUpOutline, checkmarkOutline, folderOutline })
  }

  async onOpenLink(url: string | null | undefined, satinize: boolean = false) {
    if (url) {
      url = satinize ? `https://${url}` : url
      await Browser.open({ url })
    }
  }

  // eslint-disable-next-line @angular-eslint/contextual-lifecycle
  ngOnInit() {
    this.getBenefit()
    this._intermittenceService.checkServicesAvailable(EServicesAvailableStatus.BENEFITS).then(response => {
      if (!response) {
        this._router.navigate(['/u/benefits/home'], {
          replaceUrl: true,
        })
      }
    })
  }

  getBenefit() {
    this.benefit$.pipe(takeUntil(this._unsubscribeAll), distinctUntilChanged()).subscribe(benefit => {
      if (benefit) {
        this.benefit = {
          ...this.benefit,
          ...benefit,
        }
      }
    })
  }

  getRemoteBenefitByCategory(event: null | any = null) {
    this._store.dispatch(benefitLoading({ loading: true }))
    return this._benefitResource.categories().pipe(
      withLatestFrom(this.user$),
      switchMap(([categories, user]) => {
        //switch map for request premium benefits flat for no show more info

        //if premium benefits is not empty has plan active, and return categories defaul
        if (categories.data[EBenefitCategoryKey.PREMIUM].benefits?.length > 0) {
          return of(categories)
        } else {
          //if not premium benefits, request premium benefits flat
          return this._benefitResource.premiums().pipe(
            map(response => {
              if (response['data'] && response['data']?.length) {
                categories.data[EBenefitCategoryKey.PREMIUM].benefits = response['data']?.map((benefit: IBenefit) => {
                  return {
                    ...benefit,
                    is_premium: true,
                  }
                })
              }
              return categories
            }),
            catchError(() => {
              return of(categories)
            })
          )
        }
      }),
      map(response => {
        //iterate object
        let categories: IBenefitCategory[] = []
        //recovery favorites ids
        const favoritesIds =
          response.data[EBenefitCategoryKey.FAVORITE].benefits?.map((benefit: IBenefit) => benefit.id) || []

        //emopty favorites benefits, for fill whith id
        response.data[EBenefitCategoryKey.FAVORITE].benefits = []

        //set flag liked for favorites benefits
        for (const [key, value] of Object.entries(response.data)) {
          categories.push({
            key: key,
            ...value,
            benefits: value.benefits
              ?.map((benefit: IBenefit) => {
                return {
                  ...benefit,
                  liked: favoritesIds.includes(benefit.id),
                }
              })
              .sort((a, b) => <number>a?.sort - <number>b?.sort),
          })
        }

        //recover benefits and set categories
        let benefits: IBenefit[] = []
        for (const category of categories) {
          if (category.benefits) {
            benefits = benefits.concat([
              ...category.benefits.map((benefit: IBenefit) => {
                return {
                  ...benefit,
                  category: categories
                    ?.filter(category => {
                      return category.benefits?.some(b => b.id === benefit.id)
                    })
                    ?.map(category => category.key),
                }
              }),
            ])
          }
        }

        //filter if no have benefits
        const fav = EBenefitCategoryKey.FAVORITE
        categories = categories
          .filter(row => {
            if (row.key === fav) {
              return true
            }
            return !!row.benefits?.length
          })
          .sort((a, b) => {
            return +(b.key === fav) - +(a.key === fav)
          })

        this._store.dispatch(setBenefitList({ benefits: benefits }))
        this._store.dispatch(setBenefitCategories({ categories: categories }))
        return benefits
      }),

      finalize(() => {
        setTimeout(() => {
          this._store.dispatch(benefitLoading({ loading: false }))
        })
        if (event?.target) {
          event?.target?.complete()
        }
      })
    )
  }

  ngOnDestroy() {
    this._unsubscribeAll.next(true)
    this._unsubscribeAll.complete()
  }

  /**
   * Go to back from stack
   */
  onToBack() {
    this._location?.back()
  }

  /**
   * Navigate to benefit
   */
  onToBenefitList() {
    this._navController.navigateForward(['/u/benefits'], {
      queryParamsHandling: 'merge',
      animated: true,
    })
  }

  /**
   * Get remote benefits
   * @param event
   * @returns {Observable<IBenefit[]>}
   */
  getRemoteBenefit(event: null | any = null): Observable<IBenefit[]> {
    this._store.dispatch(benefitLoading({ loading: true }))
    return this._benefitResource.list().pipe(
      map((response: ISuccessResponse<IBenefit[]>) => {
        return response.data?.sort((a, b) => <number>a?.sort - <number>b?.sort)
      }),
      finalize(() => {
        setTimeout(() => {
          this._store.dispatch(benefitLoading({ loading: false }))
          if (event?.target) {
            event?.target?.complete()
          }
        })
      }),
      tap((benefits: IBenefit[]) => {
        this._store.dispatch(setBenefitList({ benefits: benefits }))
      })
    )
  }

  recoverBenefit(): void {
    this.benefit$
      .pipe(takeUntil(this._unsubscribeAll), distinctUntilChanged(), withLatestFrom(this.benefits$))
      .subscribe(([benefit, benefits]) => {
        if (!benefit) {
          if (benefits?.length) {
            this.mathchAndSetBenefit(benefits)
            return
          }
          this.getRemoteBenefit(null).subscribe(response => {
            this.mathchAndSetBenefit(response)
          })
        }
      })
  }

  mathchAndSetBenefit(benefits: IBenefit[]): void {
    benefits?.forEach(benefit => {
      if (window.location.pathname.includes(<string>benefit.url) && benefit.url) {
        this.benefit = {
          ...benefit,
          ...this.benefit,
        }
        this._store.dispatch(setBenefit({ benefit: benefit }))
      }
    })
  }

  checkSubscription() {
    this.benefit$.pipe(take(1)).subscribe(benefit => {
      this._benefitResource
        .checkSubscription({
          benefit_id: benefit?.id,
        })
        .subscribe(() => {
          //success
        })
    })
  }

  createSubscription() {
    this.benefit$.pipe(take(1)).subscribe(benefit => {
      this._benefitResource
        .create({
          benefit_id: benefit?.id,
        })
        .subscribe(() => {
          //success
        })
    })
  }

  /**
   * Gets subscription details
   */
  getSubscriptionDetails(): void {
    this.subscriptionDetails$.pipe(take(1)).subscribe((subscriptionDetails?: IBenefitSubscriptionDetails | null) => {
      if (!subscriptionDetails) {
        this._benefitResource.subscriptionDetails().subscribe(subscriptionDetails => {
          this._store.dispatch(setBenefitSubscriptionDetails({ details: subscriptionDetails }))
        })
      }
    })
  }

  /**
   * @description select benefit and catch not found enable benefit in app for notify upgrade
   * @param {IBenefit} benefit
   */
  selectBenefit(benefit: IBenefit, from: 'list' | 'gallery' = 'list') {
    this._intermittenceService.checkServicesAvailable(EServicesAvailableStatus.BENEFITS).then(response => {
      if (!response) return
      const $set = { $set: { benefitName: benefit.name, benefitId: benefit.id, from: from } }
      if (!benefit.url) {
        posthog.capture(`Benefit - ${benefit.name} - Select Coming Soon`, $set)
        return
      }
      this._router
        .navigate([benefit.url], {
          queryParams: {
            id: benefit.id,
          },
        })
        .then(() => {
          posthog.capture(`Benefit - ${benefit.name} - Select`, $set)
        })
        .catch(e => {
          posthog.capture(`Benefit - ${benefit.name} - Select (App No Update)`, $set)
          this._updateAppService?.updateOpen()
        })
      this._store.dispatch(setBenefit({ benefit: benefit }))
    })
  }

  async onOpenViewMore() {
    const modal = await this._modalController?.create({
      component: BenefitTermsModalComponent,
      cssClass: 'auto-height modal-benefits-terms',
      componentProps: {
        benefit: this.benefit,
      },
    })
    return modal?.present()
  }

  async onOpenInstructions() {
    const modal = await this._modalController?.create({
      component: BenefitTermsModalComponent,
      cssClass: 'auto-height modal-benefits-terms',
      componentProps: {
        benefit: this.benefit,
        body: this.benefit.data?.['instructions'],
        title: 'Instrucciones de uso',
      },
    })
    return modal?.present()
  }

  /**
   * Refresh cards state
   * @param event
   */
  onRefreshList(event: any) {
    this._store.dispatch(clearBenefitCards())
    this.benefitCardListComponent.init(event)
  }

  /**
   * @description view did enter for pages show lists
   */
  ionViewDidEnterList() {}

  /**
   * @description method on init for pages show lists
   */
  ngOnInitList() {
    this.getBenefit()
    this.recoverBenefit()
  }

  processError(errorResponse: IErrorResponse): void {
    this.errorService.manipulateError(errorResponse)
  }

  processErrorWithCard(errorResponse: IErrorResponse): void {
    this.errorService.manipulateError(errorResponse)
  }

  onSelectBenefit(benefit: IBenefit) {
    this.user$.pipe(take(1)).subscribe(user => {
      this.selectBenefit(benefit)
    })
  }

  /**
   * Navigate to summary purchase
   */
  onToSummary(config?: NavigationExtras) {
    this.benefit$.pipe(take(1)).subscribe(benefit => {
      this._router.navigate([`${benefit?.url}/summary`], {
        ...config,
        queryParamsHandling: 'merge',
      })
    })
  }

  /**
   * Navigate to page gift card list
   * @param {NavigationExtras} extras
   */
  onToCards(extras?: NavigationExtras) {
    this.benefit$.pipe(take(1)).subscribe(benefit => {
      this._router.navigate([`${benefit?.url}`], {
        queryParamsHandling: 'merge',
        ...extras,
      })
    })
  }

  /**
   * Navigate to page for purcahse gift card
   */
  onToPurchase(extras?: NavigationExtras) {
    this.benefit$.pipe(take(1)).subscribe(benefit => {
      this._router.navigate([`${benefit?.url}/purchase`], {
        queryParamsHandling: 'merge',
        ...extras,
      })
    })
  }

  /**
   * Open modal unpaid subscription benefits
   */
  async openModalUnpaidSubscriptionBenefits() {
    this.user$.pipe(take(1)).subscribe(async user => {
      if (user?.profile?.benefitPlanSusbcription === EBenefitPlanStatus.UNPAID) {
        const flag = await this.storage.get('subscription_unpaid')
        if (flag) return
        this.storage.set('subscription_unpaid', true)
        const checkoutUnpaidModalComponent = await import(
          '@modules/checkout/components/checkout-unpaid-subscription-modal/checkout-unpaid-subscription-modal.component'
        ).then(m => m.CheckoutUnpaidSubscriptionModalComponent)
        const modal = await this._modalController.create({
          component: checkoutUnpaidModalComponent,
          animated: false,
          componentProps: {
            modal: true,
          },
          cssClass: 'modal-unpaid-subscription',
          id: 'modal-unpaid-subscription',
        })
        await modal.present()
      } else {
        this.storage.remove('subscription_unpaid')
      }
    })
  }
}
