import type { OnInit } from '@angular/core'
import { Component } from '@angular/core'
import { ActivatedRoute, Router, RouterModule } from '@angular/router'
import { environment } from '@environments/environment'
import { NavController } from '@ionic/angular'
import { SplitPaneService } from '@services/split-pane.service'
import { ContainerUnauthService } from './container-unauth.service'
import { StatusBar, Style } from '@capacitor/status-bar'
import { Capacitor } from '@capacitor/core'
import { CommonModule } from '@angular/common'
import { FormsModule } from '@angular/forms'
import {
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonIcon,
  IonList,
  IonListHeader,
  IonRouterOutlet,
  IonToolbar,
} from '@ionic/angular/standalone'

@Component({
  selector: 'app-container-unauth',
  templateUrl: './container-unauth.component.html',
  styleUrls: ['./container-unauth.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    IonHeader,
    IonContent,
    IonListHeader,
    IonToolbar,
    IonRouterOutlet,
    RouterModule,
    IonIcon,
    IonButton,
    IonList,
    IonButtons,
  ],
})
export class ContainerUnauthComponent implements OnInit {
  environment = environment
  goBack = false
  constructor(
    public containerUnauthService: ContainerUnauthService,
    private navCtrl: NavController,
    private _route: ActivatedRoute,
    protected _splitPaneService: SplitPaneService
  ) {}

  async ngOnInit() {
    this._route.queryParams.subscribe((data: any) => {
      this.goBack = data['goBack']
    })
    if (Capacitor.isNativePlatform()) {
      await StatusBar.setStyle({ style: Style.Light })
      if (Capacitor.getPlatform() === 'android') {
        await StatusBar.setBackgroundColor({ color: '#ffffff' })
      }
    }
  }

  onGoBack(): void {
    this.navCtrl.back()
  }
}
