import { Injectable } from '@angular/core'
import { ETutorialType, ITutorialOption } from '@modules/guide/tutorial/interfaces/tutorial.interface'
import { Store } from '@ngrx/store'
import { Observable, filter, firstValueFrom, map, of, take } from 'rxjs'
import { IUserState } from '@modules/user/interfaces/user'
import { selectProfileTutorials } from '@modules/user/selectors/user.selectors'
import { tutorialFinishedUpdate } from '@modules/user/actions/user.actions'
import { UserResource } from '@modules/user/resources/user.resource'

@Injectable({
  providedIn: 'root',
})
export class GlobalTutorialService {
  readonly options: Record<ETutorialType, ITutorialOption> = {
    home: {
      name: 'Inicio',
      icon: 'home',
      route: '/u/guide/tutorial/home',
      active: true,
      order: 0,
    },
    withdrawal: {
      name: 'Retiro',
      icon: 'card',
      route: '/u/guide/tutorial/withdrawal',
      active: true,
      order: 1,
    },
    records: {
      name: 'Historial',
      icon: 'clock',
      route: '/u/guide/tutorial/records',
      active: true,
      order: 2,
    },
    payment_service: {
      name: 'Pago de servicios',
      icon: 'payment-service',
      route: '/u/guide/tutorial/payment-service',
      active: true,
      order: 3,
    },
    topups: {
      name: 'Recargas',
      icon: 'topups',
      route: '/u/guide/tutorial/topups',
      active: true,
      order: 4,
    },
    benefits: {
      name: 'Beneficios',
      icon: 'diamond',
      route: '/u/guide/tutorial/benefits',
      active: true,
      order: 5,
    },
    gift_cards: {
      name: 'Tarjeta de Regalo',
      icon: 'gift',
      route: '/u/guide/tutorial/gift-cards',
      active: true,
      order: 6,
    },
    credit_cash: {
      name: 'PrestaMonto',
      icon: 'cash',
      route: '/u/guide/tutorial/credit-cash',
      active: true,
      order: 7,
    },
  }

  tutorials$: Observable<Record<ETutorialType, boolean> | undefined>

  constructor(
    private store: Store<{ user: IUserState }>,
    private resource: UserResource
  ) {
    this.tutorials$ = this.store.select(selectProfileTutorials)
  }

  /**
   * Gets tutorial option if not finished
   * @param type
   * @returns tutorial
   */
  async getTutorial(type: ETutorialType): Promise<ITutorialOption | null> {
    return await firstValueFrom(
      this.tutorials$.pipe(
        filter(tutorials => tutorials !== undefined),
        map(tutorials => {
          if (tutorials?.[type] === false) {
            return this.options[type] || null
          }
          return null
        })
      )
    )
  }

  /**
   * Sets tutorial finished in state and uploads tutorials status
   * @param type
   */
  async finishTutorial(type: ETutorialType): Promise<void> {
    const tutorials = await firstValueFrom(this.tutorials$.pipe(filter(tutorials => tutorials !== undefined)))

    if (tutorials?.[type] === true) {
      return
    }

    this.store.dispatch(tutorialFinishedUpdate({ key: type, value: true }))
    this.resource
      .tutorials({ data: { ...tutorials, [type]: true } })
      .pipe(take(1))
      .subscribe()
  }
}
