import { HttpClient } from '@angular/common/http'
import { Injectable, NgZone } from '@angular/core'
import { getMessaging, getToken, isSupported, onMessage } from '@angular/fire/messaging'
import { Capacitor } from '@capacitor/core'
import type { ActionPerformed, PushNotificationSchema, Token } from '@capacitor/push-notifications'
import { PushNotifications } from '@capacitor/push-notifications'
import { environment } from '@environments/environment'
import { notificationIndicatorNew } from '@modules/notification/actions/notification.actions'
import type { INotificationState } from '@modules/notification/interfaces/notification'
import { NotificationService } from '@modules/notification/services/notification.service'
import { Store } from '@ngrx/store'
import type { Observable } from 'rxjs'
import { ToastService } from '@services/toast.service'
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms'
import { TokenPayload } from '@payloads/fbToken.payload'
import { SessionService } from '@services/session.service'

@Injectable({
  providedIn: 'root',
})
export class FcmService {
  token!: string
  pushReceived!: boolean

  tokenForm: UntypedFormGroup = this.fb.group({
    content: null,
  })

  constructor(
    private fb: UntypedFormBuilder,
    private httpClient: HttpClient,
    private _toastService: ToastService,
    private _notificationService: NotificationService,
    private _store: Store<{ notification: INotificationState }>,
    private _ngZone: NgZone,
    private _sessionService: SessionService
  ) {
    /** Do nothing */
  }

  initPush(): void {
    if (Capacitor.getPlatform() !== 'web') {
      this.registerPush()
    } else {
      try {
        isSupported().then((result: boolean) => {
          if (result) {
            this.registerPushWeb()
          }
        })
      } catch (error) {
        console.debug(error)
      }
    }
  }
  async initSetToken() {
    if (!this.token) return
    this.tokenForm.patchValue({ content: this.token })
    const payload = new TokenPayload(this.tokenForm)
    if (payload.toJSONObject()?.notification_token?.content) {
      if (!(await this._sessionService.getSession())) {
        return
      }
      this.registrationToken(payload.toJSONObject()).subscribe(
        () => {
          this.tokenForm.reset()
        },
        () => {
          //no session
        }
      )
    }
  }

  registerPushWeb(): void {
    const messaging = getMessaging()
    getToken(messaging, {
      vapidKey: environment.firebase.vapidKey,
    })
      .then(currentToken => {
        if (currentToken) {
          this.token = currentToken

          this.initSetToken()

          onMessage(messaging, payload => {
            this.pushReceived = true
            // show notification toast
            this._notificationService.createNotification({
              ...payload?.notification,
              ...payload?.data,
            })

            // update indicator new notifications
            this._store.dispatch(notificationIndicatorNew({ indicator: true }))
          })
        } else {
          // Show permission request UI
          console.debug('No registration token available. Request permission to generate one.')
        }
      })
      .catch(err => {
        console.debug('An error occurred while retrieving token. ', err)
      })
  }

  registrationToken(payload: unknown): Observable<unknown> {
    return this.httpClient.post(`${environment.API}/notification_tokens`, payload)
  }

  private registerPush(): void {
    PushNotifications.requestPermissions().then(result => {
      if (result.receive === 'granted') {
        PushNotifications.register()
      }
    })

    PushNotifications.addListener('registration', (token: Token) => {
      this.token = token.value
      this.initSetToken()
    })

    PushNotifications.addListener('pushNotificationReceived', (notification: PushNotificationSchema) => {
      this._ngZone.run(() => {
        this._store.dispatch(notificationIndicatorNew({ indicator: true }))
        this._notificationService.createNotification(notification)
        this.pushReceived = true
      })
    })

    PushNotifications.addListener('pushNotificationActionPerformed', (notification: ActionPerformed) => {
      this._notificationService.createNotification(notification?.notification)
    })

    PushNotifications.addListener('registrationError', (error: any) => {
      console.debug(`Error on registration: ${JSON.stringify(error)}`)
    })
  }
}
