import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { RouterModule } from '@angular/router'
import { ContentWebModule } from '@components/content-web/content-web.module'
import { PopoverHelperComponent } from '@components/popover-helper/popover-helper.component'
import { ToolbarModule } from '@components/toolbar/toolbar.module'
import { IonicModule } from '@ionic/angular'
import { ConfigModule } from '@modules/config/config.module'
import { CurrencyMaskDirective } from './directives/currency-mask.directive'
import { SharedPipesModule } from '@pipes/shared-pipes.module'
import { InputModule } from '@components/input/input.module'

@NgModule({
  declarations: [CurrencyMaskDirective, PopoverHelperComponent],
  imports: [
    CommonModule,
    InputModule,
    IonicModule,
    ReactiveFormsModule,
    FormsModule,
    ConfigModule,
    RouterModule,
    ContentWebModule,
    ToolbarModule,
    SharedPipesModule,
  ],
  exports: [
    ReactiveFormsModule,
    FormsModule,
    InputModule,
    CurrencyMaskDirective,
    PopoverHelperComponent,
    ContentWebModule,
    ToolbarModule,
  ],
})
export class SharedModule {}
