import { Injectable } from '@angular/core'
import { ToastController, ToastOptions } from '@ionic/angular'

export type IToastOptions = ToastOptions

@Injectable({ providedIn: 'root' })
export class ToastService {
  private options: IToastOptions = {
    position: 'top',
    duration: 5000,
    id: 'toast-notification',
  }

  constructor(public toastController: ToastController) {}

  async success(config: IToastOptions = {}): Promise<any> {
    return this._display({
      ...this.options,
      color: 'success',
      ...config,
    })
  }

  async danger(config: IToastOptions = {}): Promise<any> {
    return this._display({
      ...this.options,
      color: 'danger',
      ...config,
    })
  }

  async warning(config: IToastOptions = {}): Promise<any> {
    return this._display({
      ...this.options,
      color: 'warning',
      ...config,
    })
  }

  async light(config: IToastOptions = {}): Promise<any> {
    return this._display({
      ...this.options,
      color: 'light',
      ...config,
    })
  }

  async notification(config: IToastOptions = {}): Promise<any> {
    return this._display({
      ...this.options,
      color: 'tertiary',
      ...config,
    })
  }

  private async _display(config: IToastOptions = {}): Promise<any> {
    const toast = await this.toastController.create(config)
    await toast.present()
    return toast
  }
}
