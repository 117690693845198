import type { INotification, INotificationState } from './../interfaces/notification'
import { createReducer, on } from '@ngrx/store'
import {
  notificationsLoading,
  notificationsLoaded,
  notificationsClear,
  notificationIndicatorNew,
  notificationReceived,
} from '../actions/notification.actions'

import type { EntityAdapter } from '@ngrx/entity'
import { createEntityAdapter } from '@ngrx/entity'

export const adapter: EntityAdapter<INotification> = createEntityAdapter<INotification>()

export const initialState: INotificationState = adapter.getInitialState({
  loading: true,
  entity: null,
  entities: null,
  indicatorNew: false,
  notification: null,
})

const _notificationReducer = createReducer(
  initialState,
  on(notificationsLoaded, (state, { entities }) => ({
    ...state,
    entities,
  })),
  on(notificationsLoading, (state, { loading }) => ({
    ...state,
    loading,
  })),
  on(notificationIndicatorNew, (state, { indicator }) => ({
    ...state,
    indicatorNew: indicator,
  })),
  on(notificationReceived, (state, { notification }) => ({
    ...state,
    notification,
  })),
  on(notificationsClear, state => ({
    ...state,
    entities: null,
    searchParams: null,
  }))
)

export const notificationReducer = (state: any, action: any): any => _notificationReducer(state, action)
export const { selectAll, selectIds, selectEntities } = adapter.getSelectors()
