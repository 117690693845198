import { Injectable } from '@angular/core'
import { Actions, createEffect, ofType } from '@ngrx/effects'
import { tap } from 'rxjs'
import { recordsClearAll } from '@modules/record/actions/record.actions'
import { clearBenefitCards } from '@modules/benefit/actions/benefit.actions'
import { withdrawalsClear } from '@modules/withdrawal/actions/withdrawal.actions'
import { checkoutClear, checkoutClearCard } from '@modules/checkout/state/checkout.actions'
import { Store } from '@ngrx/store'
import { userPutBalance } from '@modules/user/actions/user.actions'

@Injectable()
export class RecordEffects {
  recordClear$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(recordsClearAll),
        tap(action => {
          this._store.dispatch(clearBenefitCards())
          this._store.dispatch(withdrawalsClear())
          this._store.dispatch(checkoutClear())
          this._store.dispatch(checkoutClearCard())
          this._store.dispatch(
            userPutBalance({
              balance: undefined,
              balanceFuture: undefined,
            })
          )
        })
      ),
    { dispatch: false }
  )

  constructor(
    private actions$: Actions,
    private _store: Store
  ) {}
}
