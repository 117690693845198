import type { IWithdrawalState } from './../interfaces/withdrawal'
import { createFeatureSelector, createSelector } from '@ngrx/store'

export const withdrawalFeatureSelector = createFeatureSelector<IWithdrawalState>('withdrawal')

export const getAllWithdrawal = createSelector(withdrawalFeatureSelector, state => state.entities)
export const getAllWithdrawalOrigin = createSelector(withdrawalFeatureSelector, state => state.origin)
export const withdrawalCycleSelect = createSelector(withdrawalFeatureSelector, state => state.cycle)
export const getWithdrawalAllow = createSelector(withdrawalFeatureSelector, state => state.withdrawalAllow)
export const getAllWithdrawalSearchParams = createSelector(withdrawalFeatureSelector, state => state.searchParams)

export const areWithdrawalsLoaded = createSelector(
  withdrawalFeatureSelector,
  state => state.entities?.length && state.entities?.length > 0
)
export const selectWithdrawalLoading = createSelector(withdrawalFeatureSelector, state => state?.loading)

export const selectWithdrawalNew = createSelector(withdrawalFeatureSelector, state => state?.entityNew)
