import { ChangeDetectionStrategy, Component } from '@angular/core'

@Component({
  selector: 'app-notification-loading',
  templateUrl: './notification-loading.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NotificationLoadingComponent {
  constructor() {
    /** Do nothing */
  }
}
