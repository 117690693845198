import { Injectable } from '@angular/core'
import { IResourceMethodObservable, ResourceAction, ResourceParams, ResourceRequestMethod } from '@ngx-resource/core'
import { ResourceBaseService } from '@services/resource.base.service'
import { environment } from '@environments/environment'

@Injectable()
@ResourceParams({
  pathPrefix: 'rewards',
  url: environment.API + '/api/v3/',
})
export class RedeemResource extends ResourceBaseService {}
