import { Pipe, PipeTransform } from '@angular/core'
import { DomSanitizer, SafeHtml, SafeStyle, SafeScript, SafeUrl, SafeResourceUrl } from '@angular/platform-browser'

@Pipe({
  name: 'sanitizeHtml',
  standalone: true,
})
export class SanitizeHtmlPipe implements PipeTransform {
  /**
   *
   * @param sanitizer
   */
  constructor(private sanitizer: DomSanitizer) {}

  public transform(v: string | null | undefined, type: string): SafeHtml | SafeStyle | SafeScript | SafeUrl | SafeResourceUrl {
    switch (type) {
      case 'html':
        return this.sanitizer.bypassSecurityTrustHtml(v || '')
      case 'style':
        return this.sanitizer.bypassSecurityTrustStyle(v || '')
      case 'url':
        return this.sanitizer.bypassSecurityTrustUrl(v || '')
      case 'resourceUrl':
        return this.sanitizer.bypassSecurityTrustResourceUrl(v || '')
      default:
        return this.sanitizer.bypassSecurityTrustHtml(v || '')
    }
  }
}
