import { createFeatureSelector, createSelector } from '@ngrx/store'
import { IBenefit, IBenefitPurchase, IBenefitState } from '../interfaces/benefit'
import { selectAllBenefits } from '../reducers/benefit.reducer'
export const benefitFeatureSelector = createFeatureSelector<IBenefitState>('benefit')
export const benefitIsLoadingSelect = createSelector(benefitFeatureSelector, state => state.loading)
export const benefitSelect = createSelector(benefitFeatureSelector, state => state.benefit as IBenefit)
export const benefitPurchaseSelect = createSelector(benefitFeatureSelector, state => state.purchase as IBenefitPurchase)
export const benefitsSelect = createSelector(benefitFeatureSelector, selectAllBenefits)
export const benefitsCategoriesSelect = createSelector(benefitFeatureSelector, state => state.categories)
export const benefitCategorySelect = createSelector(benefitFeatureSelector, state => state.category)
export const benefitCardsSelect = createSelector(benefitFeatureSelector, state => state.cards)
export const benefitSuccessSelect = createSelector(benefitFeatureSelector, state => state.success)
export const benefitPremiumPlanSummarySelect = createSelector(benefitFeatureSelector, state => state.summary)
export const benefitSubscriptionDetailsSelect = createSelector(
  benefitFeatureSelector,
  state => state.subscriptionDetails
)
export const benefitRelevantSubscriptionSelect = createSelector(
  benefitFeatureSelector,
  state => state.subscriptionDetails?.relevant_subscription
)
export const benefitSubscriptionPlansSelect = createSelector(
  benefitFeatureSelector,
  state => state.subscriptionDetails?.actual_cost_details
)
export const benefitConfirmSubscriptionModeSelect = createSelector(
  benefitFeatureSelector,
  state => state.confirmSupscriptionMode
)
