<ion-grid fixed="true" class="text-dark">
  <ion-row class="px-4 pb-4 h-full pt-4" fixed="true">
    <ion-col size="12" class="flex justify-end" *ngIf="!force">
      <a href="javascript:" (click)="dismiss()">
        <ion-icon name="close-outline" class="text-dark" size="large"></ion-icon>
      </a>
    </ion-col>
    <ion-col size="12">
      <h4 class="text-center font-semibold m-0 text-2xl">Actualización</h4>
    </ion-col>
    <ion-col size="12" class="ion-padding-vertical text-center">
      <img class="mx-auto" src=" assets/icon/update.svg" />
    </ion-col>
    <ion-col size="12" class="text-center ion-padding-bottom py-4">
      <p class="tracking-normal">Para seguir disfrutando de Monto, es importante que actualices tu aplicación.</p>
    </ion-col>
    <ion-col size="12" class="webkit-center">
      <ion-button expand="block" (click)="onUpdateOpen()"> Actualizar </ion-button>
    </ion-col>
    <ion-col size="12" class="text-center" *ngIf="!force">
      <ion-button color="light" expand="block" (click)="dismiss()"> Más tarde </ion-button>
    </ion-col>
  </ion-row>
</ion-grid>
