<div part="notification" [attr.template]="custom?.template" [attr.theme]="custom?.theme" class="flex flex-col gap-1">
  @if (custom?.style?.imageTop?.src) {
    @if (imageTop) {
      <img part="imageTop" [src]="custom?.style?.imageTop?.src" />
    } @else {
      <div part="imageTop" [innerHTML]="custom?.style?.imageTop?.src | sanitizeHtml: 'html'"></div>
    }
  }
  <section class="flex justify-end pb-0 z-10" part="close">
    <ion-button class="m-0 -mr-1" slot="end" part="buttonClose" (click)="onDismiss()" fill="clear">
      <ion-icon size="large" slot="icon-only" class="h-5 w-5" name="close-outline"></ion-icon>
    </ion-button>
  </section>
  <section part="container">
    <main part="main">
      @if (notification?.title) {
        <section part="title" [innerHTML]="notification?.title | sanitizeHtml: 'html'"></section>
      }
      @if (notification?.body) {
        <section part="body" [innerHTML]="notification?.body | sanitizeHtml: 'html'" class="text-center"></section>
      }
      @if (notification?.image) {
        <section part="image">
          @if (image) {
            <img [src]="notification?.image" />
          } @else {
            <div [innerHTML]="notification?.image | sanitizeHtml: 'html'"></div>
          }
        </section>
      }
    </main>
    @if (notification?.buttons?.length) {
      <footer part="footer" class="bg-transparent">
        <section part="buttons">
          @for (button of notification?.buttons; track $index) {
            <ion-button
              [attr.part]="notification?.buttons?.length > 1 ? 'button-' + $index : 'button'"
              expand="block"
              class="max-w-button"
              (click)="onAction(button?.link)"
              [color]="button?.color ? button?.color : 'light'"
            >
              <div [innerHTML]="button?.message | sanitizeHtml: 'html'"></div>
            </ion-button>
          }
        </section>
      </footer>
    }
  </section>
  @if (custom?.style?.imageBottom?.src) {
    @if (imageBottom) {
      <img part="imageBottom" [src]="custom?.style?.imageBottom?.src" />
    } @else {
      <div part="imageBottom" [innerHTML]="custom?.style?.imageBottom?.src | sanitizeHtml: 'html'"></div>
    }
  }
</div>
