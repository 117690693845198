<ng-template #headerTpl>
  <div>
    <h2>Notificaciones</h2>
  </div>
</ng-template>
<app-content-main [templateHeader]="headerTpl" [menu]="false">
  <ion-refresher slot="fixed" (ionRefresh)="getEntities($event)">
    <ion-refresher-content></ion-refresher-content>
  </ion-refresher>
  <app-content-web direction="left">
    <div class="ion-text-center ion-align-self-center not-found" *ngIf="!entities && !isLoading">
      <div class="self-center">
        <ion-icon
          name="mail-outline"
          size="large"
          color="light"
          class="rounded-full p-3 bg-accent text-5xl h-18 w-18 mx-auto align-middle self-center"
        ></ion-icon>
        <p class="pt-2">Por el momento no hay notificaciones</p>
      </div>
    </div>
    <ng-container *ngIf="isLoading">
      <div class="ion-padding-bottom w-full">
        <ion-text color="primary" class="ion-text-end">
          <ion-skeleton-text animated class="w-full"></ion-skeleton-text>
        </ion-text>
        <div *ngFor="let item of [1, 2, 3, 4, 5, 6, 7, 8, 9, 10]" lines="none">
          <app-notification-loading></app-notification-loading>
        </div>
      </div>
    </ng-container>
    <div *ngIf="entities">
      <div>
        <div *ngFor="let date of objectKeys(entities)" class="mb-6">
          <ion-text color="primary" class="ion-text-end font-semibold tracking-wide">
            {{ date | date: 'longDate' }}
          </ion-text>
          <div *ngFor="let item of entities[date]; trackBy: trackByMethod">
            <ng-container *ngIf="item.is_loading">
              <app-notification-loading></app-notification-loading>
            </ng-container>
            <app-notification-card [notification]="item" [date]="date" [swipe]="canSwipe" (delete)="onDelete($event)">
            </app-notification-card>
          </div>
        </div>
      </div>
    </div>
    <ion-infinite-scroll threshold="100px" (ionInfinite)="getEntities($event)">
      <ion-infinite-scroll-content loadingSpinner="bubbles" loadingText="Cargando información...">
      </ion-infinite-scroll-content>
    </ion-infinite-scroll>
  </app-content-web>
</app-content-main>
