import { createAction, props } from '@ngrx/store'
import { IPaymentNew } from '../interfaces/payment'

export const paymentsOriginLoaded = createAction(
  '[Payments List] Set list Payments Orgin',
  props<{
    origin: any
  }>()
)

export const paymentsLoading = createAction('[Payments List] Set Loading', props<{ loading: boolean }>())

//create payment.actions.ts
export const setPaymentNew = createAction(
  '[Payment New] Set data for create payment',
  props<{ payment: Partial<IPaymentNew> }>()
)
export const clearPaymentNew = createAction('[Payment New] Clear data for create payment')
