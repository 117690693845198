import { createAction, props } from '@ngrx/store'
import { ICheckoutCard, ICheckoutPaymentMethod } from '@modules/checkout/checkout'

export const checkoutLoading = createAction('[Checkout] Set Loading', props<{ loading: boolean }>())
export const checkoutSetCards = createAction('[Checkout] Set Cards', props<{ cards: ICheckoutCard[] }>())
export const checkoutClearCards = createAction('[Checkout] Clear Cards')
export const checkoutSetCard = createAction('[Checkout] Set Card', props<{ card: ICheckoutCard | null }>())
export const checkoutRemoveCard = createAction('[Checkout] Remove Card', props<{ card: ICheckoutCard }>())
export const checkoutAddCard = createAction('[Checkout] Add Card', props<{ card: ICheckoutCard }>())
export const checkoutAddImage = createAction('[Checkout] Add Image', props<{ image: string }>())
export const checkoutRemoveImage = createAction('[Checkout] Remove Image')
export const checkoutSetUrlBack = createAction('[Checkout] Set Url Back', props<{ url: string | null }>())
export const checkoutClearCard = createAction('[Checkout] Clear Card')
export const checkoutClear = createAction('[Checkout] Clear')
export const checkoutNextStep = createAction('[Checkout] Next Step')
export const checkoutPaymentSuccess = createAction('[Checkout] Payment Success')
export const checkoutSetPaymentMethods = createAction(
  '[Checkout] Set Payment Methods',
  props<{ paymentMethods: ICheckoutPaymentMethod[] }>()
)
export const checkoutSetPaymentMethod = createAction(
  '[Checkout] Set Payment Method',
  props<{ paymentMethod: ICheckoutPaymentMethod }>()
)
