import { PlatformService } from '@services/platform.service'
import type { OnInit } from '@angular/core'
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, ViewChild } from '@angular/core'
import { Router } from '@angular/router'
import { IonInfiniteScroll, ModalController, NavController } from '@ionic/angular'
import { notificationIndicatorNew } from '@modules/notification/actions/notification.actions'
import type { INotification, INotificationState } from '@modules/notification/interfaces/notification'
import { Store } from '@ngrx/store'
import { PlatformWebService } from '@services/platform-web.service'
import { SplitPaneService } from '@services/split-pane.service'
import { StorageService } from '@services/storage.service'
import moment from 'moment'
import { NotificationService } from '../../services/notification.service'
import { finalize } from 'rxjs/operators'
import { addIcons } from 'ionicons'
import { chevronDownOutline, chevronUpOutline, mailOutline, trashOutline } from 'ionicons/icons'

@Component({
  selector: 'app-notification-list',
  templateUrl: './notification-list.component.html',
  styleUrls: ['./notification-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NotificationListComponent extends PlatformWebService implements OnInit {
  @ViewChild(IonInfiniteScroll) infiniteScroll!: IonInfiniteScroll
  entities!: Record<string, INotification[]>
  isLoading = true
  canSwipe = true
  pagination: {
    current_page?: any
    next_page?: any
    prev_page?: any
    total_count?: any
    total_pages?: any
  } | null = {
    next_page: 1,
  }

  objectKeys = Object.keys

  constructor(
    private _notificationService: NotificationService,
    protected override _navCtrl: NavController,
    protected override _router: Router,
    private _crd: ChangeDetectorRef,
    protected _modalCtrl: ModalController,
    private _storageService: StorageService,
    protected override _splitPaneService: SplitPaneService,
    private _store: Store<{ notification: INotificationState }>,
    protected _elementRef: ElementRef,
    private platform: PlatformService
  ) {
    super()
    addIcons({ mailOutline, trashOutline, chevronDownOutline, chevronUpOutline })
    //Let cards delete swipe if resolution width is less than  768px
    //Else shows eliminate button inside card
    this.canSwipe = !this.platform.isSm()
  }

  override ngOnInit(): void {
    super.ngOnInit()
    this.getEntities()
  }

  onDelete({ date, item }: Record<string, any>): void {
    this.entities[date] = this.entities[date].filter((row: INotification) => item.id !== row.id)
    if (this.entities[date].length === 0) {
      delete this.entities[date]
    }
    item.is_loading = false
    this._crd.detectChanges()
  }

  trackByMethod(index: number, el: any): number {
    return el?.id || index
  }

  getEntities(event: any = null): void {
    if (event?.type === 'ionRefresh') {
      this.pagination.next_page = 1
      this.infiniteScroll.disabled = false
    }
    this.isLoading = true
    this._notificationService
      .getNotifications({ page: this.pagination?.next_page || 1 })
      .pipe(
        finalize(() => {
          this.isLoading = false
          if (event) {
            event.target.complete()
          }
          this._crd.detectChanges()
        })
      )
      .subscribe((response: { meta: { pagination: any }; notifications: INotification[] }) => {
        const entities: INotification[] = response?.notifications?.map(entity => {
          let tmp = document.createElement('DIV')
          tmp.innerHTML = entity.body?.replace(/<style.*?<\/style>/g, '') || ''
          entity.body_clean = (tmp.textContent || tmp.innerText || '')?.trim()
          tmp.innerHTML = entity.title?.replace(/<style.*?<\/style>/g, '') || ''
          entity.title_clean = (tmp.textContent || tmp.innerText || '')?.trim()
          return entity
        })
        this.pagination = response?.meta?.pagination

        // check if not notifications
        if (response?.notifications.length === 0) {
          this.infiniteScroll.disabled = true
          return
        }
        let data: Record<string, INotification[]> = {}

        // set date last notifications
        this._storageService.set('LAST_NOTIFICATIONS_ID', entities[0].id)

        // recovery date and group by date
        data = this._reduceData(entities)

        // add new row for infinity scroll
        if (this.entities && event?.type === 'ionInfinite') {
          for (const [key, value] of Object.entries(data)) {
            this.entities[key] = [...(this.entities[key] || []), ...value]
          }
          // disable infinity
          if (this.pagination?.next_page === null) {
            this.infiniteScroll.disabled = true
          }
        } else {
          this.entities = data
        }
        this._crd.detectChanges()
      })
  }

  private _reduceData(entities: any): Record<string, INotification[]> {
    return entities
      .map((row: INotification) => {
        row.date = moment(row.created_at).format('YYYY-MM-DD')
        return row
      })
      .reduce((acc: { [x: string]: any }, cval: { [x: string]: any }) => {
        const myAttribute = cval['date']
        acc[myAttribute] = [...(acc[myAttribute] || []), cval]
        return acc
      }, {})
  }
}
