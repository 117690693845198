import { HttpClient, HttpHeaders } from '@angular/common/http'
import { Injectable } from '@angular/core'
import type { IProfile } from '@modules/user/interfaces/profile'
import type { IServerEmptyResponse } from '@declarations/server-empty-response'
import { environment } from '@environments/environment'
import { userLoading, userLoadRemote } from '@modules/user/actions/user.actions'
import type { IUserState } from '@modules/user/interfaces/user'
import { Store } from '@ngrx/store'
import { firstValueFrom, lastValueFrom, Observable } from 'rxjs'
import { debounceTime, filter, map } from 'rxjs/operators'
import { SessionService } from './session.service'
import { loadSiteTree } from '@modules/app/actions/site-tree.actions'
import { ISiteTreeState } from '@modules/app/interfaces/site-tree'
import { selectUser, selectUserLoadLoading } from '@modules/user/selectors/user.selectors'
import { autoSkip } from 'chart.js/dist/core/core.scale.autoskip'

@Injectable({ providedIn: 'root' })
export class ProfileService {
  constructor(
    private httpClient: HttpClient,
    private _store: Store<{ user: IUserState; siteTree: ISiteTreeState }>,
    private _sessionService: SessionService
  ) {
    /** Do nothing */
  }

  updateProfile(payload: FormData): Observable<IServerEmptyResponse> {
    return this.httpClient
      .put(`${environment.API}/profile`, payload)
      .pipe(map((response: IServerEmptyResponse) => response))
  }

  updateDataProfile(payload: any): Observable<IProfile> {
    const headers = new HttpHeaders().set('Accept', 'application/vnd.mto.v2+json').set('X-Client', 'admin')
    return this.httpClient
      .put(`${environment.API}/api/v2/users/profile`, payload, { headers: headers })
      .pipe(map((response: IProfile) => response))
  }

  async getAndSetProfile(): Promise<void> {
    const token = await this._sessionService.getSession()
    if (token) {
      this._store.dispatch(userLoadRemote())
      this._store.dispatch(loadSiteTree())
      await firstValueFrom(this._store.select(selectUserLoadLoading).pipe(filter(isLoading => isLoading === false)))
      return Promise.resolve()
    }
    return Promise.resolve()
  }
}
