<div class="input-errors" *ngIf="invalid || (control?.invalid && currentError)">
  <p
    [ngClass]="{
      'input-errors-warning': currentError?.type === 'warning' || !currentError,
      'input-errors-danger': currentError?.type === 'error'
    }"
  >
    {{ currentError?.message || customError }}
  </p>
</div>
