import { APP_INITIALIZER, ApplicationConfig, ErrorHandler, importProvidersFrom, LOCALE_ID } from '@angular/core'
import { provideAnalytics, getAnalytics, ScreenTrackingService } from '@angular/fire/analytics'
import { provideFirebaseApp, initializeApp } from '@angular/fire/app'
import { provideMessaging, getMessaging } from '@angular/fire/messaging'
import { provideRemoteConfig, getRemoteConfig } from '@angular/fire/remote-config'
import {
  NoPreloading,
  PreloadAllModules,
  provideRouter,
  Router,
  RouteReuseStrategy,
  withInMemoryScrolling,
  withPreloading,
  withRouterConfig,
} from '@angular/router'
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http'
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'
import { IconModule } from '@components/atoms/icon/icon.module'
import { IonicModule, IonicRouteStrategy } from '@ionic/angular'
import { IonicStorageModule } from '@ionic/storage-angular'
import { SiteTreeEffects } from '@modules/app/effects/site-tree.effect'
import { siteTreeReducer } from '@modules/app/reducers/site-tree.reducer'
import { benefitReducer } from '@modules/benefit/reducers/benefit.reducer'
import { biometricReducer } from '@modules/biometric/state/biometric.reducer'
import { checkoutReducer } from '@modules/checkout/state/checkout.reducer'
import { ConfigModule } from '@modules/config/config.module'
import { configReducer } from '@modules/config/reducers/config.reducer'
import { creditCashReducer } from '@modules/credit-cash/reducers/credit-cash.reducer'
import { giftCardReducer } from '@modules/gift-card/reducers/gift-card.reducer'
import { montoCashReducer } from '@modules/monto-cash/reducers/monto-cash.reducer'
import { notificationReducer } from '@modules/notification/reducers/notification.reducer'
import { paymentServiceReducer } from '@modules/payment-services/reducers/payment-services.reducer'
import { paymentReducer } from '@modules/payment/reducers/payment.reducer'
import { RecordEffects } from '@modules/record/effects/record.effect'
import { recordReducer } from '@modules/record/reducers/record.reducer'
import { UserEffects } from '@modules/user/effects/user.effect'
import { userReducer } from '@modules/user/reducers/user.reducer'
import { WithdrawalEffects } from '@modules/withdrawal/effects/withdrawal.effect'
import { withdrawalReducer } from '@modules/withdrawal/reducers/withdrawal.reducer'
import { EffectsModule } from '@ngrx/effects'
import { ActionReducer, MetaReducer, StoreModule } from '@ngrx/store'
import { StoreDevtoolsModule } from '@ngrx/store-devtools'
import { ResourceModule } from '@ngx-resource/handler-ngx-http'
import { OpenNativeSettings } from '@awesome-cordova-plugins/open-native-settings/ngx'
import { Camera } from '@awesome-cordova-plugins/camera/ngx'
import { ApiVersionInterceptor } from '@interceptors/http/api-version.interceptor'
import { AppVersionInterceptor } from '@interceptors/http/app-version.interceptor'
import { AuthorizationInterceptor } from '@interceptors/http/authorization.interceptor'
import { ClientTypeInterceptor } from '@interceptors/http/client-type.interceptor'
import { SiteTreeResource } from '@modules/app/resources/site-tree.resource'
import { BiometricService } from '@modules/biometric/services/biometric.service'
import { ConfigService } from '@modules/config/services/config.service'
import { GlobalTutorialService } from '@modules/guide/services/global-tutorial.service'
import { MontoCashResource } from '@modules/monto-cash/resources/monto-cash.resource'
import { VerifyCredentialsService } from '@modules/user/components/auth/verify-credentials/verify-credentials.service'
import { UserResource } from '@modules/user/resources/user.resource'
import { ChatbotService } from '@services/chatbot.service'
import { ClarityService } from '@services/clarity.service'
import { LoginService } from '@services/login.service'
import { LogoutService } from '@services/logout.service'
import { ProfileService } from '@services/profile.service'
import { ResourceBaseService } from '@services/resource.base.service'
import { SplitPaneService } from '@services/split-pane.service'
import { StorageService } from '@services/storage.service'
import { CookieService } from 'ngx-cookie-service'
import { File } from '@ionic-native/file/ngx'

import { Capacitor } from '@capacitor/core'
import { provideIonicAngular } from '@ionic/angular/standalone'
import { referReducer } from '@modules/refer/reducers/refer.reducer'
import { routes } from './app-routing.module'
import moment from 'moment-timezone'
import * as Sentry from '@sentry/angular'
import { BrowserTracing } from '@sentry/tracing'
import { ServiceAvailableService } from './pages/application/tabs/home/modal/intermittence-app-modal/service-available.service'
import { environment } from '@environments/environment'
import { BrowserModule } from '@angular/platform-browser'
import { registerLocaleData } from '@angular/common'
import localeMx from '@angular/common/locales/es-MX'
import { MontoWithdrawService } from '@modules/withdrawal/services/withdrawal.service'
import { BenefitResource } from '@modules/benefit/resources/benefit.resource'
import { PaymentServicesResource } from '@modules/payment-services/resources/payment-services.resource'
import { PaymentServicesEffects } from '@modules/payment-services/effects/payment-services.effect'
import { MontoCashEffects } from '@modules/monto-cash/effects/monto-cash.effect'
import { CheckoutEffects } from '@modules/checkout/state/checkout.effect'
import { ConfigStore } from '@modules/config/config.store'
import { AppStore } from '@modules/app/app.store'

moment.updateLocale('es', {
  months: 'Enero_Febrero_Marzo_Abril_Mayo_Junio_Julio_Agosto_Septiembre_Octubre_Noviembre_Diciembre'.split('_'),
  monthsShort: 'Ene_Feb_Mar_Abr_Mayo_Jun_Jul_Ago_Sep_Oct_Nov_Dic'.split('_'),
  weekdays: 'Domingo_Lunes_Martes_Miércoles_Jueves_Viernes_Sábado'.split('_'),
  weekdaysShort: 'Dom_Lun_Mar_Miér_Jue_Vier_Sáb'.split('_'),
  weekdaysMin: 'Do_Lu_Ma_Mi_Ju_Vi_Sa'.split('_'),
})

if (environment.production) {
  Sentry.init({
    release: `monto@${environment.APP_VERSION}${environment.production ? '' : '-dev'}`,
    environment: environment.production ? 'production' : 'development',
    dsn: 'https://527ce5bf002d4cc28fa118cc7a28cef1@o867048.ingest.sentry.io/5823690',
    integrations: [
      new BrowserTracing({
        tracePropagationTargets: ['https://app.monto.mx', 'https://dev-app.monto.mx'],
        routingInstrumentation: Sentry.routingInstrumentation,
      }),
    ],
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
  })
}

registerLocaleData(localeMx, 'es')

/**
 * @description Meta reducer to reset the state when the user logs out
 * @param {ActionReducer<any>} reducer
 * @returns {ActionReducer<any>}
 */
export function resetState(reducer: ActionReducer<any>): ActionReducer<any> {
  return function (state, action) {
    if (action.type === '[User] Session Remove') {
      //preserve config and biometric state
      const { config, biometric } = state
      state = { config, biometric }
    }
    return reducer(state, action)
  }
}

export const metaReducers: MetaReducer[] = [resetState]

export const appConfig: ApplicationConfig = {
  providers: [
    provideIonicAngular(),
    provideRouter(
      routes,
      withRouterConfig({
        onSameUrlNavigation: 'reload',
      }),
      withInMemoryScrolling({
        anchorScrolling: 'enabled',
        scrollPositionRestoration: 'top',
      }),
      withPreloading(Capacitor.isNativePlatform() && environment.production ? PreloadAllModules : NoPreloading)
    ),
    importProvidersFrom([
      IconModule,
      ConfigModule,
      BrowserModule,
      IonicModule.forRoot({
        mode: 'md',
        rippleEffect: false,
        refreshingSpinner: 'bubbles',
        infiniteLoadingSpinner: 'bubbles',
        animated: false,
        navAnimation: undefined,
        innerHTMLTemplatesEnabled: true,
      }),
      ResourceModule.forRoot(),
      IonicStorageModule.forRoot(),
      BrowserAnimationsModule,
      EffectsModule.forFeature([
        UserEffects,
        WithdrawalEffects,
        SiteTreeEffects,
        RecordEffects,
        PaymentServicesEffects,
        MontoCashEffects,
        CheckoutEffects,
      ]),
      EffectsModule.forRoot(),
      StoreModule.forRoot(
        {
          biometric: biometricReducer,
          config: configReducer,
          withdrawal: withdrawalReducer,
          notification: notificationReducer,
          user: userReducer,
          montoCash: montoCashReducer,
          giftCard: giftCardReducer,
          paymentService: paymentServiceReducer,
          benefit: benefitReducer,
          payment: paymentReducer,
          creditCash: creditCashReducer,
          record: recordReducer,
          siteTree: siteTreeReducer,
          checkout: checkoutReducer,
          refer: referReducer,
        },
        {
          metaReducers,
          runtimeChecks: {
            strictStateImmutability: true,
            strictActionImmutability: true,
          },
        }
      ),
      !environment.production
        ? StoreDevtoolsModule.instrument({
            maxAge: 25,
            logOnly: environment.production,
            autoPause: true, // Pauses recording actions and state changes when the extension window is not open
          })
        : [],
      provideFirebaseApp(() => initializeApp(environment.firebase)),
      provideAnalytics(() => getAnalytics()),
      provideMessaging(() => getMessaging()),
      provideRemoteConfig(() => getRemoteConfig()),
    ]),
    ConfigStore,
    AppStore,
    Camera,
    BiometricService,
    LogoutService,
    CookieService,
    StorageService,
    MontoWithdrawService,
    File,
    /* FirebaseConfig, */
    /* FirebaseAnalytics, */
    { provide: LOCALE_ID, useValue: 'es' },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ApiVersionInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AppVersionInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthorizationInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ClientTypeInterceptor,
      multi: true,
    },
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: false,
      }),
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {
        //no action
      },
      deps: [Sentry.TraceService],
      multi: true,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: (profileService: ProfileService) => () => profileService.getAndSetProfile(),
      deps: [ProfileService],
      multi: true,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: (storageService: StorageService) => () => storageService.init(),
      deps: [StorageService],
      multi: true,
    },
    ScreenTrackingService,
    ConfigService,
    MontoCashResource,
    ResourceBaseService,
    BenefitResource,
    OpenNativeSettings,
    UserResource,
    SiteTreeResource,
    VerifyCredentialsService,
    LoginService,
    ClarityService,
    ChatbotService,
    SplitPaneService,
    GlobalTutorialService,
    ServiceAvailableService,
    PaymentServicesResource,
    provideHttpClient(withInterceptorsFromDi()),
  ],
}
