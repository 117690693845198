import { Pipe, PipeTransform } from '@angular/core'

@Pipe({
  name: 'readMore',
  standalone: true,
})
export class ReadMorePipe implements PipeTransform {
  voidTags = [
    'area',
    'base',
    'br',
    'col',
    'command',
    'embed',
    'hr',
    'img',
    'input',
    'link',
    'meta',
    'param',
    'source',
    'wbr',
  ]

  /**
   * Extracts <tag id="foo"> from a larger string. Assumes str[startIdx] === '<'
   */
  extractTag(str: string, startIdx: number): string {
    const endIdx = str.indexOf('>', startIdx)
    return str.slice(startIdx, endIdx + 1)
  }

  /** Checks that <tag> is an end tag */
  isEndTag(tag: string): boolean {
    return tag[1] === '/'
  }

  /** Extracts tag from <tag id="foo"> */
  extractTagName(tag: string): string {
    let tagNameEndIdx = tag.indexOf(' ')
    if (tagNameEndIdx === -1) {
      // check for <br/> style tags
      tagNameEndIdx = tag.indexOf('/')

      if (tagNameEndIdx === -1) {
        tagNameEndIdx = tag.length - 1
      }
    }

    return tag.slice(1, tagNameEndIdx)
  }

  /** Checks that tagName is a void tag (it doesn't have an end tag) */
  isVoidTag(tagName: string) {
    for (let i = this.voidTags.length - 1; i >= 0; --i) {
      if (tagName === this.voidTags[i]) {
        return true
      }
    }

    return false
  }

  transform(html: any, maxLength = 20, showAll = false, addEllipsis = true): any {
    if (showAll) {
      return html
    }
    const len = html.length
    if (len <= maxLength) {
      return html
    }

    // leave room for ellipsis
    if (addEllipsis) {
      --maxLength
    }

    let i = 0
    let charCount = 0
    const tagStack = []

    while (i < len && charCount < maxLength) {
      const char = html.charAt(i)
      const charCode = html.charCodeAt(i)

      if (char === '<') {
        const tag = this.extractTag(html, i)

        // skip content between < and >
        i += tag.length

        if (this.isEndTag(tag)) {
          tagStack.pop()
        } else {
          const tagName = this.extractTagName(tag)

          if (!this.isVoidTag(tagName)) {
            tagStack.push(tagName)
          }
        }
      } else {
        // if charCode is a high surrogate and if the string contains a
        // low surrogate then count the pair as a single character
        if (charCode >= 0xd800 && charCode <= 0xdbff && i + 1 < len) {
          ++i
        }

        ++charCount
        ++i
      }
    }

    let result = html.slice(0, i)

    for (let j = tagStack.length - 1; j >= 0; --j) {
      result += '</' + tagStack[j] + '>'
    }

    if (addEllipsis && result.length < html.length) {
      result += '&hellip;'
    }

    return result
  }
}
