import { ICepRequestResponse } from '@declarations/cep-request-respose'

export class CepRequest {
  nonce: string

  url: string

  constructor(attr: ICepRequestResponse) {
    this.nonce = attr.nonce
    this.url = attr.url
  }
}
