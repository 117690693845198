import { inject, Injectable } from '@angular/core'
import { Capacitor } from '@capacitor/core'
import { selectUser } from '@modules/user/selectors/user.selectors'
import { Store } from '@ngrx/store'
import { IUserState } from '@modules/user/interfaces/user'
import { Observable } from 'rxjs'
import { UserModel } from '@modules/user/models/user.model'
import { take } from 'rxjs/operators'
import { parseIdentifier } from '@services/users.service'
import { Keyboard, KeyboardResize } from '@capacitor/keyboard'
import { IConfig } from '@modules/config/interfaces/config'

declare var ZohoSalesIQ: any
declare var $zoho: any
declare var window: any

@Injectable({
  providedIn: 'root',
})
export class ChatbotService {
  user$: Observable<UserModel | null>
  _store: Store<{ user: IUserState; config: IConfig }>
  platform = Capacitor.getPlatform()
  protected config$

  constructor() {
    this._store = inject(Store<{ user: IUserState }>)
    this.user$ = this._store.select(selectUser)
    this.config$ = this._store.select('config')
  }

  /**
   * Init chatbot
   * @returns void
   */
  init() {
    if (!this.checkAvailable()) {
      return
    }
    this.config$.pipe(take(1)).subscribe((config: IConfig) => {
      if (!config.CONFIG_CHATBOT) return
      if (!config.CONFIG_CHATBOT.active) return
      if (!config.CONFIG_CHATBOT.keys.android.appKey) return

      if (this.platform === 'android') {
        ZohoSalesIQ.init(config.CONFIG_CHATBOT.keys.android.appKey, config.CONFIG_CHATBOT.keys.android.accessKey)
        this.setConfigMobile()
        this.setData()
      } else if (this.platform === 'ios') {
        ZohoSalesIQ.init(config.CONFIG_CHATBOT.keys.ios.appKey, config.CONFIG_CHATBOT.keys.ios.accessKey)
        this.setConfigMobile()
        this.setData()
      } else if (this.platform === 'web') {
        if (window['$zoho']) {
          this.injectScript(config.CONFIG_CHATBOT.keys.web.accessKey)
        }
      }
    })
  }

  injectScript(key: string) {
    $zoho = $zoho || {}
    $zoho.salesiq = $zoho.salesiq || {
      widgetcode: key,
      values: {},
      ready: () => {
        $zoho.salesiq.floatbutton.visible('hide')
        this.setData()
      },
    }
    var d = document
    var s = d.createElement('script')
    s.type = 'text/javascript'
    s.id = 'zsiqscript'
    s.onload = () => {
      setTimeout(() => {
        $zoho.salesiq.floatwindow.minimize((visitid: string) => {
          if (Capacitor.isNativePlatform() && Capacitor.getPlatform() === 'ios') {
            Keyboard.setResizeMode({ mode: KeyboardResize.None })
          }
        })
      })
    }
    s.defer = true
    s.src = 'https://salesiq.zoho.com/widget'
    var t = d.getElementsByTagName('script')[0]
    t.parentNode.insertBefore(s, t)
  }

  setConfigMobile() {
    ZohoSalesIQ.showLauncher(false)
    ZohoSalesIQ.setThemeColorforiOS('#2565C7')
  }

  /**
   * Check if chatbot is available
   * @returns {boolean}
   */
  checkAvailable(): boolean {
    return true
  }

  /**
   * Open chat
   */
  openChat() {
    if (!this.checkAvailable()) {
      return
    }
    this.setData()
    if (this.platform === 'web') {
      if (Capacitor.isNativePlatform() && Capacitor.getPlatform() === 'ios') {
        Keyboard.setResizeMode({ mode: KeyboardResize.Ionic })
      }
      $zoho.salesiq.floatwindow.open()
      $zoho.salesiq.chat.start()
    } else {
      ZohoSalesIQ.show()
    }
  }

  /**
   * Set data user
   */
  setData() {
    if (!this.checkAvailable()) {
      return
    }

    this.user$.pipe(take(1)).subscribe((user: UserModel | null) => {
      if (!user) {
        return
      }

      //set data for native
      if (this.platform !== 'web') {
        ZohoSalesIQ.registerVisitor(user.id)
        ZohoSalesIQ.setVisitorName(user.firstName + ' ' + user.lastName)
        ZohoSalesIQ.setVisitorEmail(user.email)
        if (user.profile?.cellphone) {
          ZohoSalesIQ.setVisitorContactNumber(parseIdentifier(user.profile?.cellphone + ''))
        }
        if (user.profile?.companyName) {
          ZohoSalesIQ.setVisitorAddInfo('Company', user.profile?.companyName)
        }
      } else {
        //set data for web
        $zoho.salesiq.visitor.id(user.id)
        $zoho.salesiq.visitor.name(user.firstName + ' ' + user.lastName)
        $zoho.salesiq.visitor.email(user.email)
        if (user.profile?.cellphone) {
          $zoho.salesiq.visitor.contactnumber(parseIdentifier(user.profile?.cellphone + ''))
        }

        if (user.profile?.companyName) {
          $zoho.salesiq.visitor.info({
            Company: user.profile?.companyName,
          })
        }
      }
    })
  }
}
