import { IAccountInfo } from '@modules/user/interfaces/account-info'

export class AccountInfoModel {
  readonly #account: IAccountInfo

  constructor(attrs: IAccountInfo) {
    this.#account = attrs
  }

  get bank(): string {
    return this.#account.bank
  }

  get bankSlug(): string {
    return this.#account.bank_slug
  }

  get accountLast4(): string {
    return this.#account.account_last_4
  }

  toJSON(): Partial<AccountInfoModel> {
    return {
      bank: this.bank,
      accountLast4: this.accountLast4,
      bankSlug: this.bankSlug,
    }
  }
}
