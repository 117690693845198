import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { FormsModule } from '@angular/forms'
import { IonicModule } from '@ionic/angular'
import { SharedModule } from '../../../../shared/shared.module'
import { NotificationCardModule } from '../notification-card/notification-card.module'
import { NotificationLoadingModule } from '../notification-loading/notification-loading.module'
import { NotificationListComponent } from './notification-list.component'
import { ContentMainComponent } from '@components/content-main/content-main.component'

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    SharedModule,
    NotificationLoadingModule,
    NotificationCardModule,
    ContentMainComponent,
  ],
  exports: [NotificationListComponent],
  declarations: [NotificationListComponent],
})
export class NotificationListModule {}
