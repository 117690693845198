import { FormGroup } from '@angular/forms'
import { ILoginForm } from '@declarations/login-form'
import { ILoginPayload } from '@declarations/login-payload'

export class LoginPayload {
  private pLoginForm: FormGroup

  private get pLoginFormValue(): ILoginForm {
    return this.pLoginForm.value
  }

  constructor(loginForm: FormGroup) {
    this.pLoginForm = loginForm
  }

  toJSONObject(): ILoginPayload {
    return {
      session: {
        email: this.pLoginFormValue.userEmail,
        password: this.pLoginFormValue.userPassword,
      },
    }
  }
}
