import type { NgZone, OnInit } from '@angular/core'
import { inject, Injectable } from '@angular/core'
import { getAnalytics, logEvent } from '@angular/fire/analytics'
import { NavigationEnd, Router, RouterEvent } from '@angular/router'
import { NavigationStart } from '@angular/router'
import { App } from '@capacitor/app'
import { Capacitor } from '@capacitor/core'
import { SplashScreen } from '@capacitor/splash-screen'
import { environment } from '@environments/environment'
import { filter } from 'rxjs/operators'
import { FcmService } from '@modules/notification/services/fcm.service'
import { NavController } from '@ionic/angular'
import { ConfigAdapterService } from '@modules/config/services/config.adapter.service'

@Injectable()
export class ContainerService implements OnInit {
  protected configService: ConfigAdapterService = inject(ConfigAdapterService)
  public side = 'start'

  protected fcmService: FcmService = inject(FcmService)
  protected _router!: Router
  protected zone!: NgZone
  protected navController!: NavController

  constructor() {
    this.configService.init()
  }

  sidePanel(): void {
    if (window.matchMedia('(min-width: 768px)').matches) {
      this.side = 'start'
    } else {
      this.side = 'end'
    }
  }

  // eslint-disable-next-line @angular-eslint/contextual-lifecycle
  ngOnInit(): void {
    window.addEventListener(
      'resize',
      event => {
        this.sidePanel()
      },
      true
    )
    this.sidePanel()

    this.fcmService.initPush()
    this._router.events.pipe(filter(event => event instanceof NavigationStart)).subscribe(() => {
      if (environment.production) {
        if (Capacitor.getPlatform() === 'web') {
          const analytics = getAnalytics()
          logEvent(analytics, 'page_view', { page: window.location.pathname })
        } else {
          /* this._firebaseAnalytics
            .logEvent('page_view', { page: window.location.pathname })
            .then((res: any) => console.debug(res))
            .catch((error: any) => console.error(error)) */
        }
      }
    })

    this.initializeApp()
  }

  async initializeApp(): Promise<void> {
    setTimeout(async () => {
      await SplashScreen.hide()
    }, 300)
    App.addListener('appStateChange', ({ isActive }) => {
      if (!isActive) {
        SplashScreen.show({
          autoHide: false,
        })
      } else {
        SplashScreen.hide()
      }
    })

    /**
     * 1. Split url into two parts: URL and PATH
     * 2. Get path and pass reroute user using path
     */
    App.addListener('appUrlOpen', (data: { url: string }) => {
      const { 1: path } = data.url.split(environment.wepApp)

      this.zone.run(async () => {
        this.navController.navigateRoot(path)
      })
    })
  }
}
