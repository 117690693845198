import { createFeatureSelector, createSelector } from '@ngrx/store'
import { ICheckoutState } from '@modules/checkout/checkout.state'

export const selectCheckoutFeature = createFeatureSelector<ICheckoutState>('checkout')
export const selectCheckoutLoading = createSelector(selectCheckoutFeature, state => state.loading)
export const selectCheckoutCards = createSelector(selectCheckoutFeature, state => state.cards)
export const selectCheckoutCard = createSelector(selectCheckoutFeature, state => state.card)
export const selectCheckoutPaymentMethods = createSelector(selectCheckoutFeature, state => state.paymentMethods)
export const selectCheckoutPaymentMethod = createSelector(selectCheckoutFeature, state => state.paymentMethod)
export const selectCheckoutUrlBack = createSelector(selectCheckoutFeature, state => state.urlBack)
export const selectCheckoutImage = createSelector(selectCheckoutFeature, state => state.image)
