import { Injectable } from '@angular/core'
import { environment } from '@environments/environment'
import {
  IResourceMethodObservable,
  ResourceAction,
  ResourceParams,
  ResourceRequestMethod,
  ResourceResponseBodyType,
} from '@ngx-resource/core'
import { ResourceBaseService } from '@services/resource.base.service'

@Injectable()
@ResourceParams({
  url: environment.API + '/api/v3/',
})
export class SiteTreeResource extends ResourceBaseService {
  @ResourceAction({
    method: ResourceRequestMethod.Get,
    path: '/catalogs/site_tree',
    responseBodyType: ResourceResponseBodyType.Json,
  })
  siteTree: IResourceMethodObservable<any, any> | any
}
