import type { EntityAdapter } from '@ngrx/entity'
import { createEntityAdapter } from '@ngrx/entity'
import { createReducer, on } from '@ngrx/store'
import {
  clearCategoryServiceBillerGroup,
  paymentServiceBiller,
  paymentServiceBillerGroup,
  paymentServiceClear,
  paymentServiceError,
  paymentServiceLoading,
  paymentServiceSetBalance,
  paymentServiceSetIdemPotency,
  paymentServiceSetSummary,
  setCategoriesServiceBillerGroup,
  setCategoryServiceBillerGroup,
} from '@modules/payment-services/actions/payment-service.actions'
import {
  IPaymentServiceBillerGroup,
  IPaymentServiceState,
} from 'src/app/modules/payment-services/interfaces/payment-services'

export const adapter: EntityAdapter<IPaymentServiceBillerGroup> = createEntityAdapter<IPaymentServiceBillerGroup>()

export const initialState: IPaymentServiceState = adapter.getInitialState({
  loading: false,
  entity: null,
  entities: null,
  balance: null,
  group: null,
  error: false,
  idemPotency: null,
  categories: [],
  category: null,
})

const _paymentServiceReducer = createReducer(
  initialState,
  on(paymentServiceLoading, (state, { loading }) => ({
    ...state,
    loading,
  })),
  on(setCategoriesServiceBillerGroup, (state, { categories }) => ({
    ...state,
    categories: categories,
  })),
  on(setCategoryServiceBillerGroup, (state, { category }) => ({
    ...state,
    category: category,
  })),
  on(clearCategoryServiceBillerGroup, state => ({
    ...state,
    category: null,
  })),
  on(paymentServiceError, (state, { error }) => ({
    ...state,
    error,
  })),
  on(paymentServiceSetIdemPotency, (state, { idemPotency }) => ({
    ...state,
    idemPotency,
  })),
  on(paymentServiceSetBalance, (state, { balance }) => ({
    ...state,
    balance,
  })),
  on(paymentServiceClear, state => ({
    ...state,
    ...initialState,
  })),
  on(paymentServiceBillerGroup, (state, { group }) => ({
    ...state,
    group,
  })),
  on(paymentServiceBiller, (state, { entity }) => {
    if (entity == null) {
      return {
        ...state,
        entity: null,
      }
    }
    return {
      ...state,
      entity: {
        ...state.entity,
        ...entity,
      },
    }
  }),
  on(paymentServiceSetSummary, (state, { summary, card }) => {
    if (!summary) {
      return {
        ...state,
        entity: {
          ...state.entity,
          summary: null,
        },
      }
    }

    const commissionMonto = (summary.commission_monto || 0) + (summary.commission_monto_tax || 0)
    const commissionPaymentMethod = summary.commission_payment_method || 0
    const commissionSupplier = (summary.commission_supplier || 0) + (summary.commission_supplier_tax || 0)
    let total = 0
    let commission = null

    if (card) {
      commission = summary.commission || 0
      total = (state.entity?.amount || 0) + commissionPaymentMethod + commission + commissionSupplier
    } else {
      total = (state.entity?.amount || 0) + commissionMonto + commissionSupplier
    }

    const discountDate = summary.discount_date

    return {
      ...state,
      entity: {
        ...state.entity,
        summary: {
          ...summary,
          commissionMonto,
          commissionSupplier,
          commissionPaymentMethod,
          total,
          discountDate,
          card,
          commission,
        },
      },
    }
  })
)

export const paymentServiceReducer = (state: any, action: any): any => _paymentServiceReducer(state, action)
