import { Component, EnvironmentInjector } from '@angular/core'
import { Platform } from '@ionic/angular'
import { CommonModule } from '@angular/common'
import { FormsModule } from '@angular/forms'
import { IonRouterOutlet } from '@ionic/angular/standalone'
import { RouterModule } from '@angular/router'

@Component({
  selector: 'app-container-base',
  templateUrl: './container-base.component.html',
  styleUrls: ['./container-base.component.scss'],
  standalone: true,
  imports: [CommonModule, FormsModule, IonRouterOutlet, RouterModule],
})
export class ContainerBaseComponent {}
