import { AsyncPipe, NgClass, NgIf, NgTemplateOutlet } from '@angular/common'
import { NgModule } from '@angular/core'
import { IonicModule } from '@ionic/angular'
import { ToolbarComponent } from './toolbar.component'
import { IconModule } from '@atoms/icon/icon.module'
import { RouterModule } from '@angular/router'

@NgModule({
  imports: [NgIf, NgClass, NgTemplateOutlet, AsyncPipe, IonicModule, IconModule, RouterModule],
  declarations: [ToolbarComponent],
  exports: [ToolbarComponent],
})
export class ToolbarModule {}
