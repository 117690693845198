import type { IWithdrawalState, IWithdrawal } from './../interfaces/withdrawal'
import { createReducer, on } from '@ngrx/store'
import {
  setSearchParams,
  withdrawalsLoading,
  clearSearchParams,
  withdrawalsLoaded,
  withdrawalsClear,
  withdrawalAllow,
  withdrawalSetCycle,
  withdrawalClearCycle,
  withdrawalsOriginLoaded,
  setWithdrawalNew,
  clearWithdrawalNew,
} from '../actions/withdrawal.actions'

import type { EntityAdapter } from '@ngrx/entity'
import { createEntityAdapter } from '@ngrx/entity'

export const adapter: EntityAdapter<IWithdrawal> = createEntityAdapter<IWithdrawal>()

export const initialState: IWithdrawalState = adapter.getInitialState({
  loading: true,
  entity: null,
  cycle: null,
  searchParams: null,
  entities: null,
  origin: null,
  withdrawalAllow: false,
  entityNew: null,
})

const _withdrawalReducer = createReducer(
  initialState,
  on(withdrawalSetCycle, (state, { cycle }) => ({
    ...state,
    cycle,
  })),
  on(withdrawalClearCycle, state => ({
    ...state,
    cycle: null,
  })),
  on(withdrawalsLoaded, (state, { entities }) => ({
    ...state,
    entities,
  })),
  on(withdrawalsOriginLoaded, (state, { origin }) => ({
    ...state,
    origin,
  })),
  on(withdrawalsLoading, (state, { loading }) => ({
    ...state,
    loading,
  })),
  on(withdrawalAllow, (state, { allow }) => ({
    ...state,
    withdrawalAllow: allow,
  })),
  on(withdrawalsClear, state => ({
    ...state,
    entities: null,
    searchParams: null,
  })),
  on(clearWithdrawalNew, state => ({
    ...state,
    entity: null,
    entityNew: null,
  })),
  on(setSearchParams, (state, { searchParams }) => ({
    ...state,
    searchParams: {
      ...state.searchParams,
      ...searchParams,
    },
  })),
  on(clearSearchParams, state => ({
    ...state,
    searchParams: null,
  })),
  on(setWithdrawalNew, (state, { withdrawal }) => ({
    ...state,
    entityNew: {
      ...state.entityNew,
      ...withdrawal,
    },
  }))
)

export const withdrawalReducer = (state: any, action: any): any => _withdrawalReducer(state, action)
export const { selectAll, selectIds, selectEntities } = adapter.getSelectors()
