import { inject } from '@angular/core'
import { ActivatedRouteSnapshot, CanActivateFn, RouterStateSnapshot } from '@angular/router'
import { GeolocationService } from '@services/geolocation.service'
import { NavController } from '@ionic/angular'
import { Store } from '@ngrx/store'
import { IConfig } from '@modules/config/interfaces/config'
import { IUserState } from '@modules/user/interfaces/user'
import { combineLatest, from } from 'rxjs'
import { map, take } from 'rxjs/operators'
import { Capacitor } from '@capacitor/core'

export const canGeolocationRedirect: CanActivateFn = (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
  const geolocationService = inject(GeolocationService)
  const navController = inject(NavController)
  const store = inject(Store<{ config: IConfig; user: IUserState }>)
  const config$ = store.select('config')
  return combineLatest([config$, from(geolocationService.getPermissionStatus())]).pipe(
    take(1),
    map(([config, permission]) => {
      if (config?.FLAG_GEOLOCATION) {
        const condition = Capacitor.isNativePlatform()
          ? permission !== 'granted'
          : permission !== 'granted' && geolocationService.getPermission() !== 'granted'
        if (condition) {
          navController.navigateRoot(['/u/gelocation'], {
            state: {
              urlContiue: state.url,
            },
          })
          return false
        }
        geolocationService.getCurrentPosition()
      }
      return true
    })
  )
}
