import { Component, ElementRef, EventEmitter, inject, Input, OnInit, Output, ViewChild } from '@angular/core'
import { Router, RouterModule } from '@angular/router'
import { IConfig } from '@modules/config/interfaces/config'
import { IMontoCashState } from '@modules/monto-cash/interfaces/monto-cash'
import { montoCashBalanceSelect, montoCashIsLoadingSelect } from '@modules/monto-cash/selectors/monto-cash.selectors'
import { IUserState } from '@modules/user/interfaces/user'
import { ProfileModel } from '@modules/user/models/profile.model'
import { selectUser } from '@modules/user/selectors/user.selectors'
import { Store } from '@ngrx/store'
import { ImagePickerService } from '@services/image-picker.service'
import type { Observable } from 'rxjs'
import { UserModel } from '@modules/user/models/user.model'
import { PermissionsDocsService } from '@services/permissions-docs.service'
import { MenuController } from '@ionic/angular'
import { FormsModule } from '@angular/forms'
import { ConfigModule } from '@modules/config/config.module'
import { PushPipe } from '@ngrx/component'
import { BtnCompleteProfileComponent } from '@modules/user/components/btn-complete-profile/btn-complete-profile.component'
import { CurrencyPipe, NgClass } from '@angular/common'
import { IonAvatar, IonButton } from '@ionic/angular/standalone'
import { AppStore } from '@modules/app/app.store'

@Component({
  selector: 'app-user-info-card',
  templateUrl: './user-info-card.component.html',
  styleUrls: ['./user-info-card.component.scss'],
  standalone: true,
  imports: [
    CurrencyPipe,
    IonButton,
    NgClass,
    IonAvatar,
    FormsModule,
    RouterModule,
    ConfigModule,
    PushPipe,
    BtnCompleteProfileComponent,
  ],
})
export class UserInfoCardComponent implements OnInit {
  @ViewChild('fileInput')
  fileInput!: ElementRef

  @Input() color = 'light'
  @Input() viewState: any
  @Input() picture: any

  @Output() setFile: EventEmitter<any> = new EventEmitter<any>()

  profile: ProfileModel | null = null
  url!: string

  montoCashbalance$: Observable<any>
  montoCashIsLoading$: Observable<any>
  public config$
  user$: Observable<UserModel | null>
  readonly appStore = inject(AppStore)

  constructor(
    private imagePickerService: ImagePickerService,
    private _store: Store<{ config: IConfig; user: IUserState; montoCash: IMontoCashState }>,
    private router: Router
  ) {
    this.user$ = this._store.select(selectUser)
    this.config$ = this._store.select('config')
    this.montoCashbalance$ = this._store.select(montoCashBalanceSelect)
    this.montoCashIsLoading$ = this._store.select(montoCashIsLoadingSelect)
  }

  ngOnInit(): void {
    this.url = this.router.url
    this.user$.subscribe(user => {
      if (user) {
        this.profile = user.profile
      }
    })
  }
}
