<div
  class="container-flat {{ width }}"
  *ngIf="flat"
  [ngClass]="{
    'mx-auto': direction === 'center'
  }"
>
  <ng-container *ngTemplateOutlet="conaintWeb"></ng-container>
</div>
<div class="container-normal grid grid-cols-12 xl:grid-cols-12 {{ containerClass }}" *ngIf="!flat">
  <div
    [class]="
      'containter-web-content relative col-start-1 col-span-12  lg:w-web-area ' +
      customClass +
      ' ' +
      (direction === 'center'
        ? 'lg:col-start-2 lg:col-span-10'
        : direction === 'left'
        ? 'lg:col-start-1 lg:col-span-6'
        : '') +
      (direction === 'center'
        ? ' lg:col-start-3 lg:col-span-8 '
        : direction === 'left'
        ? 'lg:col-start-1 lg:col-span-6 '
        : '') +
      (direction === 'center' ? ' xl:mx-auto ' : direction === 'left' ? 'xl:col-start-1 ' : '') +
      (direction === 'full' ? 'lg:col-span-12' : '')
    "
  >
    <ng-container *ngTemplateOutlet="conaintWeb"></ng-container>
  </div>
  <ng-content select="[after]"></ng-content>
</div>
<ng-template #conaintWeb>
  <ng-content></ng-content>
</ng-template>
