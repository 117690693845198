import { Component, Input, forwardRef } from '@angular/core'
import { NG_VALIDATORS, NG_VALUE_ACCESSOR } from '@angular/forms'
import { InputMixinClass } from '@components/atoms/shared/inputMixinClass'

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'monto-input-password',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      // eslint-disable-next-line no-use-before-define
      useExisting: forwardRef(() => InputPasswordComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      // eslint-disable-next-line no-use-before-define
      useExisting: InputPasswordComponent,
      multi: true,
    },
  ],
  templateUrl: './input-password.component.html',
  styleUrls: ['./input-password.scss'],
})
export class InputPasswordComponent extends InputMixinClass {
  @Input() customClass?: string

  showPassword: boolean = false

  toggleShowPasswordType(): void {
    this.showPassword = !this.showPassword
  }
}
