import type { EntityAdapter } from '@ngrx/entity'
import { createEntityAdapter } from '@ngrx/entity'
import { createReducer, on } from '@ngrx/store'
import { referLoading, setReferConfig } from '../actions/refer.actions'
import type { IReferConfig, IReferState } from '../interfaces/refer'

export const adapter: EntityAdapter<IReferConfig> = createEntityAdapter<IReferConfig>()

export const initialState: IReferState = adapter.getInitialState({
  loading: true,
  entity: null,
  entities: null,
  indicatorNew: false,
})

const _referReducer = createReducer(
  initialState,
  on(referLoading, (state, { loading }) => ({
    ...state,
    loading,
  })),
  on(setReferConfig, (state, { config }) => ({
    ...state,
    entity: config,
  }))
)

export const referReducer = (state: any, action: any): any => _referReducer(state, action)
export const { selectAll, selectIds, selectEntities } = adapter.getSelectors()
