import { Actions, createEffect, ofType } from '@ngrx/effects'
import { map } from 'rxjs/operators'
import { Injectable } from '@angular/core'
import { withdrawalsLoaded, withdrawalsLoading } from '../actions/withdrawal.actions'
import { MontoWithdrawService } from '../services/withdrawal.service'
import { Store } from '@ngrx/store'
import { IRecordState } from '@modules/record/interfaces/record'

@Injectable()
export class WithdrawalEffects {
  withdrawalsLoaded$ = createEffect(() =>
    this.actions$.pipe(
      ofType(withdrawalsLoaded),
      map(() => withdrawalsLoading({ loading: false }))
    )
  )

  constructor(
    private actions$: Actions,
    private _withdrawalService: MontoWithdrawService,
    private _store: Store<{ record: IRecordState }>
  ) {}
}
