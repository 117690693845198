<ion-header>
  <ion-toolbar color="light" class="p-0">
    <ion-buttons slot="end">
      <ion-button (click)="onDismiss()" fill="clear">
        <ion-icon color="dark" size="large" slot="icon-only" name="close"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>
<div class="inner-content text-dark p-0 pt-1.5">
  <div class="px-4 flex flex-col gap-3.5">
    <h2 class="text-center text-[22px] leading-7 m-0 p-0 flex gap-1 items-center justify-center">
      <monto-icon [size]="'full'" class="max-w-[28px]" [color]="'primary'" [name]="'trophy'"></monto-icon>
      <div class="pt-1">Redime tu Premio</div>
    </h2>

    <div class="inner-content text-dark p-0 flex flex-col gap-3.5">
      <div class="text-center">Ingresa tu código en el siguiente campo para redimir tu premio:</div>
      <section class="flex flex-col gap-1">
        <monto-input-text
          [autofill]="true"
          maxlength="100"
          type="email"
          id="userEmail"
          [(ngModel)]="code"
          [ngModelOptions]="{ standalone: true }"
          autocomplete="off"
        ></monto-input-text>
        <div class="min-h-[30px]">
          <p *ngIf="error" class="text-center text-danger">{{ errorText }}</p>
        </div>
      </section>
      <section class="pb-[30px]">
        <ion-button expand="block" [disabled]="!code || isLoading" color="primary" (click)="onRedeem()">
          Redimir
        </ion-button>
        <ion-button expand="block" color="light" [disabled]="isLoading" (click)="onDismiss()"> Cancelar </ion-button>
      </section>
    </div>
  </div>
</div>
