import { ChangeDetectionStrategy, Component } from '@angular/core'
import { NotificationModalComponent } from '../notification-modal/notification-modal.component'
import { CommonModule } from '@angular/common'
import { SanitizeHtmlPipe } from '@pipes/sanitize-html.pipe'
import { SharedPipesModule } from '@pipes/shared-pipes.module'
import { IonButton, IonContent, IonIcon } from '@ionic/angular/standalone'

@Component({
  selector: 'app-notification-fullscreen',
  templateUrl: './notification-fullscreen.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  styleUrls: ['../notification-modal/notification-modal.component.scss'],
  standalone: true,
  imports: [CommonModule, SharedPipesModule, IonContent, IonButton, IonIcon],
  providers: [SanitizeHtmlPipe],
})
export class NotificationFullscreenComponent extends NotificationModalComponent {}
