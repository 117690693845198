<app-content-main [isModal]="true">
  <div class="inner-content text-dark p-0 flex flex-col h-full">
    <div>
      <h4 class="font-semibold">{{ title }}</h4>
    </div>
    <div class="py-1 flex-grow">
      <div [innerHTML]="body || benefit.data?.['terms'] || '' | sanitizeHtml: 'html'"></div>
    </div>
    <div class="w-full mx-auto py-1.5">
      <ion-button expand="block" color="light" (click)="onDissmiss()"> Entendido </ion-button>
    </div>
  </div>
</app-content-main>
