import { inject, Injectable } from '@angular/core'
import { Actions, createEffect, ofType } from '@ngrx/effects'
import { of, tap } from 'rxjs'
import { catchError, filter, finalize, withLatestFrom } from 'rxjs/operators'
import { loadingSiteTree, loadSiteTree, setSiteTree } from '@modules/app/actions/site-tree.actions'
import { selectSiteTreeLoading } from '@modules/app/selectors/site-tree.selectors'
import { ISiteTree, ISiteTreeState } from '@modules/app/interfaces/site-tree'
import { SiteTreeResource } from '@modules/app/resources/site-tree.resource'
import { Store } from '@ngrx/store'
import { AppStore } from '@modules/app/app.store'

@Injectable({
  providedIn: 'root',
})
export class SiteTreeEffects {
  readonly appStore = inject(AppStore)
  siteTreeLoadRemote$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(loadSiteTree),
        withLatestFrom(this._store.select(selectSiteTreeLoading)),
        filter(([, isLoading]) => {
          return !isLoading
        }),
        tap(() => {
          this._store.dispatch(
            loadingSiteTree({
              loading: true,
            })
          )
          this._resource
            .siteTree()
            .pipe(
              catchError(() => of(null)),
              finalize(() => this._store.dispatch(loadingSiteTree({ loading: false })))
            )
            .subscribe((siteTree: ISiteTree) => {
              if (!siteTree) {
                return
              }
              this.appStore.setSiteTree(siteTree)
              this._store.dispatch(
                setSiteTree({
                  siteTree,
                })
              )
            })
        })
      ),
    { dispatch: false }
  )

  constructor(
    private actions$: Actions,
    private _store: Store<{ siteTree: ISiteTreeState }>,
    private _resource: SiteTreeResource
  ) {}
}
