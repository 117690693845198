import { ISelectedPicture } from '@declarations/selected-picture'
import { DomSanitizer, SafeUrl } from '@angular/platform-browser'
import { ElementRef, Injectable } from '@angular/core'
import { File } from '@ionic-native/file/ngx'
import { Camera as CameraCapacitor, CameraResultType, CameraSource } from '@capacitor/camera'
import { Capacitor } from '@capacitor/core'
import { OpenNativeSettings } from '@awesome-cordova-plugins/open-native-settings/ngx'
import { Camera, CameraOptions } from '@awesome-cordova-plugins/camera/ngx'

@Injectable({
  providedIn: 'root',
})
export class ImagePickerService {
  constructor(
    private file: File,
    private sanitizer: DomSanitizer,
    private openNativeSettings: OpenNativeSettings,
    private camera: Camera
  ) {
    /** Do nothing */
  }

  async pickImage(inputElementRef?: ElementRef): Promise<ISelectedPicture | void> {
    if (inputElementRef) {
      inputElementRef.nativeElement.click()
      return
    }

    if (Capacitor.getPlatform() === 'ios') {
      const options: CameraOptions = {
        quality: 90,
        destinationType: this.camera.DestinationType.FILE_URI,
        encodingType: this.camera.EncodingType.JPEG,
        mediaType: this.camera.MediaType.PICTURE,
        sourceType: this.camera.PictureSourceType.PHOTOLIBRARY,
        targetHeight: 500,
      }

      const image = await this.camera.getPicture(options)

      if (image) {
        return {
          blob: await this.fileEntryToBlob(image),
          imageURL: this.bypassSecurityTrustUrl(image),
        }
      }
    } else {
      //get foto from camera and gallery and select unique photo from galley
      const image = await CameraCapacitor.getPhoto({
        quality: 90,
        saveToGallery: true,
        source: CameraSource.Photos,
        correctOrientation: true,
        height: 512,
        resultType: CameraResultType.Uri,
        webUseInput: true,
      })
      if (image) {
        return {
          blob: await this.fileEntryToBlob(image.path as string),
          imageURL: this.bypassSecurityTrustUrl(image.path as string),
        }
      }
    }
  }

  /** HACK: This is done becase if we don't the image is not displayed for security reasons */
  private bypassSecurityTrustUrl(entry: string): SafeUrl {
    return this.sanitizer.bypassSecurityTrustUrl(Capacitor.convertFileSrc(entry))
  }

  private async fileEntryToBlob(fileEntry: string): Promise<Blob> {
    const webSafePhoto = Capacitor.convertFileSrc(fileEntry)
    const response = await fetch(webSafePhoto)
    return response.blob()
  }
}
