import { ILoginPayload } from '@declarations/login-payload'
import { ILoginResponse } from '@declarations/login-response'
import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { UserToken } from '@models/user-token'
import { environment } from '@environments/environment'
import { map } from 'rxjs/operators'
import { HttpClient, HttpHeaders } from '@angular/common/http'
import { SessionService } from './session.service'
import { ProfileService } from '@services/profile.service'

@Injectable()
export class LoginService {
  session: any

  constructor(
    private httpClient: HttpClient,
    private _sessionService: SessionService,
    private _profileService: ProfileService
  ) {
    /** Do nothing */
  }

  login(payload: ILoginPayload): Observable<UserToken> {
    const headers = new HttpHeaders()
      .set('Accept', 'application/vnd.mto.v3+json')
      .set('Content-Type', 'application/json')
    return this.httpClient.post(`${environment.API}/api/v3/sessions`, payload, { headers }).pipe(
      map((response: ILoginResponse) => {
        const userToken = new UserToken(response)
        return userToken
      })
    )
  }

  generateSession(payload: ILoginPayload): Observable<UserToken> {
    return this.login(payload).pipe(
      map((userToken: UserToken) => {
        this._sessionService.setSession(userToken.token)
        this._sessionService.setSessionRefresh(userToken.refresh_token)
        this._profileService.getAndSetProfile()
        return userToken
      })
    )
  }
}
