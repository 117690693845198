import { createFeatureSelector, createSelector } from '@ngrx/store'
import { IBiometricState } from '@modules/biometric/biometric.state'

export const selectBiometricFeature = createFeatureSelector<IBiometricState>('biometric')
export const selectBiometricDoLogin = createSelector(selectBiometricFeature, state => state.doLogin)
export const selectBiometricIsAvailable = createSelector(selectBiometricFeature, state => state.available)
export const selectBiometricIsAvailableAndActivated = createSelector(
  selectBiometricFeature,
  state => state.activated && state.available
)
