import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { IonicModule } from '@ionic/angular'
import { LoginService } from '@services/login.service'
import { IconHelpComponent } from './icon-help.component'

@NgModule({
  imports: [CommonModule, IonicModule],
  declarations: [IconHelpComponent],
  exports: [IconHelpComponent],
  providers: [LoginService],
})
export class IconHelpModule {}
