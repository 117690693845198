import { patchState, signalStore, withComputed, withMethods, withState } from '@ngrx/signals'
import { computed, inject } from '@angular/core'
import {
  ISiteTree,
  ISiteTreeHome,
  ISiteTreeMenu,
  ISiteTreeOption,
  ISiteTreeTabs,
} from '@modules/app/interfaces/site-tree'
import { UserModel } from '@modules/user/models/user.model'
import { map } from 'rxjs/operators'
import { UserResource } from '@modules/user/resources/user.resource'
import { IParamsCredits } from '@modules/app/interfaces/params-credits'
import { ICreditInfo } from '@modules/credit-cash/interfaces/credit-cash'
import { CreditCashResource } from '@modules/credit-cash/resources/credit-cash.resource'

type IStateApp = {
  siteTree: ISiteTree | null
  paramsCredits: IParamsCredits | null
  user: UserModel | null
  creditInfo: ICreditInfo | null
}
const initialStateApp: IStateApp = {
  siteTree: null,
  user: null,
  paramsCredits: null,
  creditInfo: null,
}
export function parseSiteTree<T>(options: T) {
  const menu = {} as T
  for (const key in options) {
    if (typeof options[key] === 'boolean') {
      menu[key as keyof T] = {
        show: options[key] as boolean,
      } as T[keyof T]
    } else {
      menu[key] = options[key]
    }
  }
  return menu as Record<keyof T, ISiteTreeOption>
}

export const AppStore = signalStore(
  { providedIn: 'root' },
  withState(initialStateApp),
  withComputed(store => ({
    siteTreeMenu: computed(() => {
      return parseSiteTree<ISiteTreeMenu>(store.siteTree()?.menu)
    }),
    siteTreeTabs: computed(() => {
      return parseSiteTree<ISiteTreeTabs>(store.siteTree()?.tabs)
    }),
    siteTreeHeader: computed(() => {
      return store.siteTree()?.header
    }),
    siteTreeHome: computed(() => {
      return parseSiteTree<ISiteTreeHome>(store.siteTree()?.home)
    }),
  })),
  withMethods((store, userSesource = inject(UserResource), creditResource = inject(CreditCashResource)) => {
    return {
      setParams: (paramsCredits: IParamsCredits) => {
        patchState(store, {
          paramsCredits,
        })
      },
      loadParamsCredits: () => {
        return userSesource.paramsCredits().pipe(
          map(paramsCredits => {
            patchState(store, {
              paramsCredits,
            })
          })
        )
      },
      loadCreditInfo: () => {
        return creditResource.info().pipe(
          map((info: ICreditInfo) => {
            patchState(store, {
              creditInfo: info,
            })
            return info
          })
        )
      },
      setSiteTree: (siteTree: ISiteTree) => {
        patchState(store, {
          siteTree,
        })
      },
      putUser: (user: UserModel) => {
        patchState(store, {
          user,
        })
      },
      creditInfoClear: () => {
        patchState(store, {
          creditInfo: null,
        })
      },
      paramsCreditsClear: () => {
        patchState(store, {
          paramsCredits: null,
        })
      },
      removeUser: () => {
        patchState(store, {
          user: null,
        })
      },
    }
  })
)
