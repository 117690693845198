import { createAction, props } from '@ngrx/store'
import { ERecordOption, IRecordCycle, IRecordPagination, IRecordSearchParams } from '@modules/record/interfaces/record'

export const setRecords = createAction(
  '[Records List] Set list Records',
  props<{
    entities: null | IRecordCycle[]
  }>()
)
export const pushRecords = createAction(
  '[Records List] Push next Records and edit pagination',
  props<{
    entities: IRecordCycle[]
    pagination: IRecordPagination
    option: ERecordOption
  }>()
)
export const setPagination = createAction(
  '[Records List] Set Search Params',
  props<{ pagination: IRecordPagination }>()
)
export const recordClearPagination = createAction('[Records List] Clear pagination')
export const recordsClearAll = createAction('[Records List] Clear state')
export const recordsClearEntities = createAction('[Records List] Clear entity list')
export const recordsClearAllEntities = createAction('[Records List] Clear all entity lists')
export const recordsLoading = createAction('[Records List] Set Loading', props<{ loading: boolean }>())
export const setSearchParams = createAction(
  '[Records List] Set Search Params',
  props<{ searchParams: IRecordSearchParams }>()
)
export const clearSearchParams = createAction('[Records List] Clear Search Params')
export const setRecordType = createAction('[Records List] Set Record Type', props<{ option: ERecordOption }>())

export const recordNeedsRefresh = createAction(
  '[Records List] Set records need to refresh boolean',
  props<{ needsRefresh: boolean }>()
)

export const setJumpRecordCycle = createAction(
  '[Records List] Set jump record cycle',
  props<{ entity: IRecordCycle }>()
)

export const ClearJumpRecordCycle = createAction('[Records List] Clear jump record cycle')
