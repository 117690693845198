import { Injectable } from '@angular/core'
import { App } from '@capacitor/app'
import { Capacitor } from '@capacitor/core'
import { environment } from '@environments/environment'
import { ModalController } from '@ionic/angular'
import type { IConfig } from '@modules/config/interfaces/config'
import { getFlagUpdateSelect } from '@modules/config/selectors/config.selectors'
import type { IUserState } from '@modules/user/interfaces/user'
import { select, Store } from '@ngrx/store'
import { compare } from 'compare-versions'
import { debounceTime, distinctUntilChanged, take } from 'rxjs/operators'
import { UpdateAppModalComponent } from 'src/app/pages/application/tabs/home/modal/update-app-modal/update-app-modal.component'

declare const CheckHuaweiServices: any

@Injectable({ providedIn: 'root' })
export class UpdateAppService {
  private _openUpdateModal = false
  private _dismissedUpdateModal = false

  constructor(private _store: Store<{ config: IConfig; user: IUserState }>, private _modalCtrl: ModalController) {}

  async init(): Promise<void> {
    let version: any = ''
    let platform = Capacitor.getPlatform()
    if (platform !== 'web') {
      const info = await App.getInfo()
      version = info.version
    } else {
      version = environment.APP_VERSION
    }
    version = version.replace(/[^0-9.]+/g, '')

    this._store
      .pipe(select(getFlagUpdateSelect), debounceTime(2000), distinctUntilChanged())
      .subscribe(async update => {
        if (!update || (update && Object.keys(update).length === 0)) {
          try {
            await this._modalCtrl.dismiss(null, undefined, 'modal-update-app')
          } catch (e) {}
          return
        }
        // for test in web dev, change key to android
        if (platform === 'web' && !environment.production) {
          platform = 'android'
        }
        if (platform === 'ios' || platform === 'android') {
          // check if is android
          if (platform === 'android') {
            try {
              // check if de huawei service is available and set platform huawei
              await CheckHuaweiServices.checkHuaweiServicesAvailability()
              platform = 'huawei'
            } catch (e) {}
          }

          const min = update?.versions[platform]?.min.replace(/[^0-9.]+/g, '')
          const max = update?.versions[platform]?.max.replace(/[^0-9.]+/g, '')
          if (compare(version, max, '=')) {
            // No update available, no action required. Close if modal is open
          } else if (compare(version, max, '<') && compare(version, min, '>=')) {
            // We can show the user a dismissable pop-up inside the app to inform them that they can download an update but don’t have to.
            this.updateAppModal(false, update?.versions[platform]?.updateUrl)
          } else if (compare(version, min, '<')) {
            try {
              await this._modalCtrl.dismiss(null, undefined, 'modal-update-app')
            } catch (e) {}
            this._dismissedUpdateModal = false
            // The app is not compatible with the back end and must be force-updated. We will show the user a modal,
            // non-dismissable pop-up that explains that they have to update the app to be able to use it.
            this.updateAppModal(true, update?.versions[platform]?.updateUrl)
          }
        }
      })
  }

  async updateOpen(force = false): Promise<void> {
    this._dismissedUpdateModal = false
    this._openUpdateModal = false
    let platform = Capacitor.getPlatform()
    this._store.pipe(select(getFlagUpdateSelect), take(1)).subscribe(async update => {
      // for test in web dev, change key to android
      if (platform === 'web' && !environment.production) {
        platform = 'android'
      }
      if (platform === 'ios' || platform === 'android') {
        // check if is android
        if (platform === 'android') {
          try {
            // check if de huawei service is available and set platform huawei
            await CheckHuaweiServices.checkHuaweiServicesAvailability()
            platform = 'huawei'
          } catch (e) {}
        }
        this.updateAppModal(force, update?.versions[platform]?.updateUrl)
      }
    })
  }

  async updateAppModal(force = false, url: string): Promise<void> {
    // if dismissed modal and not is force
    if (this._dismissedUpdateModal) {
      return
    }
    // flag for no reopen multiple modals for update
    if (this._openUpdateModal) {
      return
    }
    this._openUpdateModal = true
    const modal = await this._modalCtrl.create({
      id: 'modal-update-app',
      component: UpdateAppModalComponent,
      backdropDismiss: !force,
      cssClass: 'modal-dialog',
      componentProps: {
        force,
        url,
      },
    })
    await modal.present()
    const { data } = await modal.onWillDismiss()
    if (data?.dismissed) {
      this._dismissedUpdateModal = true
    }
    this._openUpdateModal = false
  }
}
