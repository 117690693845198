import type { EntityAdapter } from '@ngrx/entity'
import { createEntityAdapter } from '@ngrx/entity'
import { createReducer, on } from '@ngrx/store'
import {
  montoCashClear,
  montoCashLoading,
  montoCashSetBalance,
  montoCashSetIdemPotency,
  montoCashSetWithdrawal,
} from '../actions/monto-cash.actions'
import type { IMontoCash, IMontoCashState } from '../interfaces/monto-cash'

export const adapter: EntityAdapter<IMontoCash> = createEntityAdapter<IMontoCash>()

export const initialState: IMontoCashState = adapter.getInitialState({
  loading: false,
  withdrawal: null,
  balance: null,
  idemPotency: null,
})

const _montoCashReducer = createReducer(
  initialState,
  on(montoCashLoading, (state, { loading }) => ({
    ...state,
    loading,
  })),
  on(montoCashSetWithdrawal, (state, { withdrawal }) => ({
    ...state,
    withdrawal,
  })),
  on(montoCashSetBalance, (state, { balance }) => ({
    ...state,
    balance,
  })),
  on(montoCashClear, state => ({
    ...state,
    loading: false,
    withdrawal: null,
    idemPotency: null,
  })),
  on(montoCashSetIdemPotency, (state, { idemPotency }) => ({
    ...state,
    idemPotency,
  }))
)

export const montoCashReducer = (state: any, action: any): any => _montoCashReducer(state, action)
export const { selectAll, selectIds, selectEntities } = adapter.getSelectors()
