import { Component, Input } from '@angular/core'
import { Browser } from '@capacitor/browser'
import { ModalController } from '@ionic/angular'
import { IBenefit } from '@modules/benefit/interfaces/benefit'

/**
 * Benefit terms modal component generic
 */
@Component({
  selector: 'app-benefit-terms-modal',
  templateUrl: 'benefit-terms-modal.component.html',
  styleUrls: ['benefit-terms-modal.component.scss'],
})
export class BenefitTermsModalComponent {
  /**
   * Benefit
   * @type {IBenefit}
   */
  @Input() benefit!: IBenefit

  @Input() title = 'Términos y condiciones'

  @Input() body: string | null = null

  constructor(public _modalController: ModalController) {}

  /**
   * Open link in browser
   * @param {string} url
   * @returns {Promise<void>}
   */
  async onOpenLink(url: string) {
    await Browser.open({ url })
  }

  /**
   * Dismiss modal
   */
  onDissmiss() {
    this._modalController.dismiss()
  }
}
