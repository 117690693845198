import { createAction, props } from '@ngrx/store'
import {
  IPaymentServiceBalance,
  IPaymentServiceBiller,
  IPaymentServiceBillerGroup,
  IPaymentServiceByCategory,
  IPaymentServiceSummary,
} from '@modules/payment-services/interfaces/payment-services'
import { ICheckoutCard } from '@modules/checkout/checkout'

export const paymentServiceLoading = createAction('[Payment Service] Set Loading', props<{ loading: boolean }>())
export const paymentServiceError = createAction('[Payment Service] Set Error Service', props<{ error: boolean }>())
export const paymentServiceSetIdemPotency = createAction(
  '[Payment Service] Set idemPotency ',
  props<{ idemPotency: string }>()
)
export const paymentServiceBiller = createAction(
  '[Payment Service] Set Data ',
  props<{ entity: IPaymentServiceBiller }>()
)
export const paymentServiceBillerGroup = createAction(
  '[Payment Service] Set Group Data ',
  props<{ group: IPaymentServiceBillerGroup }>()
)

export const setCategoriesServiceBillerGroup = createAction(
  '[Payment Service] Set Categories',
  props<{ categories: IPaymentServiceByCategory[] }>()
)

export const setCategoryServiceBillerGroup = createAction(
  '[Payment Service] Set Category',
  props<{ category: IPaymentServiceByCategory }>()
)

export const clearCategoryServiceBillerGroup = createAction('[Payment Service] Clear Category')

export const paymentServiceLoadBalance = createAction('[Payment Service] Load Balance ')
export const paymentServiceSetBalance = createAction(
  '[Payment Service] Set Balance ',
  props<{ balance: IPaymentServiceBalance }>()
)
export const paymentServiceLoadSummary = createAction('[Payment Service] Load Summary ')
export const paymentServiceSetSummary = createAction(
  '[Payment Service] Set Summary ',
  props<{ summary: IPaymentServiceSummary; card: ICheckoutCard | null }>()
)
export const paymentServiceClear = createAction('[Payment Service] Clear state ')
