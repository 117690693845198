import { Component, Input } from '@angular/core'
import { Browser } from '@capacitor/browser'
import { ModalController, NavController } from '@ionic/angular'

@Component({
  selector: 'app-update-app-modal',
  templateUrl: './update-app-modal.component.html',
  styleUrls: ['./update-app-modal.component.scss'],
})
export class UpdateAppModalComponent {
  @Input() force = false
  @Input() url = ''

  constructor(public modalController: ModalController, private navController: NavController) {
    /* Nothing */
  }

  async onUpdateOpen(): Promise<void> {
    window.location.href = this.url
    return
    await Browser.open({ url: this.url })
  }

  dismiss(): void {
    this.modalController.dismiss({
      dismissed: true,
    })
  }
}
