import type { EntityAdapter } from '@ngrx/entity'
import { createEntityAdapter } from '@ngrx/entity'
import { createReducer, on } from '@ngrx/store'
import {
  benefitLoading,
  clearBenefit,
  clearBenefitCards,
  clearBenefitCategory,
  clearBenefitPremiumPlanSummary,
  clearBenefitPurchase,
  clearBenefitSubscriptionDetails,
  likeBenefit,
  setBenefit,
  setBenefitCards,
  setBenefitCategories,
  setBenefitCategory,
  setBenefitConfirmSubscriptionMode,
  setBenefitList,
  setBenefitPremiumPlanSummary,
  setBenefitPurchase,
  setBenefitSubscriptionDetails,
  setBenefitSuccess,
  unlikeBenefit,
  updateBenefit,
} from '../actions/benefit.actions'
import { IBenefit, IBenefitState } from '@modules/benefit/interfaces/benefit'

export const adapter: EntityAdapter<IBenefit> = createEntityAdapter<IBenefit>()

export const initialState: IBenefitState = adapter.getInitialState({
  loading: false,
  benefit: null,
  benefitSelected: null,
  purchase: null,
  entities: null,
  cards: null,
  categories: null,
  category: null,
  confirmSupscriptionMode: false,
  summary: null,
  subscriptionDetails: null,
})

const _benefitReducer = createReducer(
  initialState,
  on(benefitLoading, (state, { loading }) => ({
    ...state,
    loading,
  })),
  on(setBenefitConfirmSubscriptionMode, (state, { confirm }) => ({
    ...state,
    confirmSupscriptionMode: confirm,
  })),
  on(setBenefit, (state, { benefit }) => ({
    ...state,
    benefit,
  })),
  on(updateBenefit, (state, { update }) => {
    return adapter.updateOne(update, state)
  }),
  on(likeBenefit, (state, { benefit }) => {
    return adapter.updateOne(
      {
        id: benefit.id,
        changes: {
          liked: true,
        },
      },
      state
    )
  }),
  on(unlikeBenefit, (state, { benefit }) => {
    return adapter.updateOne(
      {
        id: benefit.id,
        changes: {
          liked: false,
        },
      },
      state
    )
  }),
  on(clearBenefit, state => ({
    ...state,
    benefit: null,
  })),
  on(setBenefitPurchase, (state, { purchase }) => ({
    ...state,
    purchase: {
      ...state.purchase,
      ...purchase,
    },
  })),
  on(clearBenefitPurchase, state => ({
    ...state,
    purchase: null,
  })),
  on(setBenefitList, (state, { benefits }) => {
    return adapter.setAll(benefits, state)
  }),
  on(setBenefitCategories, (state, { categories }) => ({
    ...state,
    categories,
  })),
  on(setBenefitCategory, (state, { category }) => ({
    ...state,
    category,
  })),
  on(clearBenefitCategory, state => ({
    ...state,
    category: null,
  })),
  on(setBenefitCards, (state, { cards }) => ({
    ...state,
    cards,
  })),
  on(clearBenefitCards, state => ({
    ...state,
    cards: null,
  })),
  on(setBenefitPremiumPlanSummary, (state, { summary }) => ({
    ...state,
    summary,
  })),
  on(clearBenefitPremiumPlanSummary, state => ({
    ...state,
    summary: null,
  })),
  on(setBenefitSubscriptionDetails, (state, { details }) => ({
    ...state,
    subscriptionDetails: details,
  })),
  on(clearBenefitSubscriptionDetails, state => ({
    ...state,
    subscriptionDetails: null,
  })),
  on(setBenefitSuccess, (state, { success }) => ({
    ...state,
    success,
  }))
)

export const benefitReducer = (state: any, action: any): any => _benefitReducer(state, action)

export const {
  // select the array of benefits
  selectAll: selectAllBenefits,
} = adapter.getSelectors()
