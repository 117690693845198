import type { OnDestroy, OnInit } from '@angular/core'
import { Injectable } from '@angular/core'
import type { ActivatedRoute, Router } from '@angular/router'
import { NavigationEnd } from '@angular/router'
import type { NavController } from '@ionic/angular'
import { Subject } from 'rxjs'
import { filter, takeUntil } from 'rxjs/operators'
import type { SplitPaneService } from './split-pane.service'

@Injectable({ providedIn: 'root' })
/**
 * @deprecated
 */
export class PlatformWebService implements OnInit, OnDestroy {
  public goBack = false
  protected _router!: Router
  protected _navCtrl!: NavController
  protected _route!: ActivatedRoute
  protected _splitPaneService!: SplitPaneService
  protected _unsubscribeAll = new Subject()

  // eslint-disable-next-line @angular-eslint/contextual-lifecycle
  ngOnInit(): void {
    this._router.events
      .pipe(
        filter((e): e is NavigationEnd => e instanceof NavigationEnd),
        takeUntil(this._unsubscribeAll)
      )
      .subscribe((response: NavigationEnd) => {
        this._splitPaneService.setPaneStatus(true)
      })
    if (this._route) {
      this._route.queryParams.pipe(takeUntil(this._unsubscribeAll)).subscribe((data: any) => {
        if (data?.goBack) {
          this.goBack = true
        }
      })
    }
  }

  async ionViewWillEnter(): Promise<void> {
    this._splitPaneService.setPaneStatus(true)
  }

  ionViewDidLeave(): void {
    this._splitPaneService.setPaneStatus(true)
  }

  ngOnDestroy(): void {
    this._unsubscribeAll.next(true)
    this._unsubscribeAll.complete()
  }

  onBack(): void {
    this._navCtrl.back()
  }
}
