import { createReducer, on } from '@ngrx/store'
import type { EntityAdapter } from '@ngrx/entity'
import { createEntityAdapter } from '@ngrx/entity'
import { ISiteTree, ISiteTreeState } from '@modules/app/interfaces/site-tree'
import { clearSiteTree, loadingSiteTree, setSiteTree } from '@modules/app/actions/site-tree.actions'

export const adapter: EntityAdapter<ISiteTree> = createEntityAdapter<ISiteTree>()

export const initialState: ISiteTreeState = adapter.getInitialState({
  siteTree: null,
  loading: false,
})

const _siteTreeReducer = createReducer(
  initialState,
  on(loadingSiteTree, (state, { loading }) => ({
    ...state,
    loading,
  })),
  on(setSiteTree, (state, { siteTree }) => ({
    ...state,
    siteTree: siteTree,
  })),
  on(clearSiteTree, state => ({
    ...state,
    siteTree: null,
    loading: false,
  }))
)

export const siteTreeReducer = (state: any, action: any): any => _siteTreeReducer(state, action)
export const { selectAll, selectIds, selectEntities } = adapter.getSelectors()
