import { createAction, props } from '@ngrx/store'
import { IMontoCashWithdrawal } from '../interfaces/monto-cash'

export const montoCashLoading = createAction('[Cash Monto] Set Loading', props<{ loading: boolean }>())
export const montoCashLoadBalance = createAction('[Cash Monto Load Balance Get API]')
export const montoCashWithdrawal = createAction('[Cash Monto Withdrawal Save API]')
export const montoCashClear = createAction('[Cash Monto Clear]')
export const montoCashSetIdemPotency = createAction('[Cash Monto] Set idemPotency ', props<{ idemPotency: string }>())
export const montoCashSetWithdrawal = createAction(
  '[Cash Monto Withdrawal] Set Withdrawal',
  props<{ withdrawal: IMontoCashWithdrawal }>()
)
export const montoCashSetBalance = createAction('[Cash Monto Balance] Set Balance', props<{ balance: any }>())
