import { INews } from '@modules/news/interfaces/news'
import { AllParameters } from 'rxfire/remote-config'
import { IStore } from '@components/pages/stores/stores'

export type ConfigTypes = 'boolean' | 'object' | 'string' | 'number'

/**
 * if defined flag  suffix _USERS, check and set the flag to the users defined in the array
 *
 * check src/app/modules/config/services/config.service.ts:134
 *
 * example:
 *
 * FLAG_TOPUPS?: boolean //flag for featured
 *
 * FLAG_TOPUPS_USERS?: any[] //user to activate the flag
 */
export interface IConfig {
  FLAG_INTERVAL_RECOVERY_CONFIG?: number
  FLAG_UPDATE?: any
  FLAG_MFA?: boolean
  FLAG_STORE?: boolean
  FLAG_CREDIT_CASH?: boolean
  FLAG_GEOLOCATION?: boolean
  FLAG_CREDIT_CASH_USERS?: string[]
  STORE_CONFIG: {
    [key: string]: any
  }
  CONFIG: {
    CASHOUT_TIMEOUT_MINUTE: number
    CASHOUT_LIMIT_AMOUNT: number
    PAYMENT_QR_TIMEOUT_MINUTE: number
    INSTRUCTIONS_CASHOUT: string
    INSTRUCTIONS_PAYMENT_QR: string
    BENEFIT_SUBSCRIPTION_PRICE: number
    PAYMENT_METHODS: Record<any, any>
  }
  STABLISHMENTS_PAYMENT: IStore[]
  STABLISHMENTS_CASHOUT: IStore[]
  FLAG_CASHOUT?: boolean
  FLAG_CASHOUT_USERS?: string[]
  FLAG_CASHOUT_SALARY_TYPE?: string[]
  FLAG_PAYMENT_QR?: boolean
  FLAG_PAYMENT_QR_USERS?: string[]
  FLAG_PAYMENT_QR_READ?: boolean
  FLAG_PAYMENT_QR_READ_USERS?: string[]
  FLAG_PAYMENT_QR_SALARY_TYPE?: string[]
  FLAG_BIOMETRIC?: boolean
  FLAG_CHECKOUT: boolean
  CONFIG_REFER?: Record<any, any>
  CONFIG_CHATBOT?: {
    active: boolean
    keys: {
      web: {
        accessKey: string
      }
      android: {
        appKey: string
        accessKey: string
      }
      ios: {
        appKey: string
        accessKey: string
      }
    }
  }
  NEWS_HOME?: {
    CONFIG_SLIDE: Record<any, any>
    NEWS: INews[]
  }
  MONTO_CASH_MINIMUM_WITHDRAWAL?: number
  CONFIF_SERVICE_INACTIVE: Record<any, any>
  FLAG_CARD_VERIFICATION?: boolean
}

export interface OnConfig {
  fetch(): void

  parse(config?: AllParameters): void
}

// src/app/modules/config/services/config.service.ts:parse
export enum EConfig {
  STABLISHMENTS_PAYMENT = 'object',
  FLAG_GEOLOCATION = 'boolean',
  STABLISHMENTS_CASHOUT = 'object',
  FLAG_PAYMENT_QR_READ = 'boolean',
  FLAG_PAYMENT_QR_READ_USERS = 'object',
  FLAG_MFA = 'boolean',
  FLAG_INTERVAL_RECOVERY_CONFIG = 'number',
  FLAG_CASHOUT = 'boolean',
  FLAG_CASHOUT_USERS = 'object',
  FLAG_CASHOUT_SALARY_TYPE = 'object',
  FLAG_PAYMENT_QR = 'boolean',
  FLAG_PAYMENT_QR_USERS = 'object',
  FLAG_PAYMENT_QR_SALARY_TYPE = 'object',
  CONFIG = 'object',
  STORE_CONFIG = 'object',
  FLAG_UPDATE = 'object',
  FLAG_STORE = 'boolean',
  FLAG_CREDIT_CASH = 'boolean',
  FLAG_CREDIT_CASH_USERS = 'object',
  FLAG_GEOLOCATION_USERS = 'object',
  FLAG_BIOMETRIC = 'boolean',
  CONFIG_REFER = 'object',
  FLAG_CHECKOUT = 'boolean',
  MONTO_CASH_MINIMUM_WITHDRAWAL = 'number',
  NEWS_HOME = 'object',
  CONFIF_SERVICE_INACTIVE = 'object',
  CONFIG_CHATBOT = 'object',
  FLAG_CARD_VERIFICATION = 'boolean',
}
