import { IBiometricState } from '@modules/biometric/biometric.state'
import { createReducer, on } from '@ngrx/store'
import { activeBiometric, availableBiometric, doLoginBiometric } from '@modules/biometric/state/biometric.actions'

export const initialState: IBiometricState = <IBiometricState>{
  doLogin: false,
  activated: false,
  available: false,
}
const _configReducer = createReducer(
  initialState,
  on(doLoginBiometric, (state, { doLogin }) => ({
    ...state,
    doLogin: doLogin,
  })),
  on(activeBiometric, (state, { activated }) => ({
    ...state,
    activated: activated,
  })),
  on(availableBiometric, (state, { available }) => ({
    ...state,
    available: available,
  }))
)

export const biometricReducer = (state: IBiometricState, action: any): any => _configReducer(state, action)
