import type { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { environment } from '@environments/environment'
import { PlatformService } from '@services/platform.service'
import type { Observable } from 'rxjs'

/**
 * Class in charge of sending the app version to server using custom HTTP
 * headers. This version is used to decide whether to accept or not the request
 * based on the app version.
 */
@Injectable({ providedIn: 'root' })
export class AppVersionInterceptor implements HttpInterceptor {
  constructor(private platformService: PlatformService) {
    /** Do nothing */
  }

  /** HACK (Rodolfo Islas): Version should be obtained from an environment
   *                        variable.
   */
  /**
   * Intercept request before being sent to server. This method will add the
   * custom X-Version HTTP header only if app is running on mobile devices and
   * will get the app version from environment variables.
   *
   * @param req Request being handled
   * @param next HttpHandler to handle request (continue sending request after
   *             intercepting and modifying request)
   */
  intercept(req: HttpRequest<string>, next: HttpHandler): Observable<HttpEvent<string>> {
    if (req.params.get('skipInterceptor')) {
      return next.handle(req)
    }
    let request = req.clone()

    const appVersionHeaders = {
      headers: req.headers.append('X-Version', environment.APP_VERSION),
    }
    request = req.clone(appVersionHeaders)

    return next.handle(request)
  }
}
