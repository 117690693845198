import { createAction, props } from '@ngrx/store'
import {
  IGiftCardBalance,
  IGiftCardBiller,
  IGiftCardBillerGroup,
  IGiftCardBillers,
  IGiftCardSummary,
} from '@modules/gift-card/interfaces/gift-card'
import { ICheckoutCard } from '@modules/checkout/checkout'

export const giftCardLoading = createAction('[Gift Card] Set Loading', props<{ loading: boolean }>())
export const giftCardError = createAction('[Gift Card] Set Error Service', props<{ error: boolean }>())
export const giftCardSetIdemPotency = createAction('[Gift Card] Set idemPotency ', props<{ idemPotency: string }>())
export const giftCardBiller = createAction('[Gift Card] Set Data ', props<{ entity: IGiftCardBiller }>())
export const giftCardBillerGroup = createAction('[Gift Card] Set Group Data ', props<{ group: IGiftCardBillerGroup }>())
export const giftCardSetBalance = createAction('[Gift Card] Set Balance ', props<{ balance: IGiftCardBalance }>())
export const giftCardLoadSummary = createAction('[Gift Card] Load Summary ')
export const giftCardSetSummary = createAction(
  '[Gift Card] Set Summary ',
  props<{ summary: IGiftCardSummary; card: ICheckoutCard | null }>()
)
export const giftCardClear = createAction('[Gift Card] Clear state ')
export const giftCardLoadBillers = createAction('[Gift Card] Load List ')
export const giftCardSetBillers = createAction('[Gift Card] Set Billers ', props<{ entities: IGiftCardBillers }>())
