import { HttpClient } from '@angular/common/http'
import { ChangeDetectionStrategy, Component, HostBinding, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core'
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser'
import { Subject } from 'rxjs'
import { AtomColor, TNameIcon } from '@atoms/shared/helper'

@Component({
  selector: 'monto-icon',
  template: `
    <div id="container-icon">
      <ion-icon [attr.color]="color" [src]="url" class="{{ class }}"></ion-icon>
    </div>
  `,
  styleUrls: ['icon.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export default class IconComponent implements OnChanges {
  @Input() name!: TNameIcon | string
  @Input() size: 'small' | 'medium' | 'large' | 'full' = 'medium'
  protected src!: SafeResourceUrl | undefined
  protected url!: string
  @Input() color: AtomColor | null = 'primary'
  @Input() class: string | null = null
  protected _unsubscribeAll: Subject<any> = new Subject<any>()
  @HostBinding('class.full') get isFull(): boolean {
    return this.size === 'full'
  }

  ngOnChanges(): void {
    this.url = 'icon-svgs/' + this.name + '.svg'
  }
  constructor(private _domSanitizer: DomSanitizer, private _httpClient: HttpClient) {}
}
