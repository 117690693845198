import { Injectable } from '@angular/core'
import { StorageService } from '@services/storage.service'
import * as CryptoJS from 'crypto-js'
import { Credentials } from 'capacitor-native-biometric/dist/esm/definitions'
import { Preferences } from '@capacitor/preferences'
import { NavController } from '@ionic/angular'
import { SessionService } from '@services/session.service'

@Injectable({
  providedIn: 'root',
})
export class CredentialsService {
  /**
   * Seed to encrypt the data
   * @type {string}
   */
  seed = ''

  constructor(
    private _navCtrl: NavController,
    private storage: StorageService,
    private _sessionService: SessionService
  ) {
    this.recoverySeed().then(seed => (this.seed = seed))
  }

  /**
   * Generate a seed to encrypt the data
   * @returns {string}
   */
  generateSeed(): string {
    return new Date().valueOf().toString()
  }

  /**
   * Get the seed to encrypt the data
   * @returns {Promise<string>}
   */
  async recoverySeed(): Promise<string> {
    const { value } = await Preferences.get({ key: 'seed' })
    if (value) return value
    const newSeed = this.generateSeed()
    await Preferences.set({
      key: 'seed',
      value: newSeed,
    })
    return newSeed
  }

  /**
   * Get credentials
   * @returns {Promise<any>}
   */
  decryptCredentials(credentials: Credentials): Credentials {
    credentials.username = this.decryptSeed(credentials.username)
    credentials.password = this.decryptSeed(credentials.password)
    return credentials
  }

  /**
   * Set credentials
   */
  encryptCredentails(credentials: Credentials): Credentials {
    const username = this.encryptSeed(credentials.username)
    const password = this.encryptSeed(credentials.password)
    return { username, password }
  }

  /**
   * Encrypt seed
   * @param {string} text
   * @returns {string}
   */
  encryptSeed(text: string): string {
    return CryptoJS.AES.encrypt(text, this.seed).toString()
  }

  /**
   * Decrypt seed
   * @param {string} text
   * @returns {string}
   */
  decryptSeed(text: string): string {
    return CryptoJS.AES.decrypt(text, this.seed).toString(CryptoJS.enc.Utf8)
  }
}
