import {
  userVerifyInfoUpdate,
  userVerifyInfoClean,
  userLoading,
  userSession,
  updateUserSession,
  updateProfileSession,
  tutorialFinishedUpdate,
  userPutBalance,
  putIsFirstTimeLogin,
} from './../actions/user.actions'
import type { EntityAdapter } from '@ngrx/entity'
import { createEntityAdapter } from '@ngrx/entity'
import { createReducer, on } from '@ngrx/store'
import { removeSession, userSessionProfile } from '../actions/user.actions'
import type { IUser, IUserState } from '../interfaces/user'
import { ProfileModel } from '@modules/user/models/profile.model'
import { UserModel } from '@modules/user/models/user.model'

export const adapter: EntityAdapter<IUser> = createEntityAdapter<IUser>()

export const initialState: IUserState = adapter.getInitialState({
  userSession: null,
  verifyInfo: null,
  user: null,
  loading: false,
  profile: null,
  balance: undefined,
  balanceFuture: undefined,
  isFirstTimeLogin: false,
})

const _userReducer = createReducer(
  initialState,
  on(putIsFirstTimeLogin, (state, { isFirstTimeLogin }) => ({
    ...state,
    user: {
      ...state.user,
      isFirstTimeLogin: isFirstTimeLogin,
    } as UserModel,
    isFirstTimeLogin,
  })),
  on(userLoading, (state, { loading }) => ({
    ...state,
    loading,
  })),
  on(userSessionProfile, (state, { user }) => ({
    ...state,
    userSession: user,
  })),
  on(userPutBalance, (state, { balance, balanceFuture }) => ({
    ...state,
    balance,
    balanceFuture,
  })),
  on(userSession, (state, { user }) => ({
    ...state,
    user: {
      ...state.user,
      ...user,
      isFirstTimeLogin: state.isFirstTimeLogin,
    } as UserModel,
  })),
  on(updateUserSession, (state, { user }) => ({
    ...state,
    user: {
      ...state.user,
      ...user,
    } as UserModel,
  })),
  on(updateProfileSession, (state, { profile }) => ({
    ...state,
    user: {
      ...state.user,
      profile: {
        ...state.user?.profile,
        ...profile,
      } as ProfileModel,
    } as UserModel,
  })),
  on(removeSession, state => ({
    ...state,
    userSession: null,
    user: null,
  })),
  on(userVerifyInfoUpdate, (state, { verifyInfo }) => ({
    ...state,
    verifyInfo: { ...state.verifyInfo, ...verifyInfo },
  })),
  on(userVerifyInfoClean, state => ({
    ...state,
    verifyInfo: null,
  })),
  on(tutorialFinishedUpdate, (state, { key, value }) => ({
    ...state,
    user: {
      ...state.user,
      profile: {
        ...state.user?.profile,
        tutorials: {
          ...state.user?.profile?.tutorials,
          ...{ [key]: value },
        },
      } as ProfileModel,
    } as UserModel,
  }))
)

export const userReducer = (state: any, action: any): any => _userReducer(state, action)
export const { selectAll, selectIds, selectEntities } = adapter.getSelectors()
