import { Injectable } from '@angular/core'
import { BehaviorSubject, Observable } from 'rxjs'

@Injectable({ providedIn: 'root' })
export default class IconService {
  private loadedIcons: Record<string, any> = {}
  private _icon: BehaviorSubject<Record<string, any>> = new BehaviorSubject({})

  /**
   * Setter & getter for user
   *
   * @param value
   */
  set icon(value: Record<string, any>) {
    this._icon.next({
      ...this._icon.getValue(),
      ...value,
    })
  }

  get icon$(): Observable<Record<string, any>> {
    return this._icon.asObservable()
  }

  getIcon(name: string) {
    return this.loadedIcons[name]
  }

  setIcon(name: string, svg: any) {
    this.loadedIcons[name] = svg
  }
}
