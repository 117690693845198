import { Injectable } from '@angular/core'
import { ToastService } from '@services/toast.service'

@Injectable({
  providedIn: 'root',
})
export class ErrorService {
  constructor(private _toastService: ToastService) {}

  manipulateError(errorResponse: any) {
    const error = errorResponse?.error || errorResponse?.body
    let message = ''
    if (error && error?.errors) {
      message = error.errors.toString()
      this._toastService.danger({
        message: message,
      })
    } else if (error && error?.error) {
      message = error.error.toString()
      this._toastService.danger({
        message: message,
      })
    } else {
      message = 'No se pudo generar el movimiento, intente más tarde.'
      this._toastService.danger({
        message: message,
      })
    }
    return message
  }
}
