import { ICreditInfo, ICreditLoan } from '@modules/credit-cash/interfaces/credit-cash'
import { createAction, props } from '@ngrx/store'

export const creditCashStateLoad = createAction('[Credit Cash] Load Credit Loans via Service')

export const setCreditLoan = createAction(
  '[Credit Cash] Set data for create credit loan',
  props<{ creditLoan: ICreditLoan }>()
)
export const updateCreditLoan = createAction(
  '[Credit Cash] Update data of created credit loan',
  props<{ creditLoan: Partial<ICreditLoan> }>()
)
export const creditCashClear = createAction('[Credit Cash] Clear data to create a credit loan')
export const creditCashAllow = createAction('[Credit Cash] If user allow credit loans', props<{ allow: boolean }>())
export const creditCashLoading = createAction('[Credit Cash] Set Loading', props<{ loading: boolean }>())

export const setCreditInfo = createAction(
  '[Credit Cash] Set data to create a credit loan',
  props<{ creditInfo: ICreditInfo }>()
)
