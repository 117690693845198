import { NgModule } from '@angular/core'
import { IonicModule } from '@ionic/angular'
import { NotificationLoadingComponent } from './notification-loading.component'

@NgModule({
  imports: [IonicModule],
  exports: [NotificationLoadingComponent],
  declarations: [NotificationLoadingComponent],
})
export class NotificationLoadingModule {}
