import { Injectable } from '@angular/core'
import { environment } from 'src/environments/environment'
import { StorageService } from './storage.service'
import { CookieService } from 'ngx-cookie-service'
import { Capacitor } from '@capacitor/core'

@Injectable({
  providedIn: 'root',
})
export class SessionService {
  private domain: string = ''

  constructor(private storage: StorageService, protected _cookieService: CookieService) {
    this.getDomain()
  }

  getDomain() {
    //check if is ip
    if (/^(?:[0-9]{1,3}\.){3}[0-9]{1,3}$/.test(window.location.hostname)) {
      this.domain = window.location.hostname
      return
    }

    //check if is localhost
    if (window.location.hostname === 'localhost') {
      this.domain = window.location.hostname
      return
    }

    const hostname = window.location.hostname.split('.')
    //join sonly domain
    this.domain = `.${hostname.slice(-2).join('.')}`
  }

  getSession(): Promise<string> {
    return this.storage.get(environment.COOKIE.APPLICATION)
  }

  getSessionRefresh(): Promise<string> {
    return this.storage.get(environment.COOKIE.APPLICATION_REFRESH)
  }

  setSession(token: string): Promise<void> {
    return this.storage.set(environment.COOKIE.APPLICATION, token)
  }

  setSessionMFA(token: string) {
    if (Capacitor.isNativePlatform()) {
      return this.storage.set(environment.COOKIE.SESSION_TOKEN, token)
    }
    return this._cookieService.set(environment.COOKIE.SESSION_TOKEN, token, undefined, '/', this.domain)
  }

  async getSessionMFA() {
    if (Capacitor.isNativePlatform()) {
      return await this.storage.get(environment.COOKIE.SESSION_TOKEN)
    }
    return this._cookieService.get(environment.COOKIE.SESSION_TOKEN)
  }

  setSessionChallengeName(challengeName: string) {
    if (Capacitor.isNativePlatform()) {
      return this.storage.set(environment.COOKIE.SESSION_CHALLENGE_NAME, challengeName)
    }
    return this._cookieService.set(
      environment.COOKIE.SESSION_CHALLENGE_NAME,
      challengeName,
      undefined,
      '/',
      this.domain
    )
  }

  async getSessionChallengeName() {
    if (Capacitor.isNativePlatform()) {
      return await this.storage.get(environment.COOKIE.SESSION_CHALLENGE_NAME)
    }
    return this._cookieService.get(environment.COOKIE.SESSION_CHALLENGE_NAME)
  }

  setSessionIdentifier(identifier: string) {
    if (Capacitor.isNativePlatform()) {
      return this.storage.set(environment.COOKIE.SESSION_IDENTIFIER, identifier)
    }
    return this._cookieService.set(environment.COOKIE.SESSION_IDENTIFIER, identifier, undefined, '/', this.domain)
  }

  async getSessionIdentifier() {
    if (Capacitor.isNativePlatform()) {
      return await this.storage.get(environment.COOKIE.SESSION_IDENTIFIER)
    }
    return this._cookieService.get(environment.COOKIE.SESSION_IDENTIFIER)
  }

  setSessionRefresh(token: string): Promise<void> {
    return this.storage.set(environment.COOKIE.APPLICATION_REFRESH, token)
  }

  /// <reference path="credentials.service.ts" />
  async destroySession(): Promise<void> {
    await this.storage.remove('LAST_NOTIFICATIONS_ID')
    await this.storage.remove(environment.COOKIE.APPLICATION_REFRESH)
    await this.removeToken()
  }

  removeMFA() {
    if (Capacitor.isNativePlatform()) {
      this.storage.remove(environment.COOKIE.SESSION_IDENTIFIER)
      this.storage.remove(environment.COOKIE.SESSION_TOKEN)
      this.storage.remove(environment.COOKIE.SESSION_CHALLENGE_NAME)
    }
    this._cookieService.delete(environment.COOKIE.SESSION_IDENTIFIER, '/', this.domain)
    this._cookieService.delete(environment.COOKIE.SESSION_TOKEN, '/', this.domain)
    this._cookieService.delete(environment.COOKIE.SESSION_CHALLENGE_NAME, '/', this.domain)
  }

  async removeToken(): Promise<void> {
    await this.storage.remove(environment.COOKIE.APPLICATION)
    this.destroyCookie()
  }

  destroyCookie(): void {
    this._cookieService.delete(environment.COOKIE.APPLICATION_TKN, '/', this.domain)
    this._cookieService.delete(environment.COOKIE.APPLICATION_TKN_REFRESH, '/', this.domain)
  }
}
