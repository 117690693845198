import { ControlValueAccessor, FormControl, ValidationErrors, Validator } from '@angular/forms'
import { Directive, EventEmitter, Input, Output } from '@angular/core'

@Directive()
export class ControlValueMixinClass implements ControlValueAccessor, Validator {
  protected _touched!: Function
  protected _change!: Function
  @Input() value: any = null
  @Input() disabled: boolean | null = false

  @Output()
  // eslint-disable-next-line @angular-eslint/no-output-on-prefix
  onChange: EventEmitter<any> = new EventEmitter<any>()

  // eslint-disable-next-line no-unused-vars
  validate({ value }: FormControl): ValidationErrors | null {
    return null
  }

  dispatchChange(value: any): void {
    if (this._change) {
      this._change(value)
    }
    if (this._touched) {
      this._touched()
    }
    this.onChange.emit(this.value)
  }

  changed($event: any): void {
    this.value = $event.target.value
    this.dispatchChange(this.value)
  }

  registerOnChange(fn: any): void {
    this._change = fn
  }

  registerOnTouched(fn: any): void {
    this._touched = fn
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled
  }

  writeValue(obj: any): void {
    this.value = obj
  }
}
