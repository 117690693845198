import { Directive, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core'
import { ControlValueMixinClass } from './controlValueMixinClass'
import { Capacitor } from '@capacitor/core'
import { IonInput } from '@ionic/angular'

@Directive()
export class InputMixinClass extends ControlValueMixinClass implements OnChanges, OnInit {
  @Input() autocomplete: string | null = null

  @Input() override value: any = null

  @Input()
  color: 'primary' = 'primary'

  @Input()
  inputmode?: string

  @Input()
  border = true

  @Input()
  required?: any

  @Input()
  id: string | null = null

  @Input()
  minlength: number | null | string = null

  @Input()
  maxlength: number | null | string = null

  @Input()
  placeholder: string | null = null

  @Input()
  label: string | null = null

  @Input()
  name: string | null = null

  @Input()
  min: any | null = null

  @Input()
  max: any | null = null

  @Input()
  autofill: boolean = false

  @Input()
  readonly?: boolean
  classes: string[] = []

  @ViewChild(IonInput, { static: true }) protected input!: IonInput

  // eslint-disable-next-line no-unused-vars
  ngOnChanges(_changes: SimpleChanges): void {
    this.classes = this.getClasses()
  }

  public getClasses(): string[] {
    return [
      `color-${this.color}`,
      ...(this.border ? ['border-solid', 'border', 'rounded-lg'] : ['border-0']),
      ...(this.disabled ? ['cursor-not-allowed'] : []),
    ]
  }

  ngOnInit(): void {
    if (this.autofill) {
      if (Capacitor.getPlatform() !== 'ios') {
        return
      }
      setTimeout(() => {
        this.input.getInputElement().then(input => {
          input.addEventListener('change', (e: Event) => {
            input.value = (e.target as any).value
            this.dispatchChange(input.value)
          })
        })
      }, 300)
    }
  }
}
