import { Injectable } from '@angular/core'
import { IResourceMethodObservable, ResourceAction, ResourceParams, ResourceRequestMethod } from '@ngx-resource/core'
import { ResourceBaseService } from '@services/resource.base.service'
import { environment } from '@environments/environment'
import {
  IBenefitCategory,
  IBenefitSubscriptionDetails,
  IPremiumCheckoutResponse,
} from '@modules/benefit/interfaces/benefit'
import { ETypePaymentMethod } from '@modules/checkout/checkout'

export interface ICategoriesResponseSuccess {
  data: {
    [key: string]: IBenefitCategory
  }
}

@Injectable()
@ResourceParams({
  pathPrefix: 'benefits',
  url: environment.API + '/api/v3/',
})
export class BenefitResource extends ResourceBaseService {
  @ResourceAction({
    method: ResourceRequestMethod.Get,
    pathPrefix: 'employee',
    path: '/subscription_benefits',
  })
  checkSubscription: IResourceMethodObservable<any, any> | any

  @ResourceAction({
    method: ResourceRequestMethod.Get,
    path: '/list',
  })
  categories!: IResourceMethodObservable<any, ICategoriesResponseSuccess>

  @ResourceAction({
    method: ResourceRequestMethod.Get,
    path: '/premium_checkout',
  })
  premiumCheckout!: IResourceMethodObservable<
    {
      payment_type: 'on_demand' | 'card' | string
      year_plan: boolean
      country: null | undefined | string
    },
    IPremiumCheckoutResponse
  >

  @ResourceAction({
    method: ResourceRequestMethod.Post,
    path: '/favorite',
  })
  likeBenefit!: IResourceMethodObservable<
    {
      benefit_id: string
    },
    Record<any, any>
  >

  @ResourceAction({
    method: ResourceRequestMethod.Delete,
    path: '/favorite',
  })
  unlikeBenefit!: IResourceMethodObservable<
    {
      benefit_id: string
    },
    Record<any, any>
  >

  @ResourceAction({
    method: ResourceRequestMethod.Post,
    path: '/subscription',
  })
  subscription!: IResourceMethodObservable<
    {
      year_plan: boolean | undefined
      monto_cash: boolean
    } | null,
    Record<any, any>
  >

  @ResourceAction({
    method: ResourceRequestMethod.Post,
    path: '/subscription_with_card',
  })
  subscriptionWithCard!: IResourceMethodObservable<
    {
      card_id: string
      year_plan: boolean | undefined
      country: string | undefined
    },
    Record<any, any>
  >

  @ResourceAction({
    method: ResourceRequestMethod.Put,
    path: '/subscription',
  })
  subscriptionCancel!: IResourceMethodObservable<null, Record<any, any>>

  @ResourceAction({
    method: ResourceRequestMethod.Get,
    path: '/list_premium',
  })
  premiums!: IResourceMethodObservable<null, Record<any, any>>

  @ResourceAction({
    method: ResourceRequestMethod.Get,
    pathPrefix: '',
    path: '/subscription/details',
  })
  subscriptionDetails!: IResourceMethodObservable<null, IBenefitSubscriptionDetails>
}
