import { Injectable } from '@angular/core'
import { ModalController } from '@ionic/angular'
import { BiometricService } from '@modules/biometric/services/biometric.service'
import { VerifyCredentialsComponent } from '@modules/user/components/auth/verify-credentials/verify-credentials.component'

export interface VerifyCredentialsData {
  verify: boolean
  cancel?: boolean
}

/**
 * Service to verify the credentials
 */
@Injectable({ providedIn: 'root' })
export class VerifyCredentialsService {
  constructor(private _modalController: ModalController, private _biometricService: BiometricService) {}

  /**
   * Login with biometric
   * @returns {Promise<{data: {verify: boolean}}>}
   */
  async loginWithBiometric(): Promise<{
    data: VerifyCredentialsData
  }> {
    const credentials = await this._biometricService.performBiometricVerification({
      reason: 'Verificación de seguridad',
    })
    if (credentials) {
      return Promise.resolve({
        data: {
          verify: true,
        },
      })
    }

    return Promise.resolve({
      data: {
        verify: false,
      },
    })
  }

  /**
   * Check if the user has biometric activated and if it is available, if so, it will open the modal to verify the credentials
   * @returns {Promise<{data: {verify: boolean}}>}
   */
  async checkAuthorization(props: Record<any, any> = {}): Promise<{
    data: VerifyCredentialsData
  }> {
    //check if biometric is available and activated
    if (this._biometricService.isAvailable && this._biometricService.isActivated) {
      const data = await this.loginWithBiometric()
      //if verify is correct, return the data
      if (data.data.verify) {
        return data
      }
    }
    //if verify is incorrect, or cancel the biometric, open the modal to verify the credentials
    return await this.openModalAuthorization(props)
  }

  /**
   * Open the modal to verify the credentials
   * @returns {Promise<{data: {verify: boolean}}>}
   */
  async openModalAuthorization(props: Record<any, any> = {}): Promise<{
    data: VerifyCredentialsData
  }> {
    const modal = await this._modalController.create({
      component: VerifyCredentialsComponent,
      cssClass: 'modal-verify-credentials',
      animated: false,
      componentProps: {
        text: 'Estás a punto de realizar un retiro de efectivo, por seguridad, ingresa tu contraseña para confirmar este movimiento.',
        biometric: true,
        ...props,
      },
    })
    await modal.present()
    const { data } = await modal.onDidDismiss()
    return Promise.resolve({
      data: data,
    })
  }
}
