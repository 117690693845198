import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { IonicModule } from '@ionic/angular'
import { InputTextComponent } from '@atoms/input/input-text/input-text.component'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { AutofillDirective } from '@directives/autofill.directive'
@NgModule({
  imports: [CommonModule, IonicModule, ReactiveFormsModule, FormsModule, AutofillDirective],
  exports: [InputTextComponent],
  declarations: [InputTextComponent],
})
export class InputTextModule {}
