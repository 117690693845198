import { NgModule } from '@angular/core'
import { ConfigPipesModule } from './pipes/config-pipes.module'
import { ConfigPipe } from './pipes/config.pipe'
import { ConfigAdapterService } from './services/config.adapter.service'
import { ConfigNativeService } from './services/config.native.service'
import { ConfigWebService } from './services/config.web.service'

@NgModule({
  imports: [ConfigPipesModule],
  exports: [ConfigPipesModule],
  providers: [ConfigNativeService, ConfigWebService, ConfigAdapterService, ConfigPipe],
})
export class ConfigModule {}
