import { Injectable } from '@angular/core'
import { environment } from '@environments/environment'
import { Capacitor } from '@capacitor/core'
import { Device } from '@capacitor/device'
import * as Sentry from '@sentry/angular'

declare let ClarityPlugin: any

@Injectable({ providedIn: 'root' })
export class ClarityService {
  constructor() {}

  async init(): Promise<void> {
    if (!(await this.checkAvailable())) {
      return
    }
    try {
      ClarityPlugin.initialize(
        environment.clarityKey,
        () => {},
        () => {},
        {
          isIonic: true,
          disableOnLowEndDevices: true,
        }
      )
    } catch (e) {
      Sentry.withScope(function (scope) {
        scope.setLevel('warning')
        Sentry.captureException(e)
      })
    }
  }

  async checkAvailable() {
    if (!Capacitor.isNativePlatform()) {
      return false
    }

    if (Capacitor.getPlatform() !== 'android') {
      return false
    }

    if (Capacitor.getPlatform() === 'android') {
      const info = await Device.getInfo()
      if (info.androidSDKVersion < 29) {
        return false
      }
    }
    return environment.production
  }

  async setCustomUserId(id: string) {
    if (!(await this.checkAvailable())) {
      return
    }
    try {
      ClarityPlugin?.setCustomUserId(
        id,
        () => {},
        () => {}
      )
    } catch (e) {
      Sentry.withScope(function (scope) {
        scope.setLevel('warning')
        Sentry.captureException(e)
      })
    }
  }
}
