import type { OnDestroy, OnInit } from '@angular/core'
import { Component, Input } from '@angular/core'
import { ActivatedRoute, Router } from '@angular/router'
import { SessionService } from '@services/session.service'
import { parseIdentifier, UsersService } from '@services/users.service'
import { Observable, Subject } from 'rxjs'
import { ModalController } from '@ionic/angular'
import { LoginPayload } from '@payloads/login.payload'
import { LoginService } from '@services/login.service'
import { FormBuilder, FormGroup, Validators } from '@angular/forms'
import { IUserState } from '@modules/user/interfaces/user'
import { Store } from '@ngrx/store'
import { finalize, takeUntil } from 'rxjs/operators'
import { HttpErrorResponse } from '@angular/common/http'
import { IConfig } from '@modules/config/interfaces/config'
import { BiometricService } from '@modules/biometric/services/biometric.service'

@Component({
  selector: 'app-verify-credentials',
  templateUrl: 'verify-credentials.component.html',
  styleUrls: ['./verify-credentials.component.scss'],
})
export class VerifyCredentialsComponent implements OnInit, OnDestroy {
  formGroup!: FormGroup

  validating = false

  _unsubscribeAll: Subject<any> = new Subject<any>()

  error: string = ''

  @Input() text: string =
    'Por seguridad, es necesario ingresar tu contraseña para poder activar el sistema biométrico dentro de tu cuenta.'

  @Input() biometric: boolean = false
  config$
  @Input() errorLoginBiometric: boolean = false

  constructor(
    private fb: FormBuilder,
    private _router: Router,
    private _modalController: ModalController,
    private _route: ActivatedRoute,
    private _sessionService: SessionService,
    private _usersService: UsersService,
    private _loginService: LoginService,
    private _store: Store<{ user: IUserState; config: IConfig }>,
    private _biometricService: BiometricService
  ) {
    this.config$ = this._store.select('config')
  }

  public ngOnInit() {
    this.formGroup = this.fb.group({
      userEmail: [null, [Validators.required]],
      userPassword: [null, [Validators.required]],
    })
    this._usersService.getUser().subscribe((response: any) => {
      this.formGroup.patchValue({
        userEmail: parseIdentifier(response?.user?.identifier),
      })
    })
  }

  onDissmiss() {
    this._modalController.dismiss()
  }

  onValidateCredentials() {
    const payload = new LoginPayload(this.formGroup)
    this.validating = true
    this._loginService
      .login(payload.toJSONObject())
      .pipe(
        takeUntil(this._unsubscribeAll),
        finalize(() => (this.validating = false))
      )
      .subscribe(
        response => {
          this._modalController.dismiss({
            verify: true,
            data: payload.toJSONObject().session,
          })
        },
        (error: HttpErrorResponse) => {
          this.formGroup.patchValue({
            userPassword: null,
          })
          const [message] = error.error?.errors
          if (message) {
            this.error = message
          }
        }
      )
  }

  async loginWithBiometric(): Promise<void> {
    if (this._biometricService.isAvailable) {
      this.errorLoginBiometric = false
      const credentials = await this._biometricService.getCredentials({
        reason: 'Verificación de seguridad',
      })
      if (credentials) {
        this._modalController.dismiss({
          verify: true,
        })
      } else {
        this.errorLoginBiometric = true
      }
    }
  }

  public ngOnDestroy() {
    this._unsubscribeAll.next(true)
    this._unsubscribeAll.complete()
  }
}
