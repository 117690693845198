<ion-header *ngIf="!containerUnauthService.header"></ion-header>
<ion-content id="unauth-content" class="relative">
  <div class="grid grid-cols-12 md:flex h-full sm:h-auto overflow-y-hidden inset-0 absolute place-items-stretch">
    <div
      class="sm:col-span-4 md:flex-none w-14 hidden sm:block sidebar relative max-w-[250px] md:max-w-[313px] md:w-[313px]"
      *ngIf="containerUnauthService.leftBar"
    >
      <ion-list lines="none">
        <ion-list-header>
          <div class="p-3">
            <div class="flex items-start">
              <img class="pb-6" src="assets/icon/img_logo-small_white.svg" />
              <img src="../assets/matraz.svg" alt="Dev" title="Dev" *ngIf="!environment.production" class="pl-2 mt-2" />
            </div>
            <h3 class="text-white mb-0 font-semibold">Tu dinero cuando quieras</h3>
          </div>
        </ion-list-header>
      </ion-list>
      <div class="absolute bottom-0 w-full">
        <ion-toolbar class="px-[20px] text-center">
          <a href="https://www.monto.mx" class="text-white text-md" target="_blank">Conocer más acerca de Monto</a>
        </ion-toolbar>
      </div>
    </div>
    <div
      class="sm:col-span-2 md:col-span-2 lg:col-span-2 hidden sm:block bg-white relative"
      *ngIf="!containerUnauthService.leftBar"
    ></div>
    <div class="col-span-12 sm:col-span-8 md:grow sm:h-auto overflow-x-auto relative">
      <div class="flex flex-col inset-0 absolute sm:pt-6 md:pt-30">
        <div class="flex-none" *ngIf="containerUnauthService.header">
          <ion-toolbar
            color="light"
            class="sm:absolute md:w-auto md:top-26 lg:right-26 sm:w-auto sm:top-[44px] sm:right-0"
          >
            <ion-buttons slot="start" class="pl-[14px] sm:hidden flex gap-2 items-end">
              <img class="w-[121px]" src="../../../assets/images/unauth/img_logo-monto.svg" alt="" />
              <img src="../assets/matraz.svg" alt="Dev" title="Dev" *ngIf="!environment.production" />
            </ion-buttons>
            <ion-buttons slot="end">
              <ion-button *ngIf="goBack" (click)="onGoBack()" fill="clear">
                <ion-icon slot="icon-only" name="close-outline" color="dark" size="large"></ion-icon>
              </ion-button>
            </ion-buttons>
          </ion-toolbar>
        </div>
        <div
          class="grow pb-2 p-4 pt-3.5 md:p-6 md:pr-3 md:pt-0 box-border md:max-w-web-area"
          [ngClass]="{ 'md:mx-auto': containerUnauthService.center }"
        >
          <router-outlet name="unauth-content-layout"></router-outlet>
        </div>
      </div>
    </div>
  </div>
</ion-content>
