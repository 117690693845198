import { Injectable } from '@angular/core'
import { environment } from '@environments/environment'
import {
  IResourceMethodObservable,
  ResourceAction,
  ResourceParams,
  ResourceRequestBodyType,
  ResourceRequestMethod,
  ResourceResponseBodyType,
} from '@ngx-resource/core'
import { ResourceBaseService } from '@services/resource.base.service'

@Injectable({
  providedIn: 'root',
})
@ResourceParams({
  pathPrefix: 'credits',
  url: environment.API + '/api/v3/',
})
export class CreditCashResource extends ResourceBaseService {
  @ResourceAction({
    method: ResourceRequestMethod.Get,
    path: '/info',
    responseBodyType: ResourceResponseBodyType.Json,
  })
  info: IResourceMethodObservable<any, any> | any

  @ResourceAction({
    method: ResourceRequestMethod.Post,
    path: '/plans',
    responseBodyType: ResourceResponseBodyType.Json,
  })
  plans: IResourceMethodObservable<any, any> | any

  @ResourceAction({
    method: ResourceRequestMethod.Get,
    path: '/credit_files/show/{!id}',
    responseBodyType: ResourceResponseBodyType.Json,
  })
  getCreditFile: IResourceMethodObservable<any, any> | any

  @ResourceAction({
    method: ResourceRequestMethod.Post,
    path: '/create_file',
    responseBodyType: ResourceResponseBodyType.Json,
  })
  createFile: IResourceMethodObservable<any, any> | any

  @ResourceAction({
    method: ResourceRequestMethod.Get,
    path: '/credit_files/index',
    responseBodyType: ResourceResponseBodyType.Json,
  })
  indexCreditFiles: IResourceMethodObservable<any, any> | any

  @ResourceAction({
    method: ResourceRequestMethod.Post,
    path: '/upload_files',
    requestBodyType: ResourceRequestBodyType.FORM_DATA,
    responseBodyType: ResourceResponseBodyType.Json,
  })
  uploadFile: IResourceMethodObservable<any, any> | any

  @ResourceAction({
    method: ResourceRequestMethod.Post,
    path: '/additional_information',
    responseBodyType: ResourceResponseBodyType.Json,
  })
  additionalInfo: IResourceMethodObservable<any, any> | any

  @ResourceAction({
    method: ResourceRequestMethod.Get,
    pathPrefix: 'transaction_reasons',
    path: '/index',
    responseBodyType: ResourceResponseBodyType.Json,
  })
  transactionReasons: IResourceMethodObservable<any, any> | any
}
