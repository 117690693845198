import { Injectable } from '@angular/core'
import { Actions, createEffect, ofType } from '@ngrx/effects'
import { Store } from '@ngrx/store'
import type { Observable } from 'rxjs'
import { filter, finalize, map, switchMap, tap, throttleTime, withLatestFrom } from 'rxjs/operators'
import {
  montoCashLoadBalance,
  montoCashLoading,
  montoCashSetBalance,
  montoCashWithdrawal,
} from '../actions/monto-cash.actions'
import { IMontoCashBalance, IMontoCashState } from '../interfaces/monto-cash'
import { MontoCashResource } from '../resources/monto-cash.resource'
import { montoCashIsLoadingSelect, montoCashWithdrawalSelect } from '../selectors/monto-cash.selectors'

@Injectable()
export class MontoCashEffects {
  loadBalance$ = createEffect(() =>
    this.actions$.pipe(
      ofType(montoCashLoadBalance),
      withLatestFrom(this._store.select(montoCashIsLoadingSelect)),
      filter(([action, isLoading]) => {
        return !isLoading
      }),
      throttleTime(1000),
      tap(() => {
        this._store.dispatch(montoCashLoading({ loading: true }))
      }),
      switchMap(
        (): Observable<any> =>
          this._montoCashResource.balance().pipe(
            map((balance: IMontoCashBalance) => {
              balance.balance = parseFloat(balance?.balance)
              return montoCashSetBalance(balance)
            }),
            finalize(() => {
              this._store.dispatch(montoCashLoading({ loading: false }))
            })
          )
      )
    )
  )

  withdrawal$ = createEffect(() =>
    this.actions$.pipe(
      ofType(montoCashWithdrawal),
      tap(() => {
        montoCashLoading({ loading: true })
      }),
      withLatestFrom(this._store.select(montoCashWithdrawalSelect)),
      switchMap(
        ([action, withdrawal]): Observable<any> =>
          this._montoCashResource.withdrawal(withdrawal).pipe(
            map(response => {
              return response
            }),
            finalize(() => {
              montoCashLoading({ loading: false })
            })
          )
      )
    )
  )

  constructor(
    private actions$: Actions,
    private _montoCashResource: MontoCashResource,
    private _store: Store<{ montoCash: IMontoCashState }>
  ) {}
}
