import { NgModule } from '@angular/core'
import { NotificationListModule } from './components/notification-list/notification-list.module'
import { NotificationService } from './services/notification.service'
import { NotificationModalComponent } from '@modules/notification/components/notification-modal/notification-modal.component'
import { NotificationFullscreenComponent } from '@modules/notification/components/notification-modal-fullscreen/notification-fullscreen.component'

@NgModule({
  imports: [NotificationListModule, NotificationModalComponent, NotificationFullscreenComponent],
  exports: [NotificationListModule, NotificationModalComponent, NotificationFullscreenComponent],
  providers: [NotificationService],
})
export class NotificationModule {}
