export enum EventType {
  monto_failure = '¡Retiro no exitoso!',
  monto_success = '¡Retiro exitoso!'
}

export enum NotificationType {
  TOAST = 'toast',
  MODAL = 'modal',
  FULLSCREEN = 'fullscreen',
  FULLSCREEN_PUSH = 'fullscreen_push',
  MODAL_PUSH = 'modal_push'
}

export enum NotificationStatus {
  UNREAD = 'unread',
  READ = 'read'
}
