import { createReducer, on } from '@ngrx/store'
import {
  checkoutAddCard,
  checkoutAddImage,
  checkoutClear,
  checkoutClearCard,
  checkoutClearCards,
  checkoutLoading,
  checkoutRemoveCard,
  checkoutRemoveImage,
  checkoutSetCard,
  checkoutSetCards,
  checkoutSetPaymentMethod,
  checkoutSetPaymentMethods,
  checkoutSetUrlBack,
} from '@modules/checkout/state/checkout.actions'
import { ICheckoutState } from '@modules/checkout/checkout.state'

export const initialState: ICheckoutState = <ICheckoutState>{
  loading: true,
  card: null,
  cards: null,
  paymentMethod: null,
  paymentMethods: null,
  urlBack: null,
  image: null,
}
const _configReducer = createReducer(
  initialState,
  //imple ment actions
  on(checkoutLoading, (state, { loading }) => ({
    ...state,
    loading,
  })),
  on(checkoutSetCards, (state, { cards }) => ({
    ...state,
    cards,
  })),
  on(checkoutSetUrlBack, (state, { url }) => ({
    ...state,
    urlBack: url,
  })),
  on(checkoutAddImage, (state, { image }) => ({
    ...state,
    image,
  })),
  on(checkoutRemoveImage, state => ({
    ...state,
    image: null,
  })),
  on(checkoutAddCard, (state, { card }) => {
    if (card?.default) {
      return {
        ...state,
        cards: state.cards ? [...state.cards?.map(c => ({ ...c, default: false })), card] : [card],
      }
    }
    return {
      ...state,
      cards: state.cards ? [...state.cards, card] : [card],
    }
  }),
  on(checkoutRemoveCard, (state, { card }) => ({
    ...state,
    cards: state.cards ? state.cards.filter(c => c.id !== card.id) : null,
  })),
  on(checkoutSetCard, (state, { card }) => ({
    ...state,
    card,
  })),
  on(checkoutClearCards, state => ({
    ...state,
    cards: null,
  })),
  on(checkoutClearCard, state => ({
    ...state,
    card: null,
  })),
  on(checkoutSetPaymentMethods, (state, { paymentMethods }) => ({
    ...state,
    paymentMethods,
  })),
  on(checkoutSetPaymentMethod, (state, { paymentMethod }) => ({
    ...state,
    paymentMethod,
  })),
  on(checkoutClear, state => ({
    ...state,
    card: null,
    paymentMethod: null,
    paymentMethods: null,
    image: null,
    urlBack: null,
    loading: false,
  }))
)

export const checkoutReducer = (state: ICheckoutState, action: any): any => _configReducer(state, action)
