import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { FormsModule } from '@angular/forms'
import { IonicModule } from '@ionic/angular'
import { SharedModule } from '../../../../shared/shared.module'
import { NotificationLoadingModule } from '../notification-loading/notification-loading.module'
import { NotificationCardComponent } from './notification-card.component'
import { SharedPipesModule } from '@pipes/shared-pipes.module'

@NgModule({
  imports: [CommonModule, FormsModule, IonicModule, SharedModule, NotificationLoadingModule, SharedPipesModule],
  exports: [NotificationCardComponent],
  declarations: [NotificationCardComponent],
})
export class NotificationCardModule {}
