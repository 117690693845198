import { inject } from '@angular/core'
import { SessionService } from '@services/session.service'
import { ActivatedRouteSnapshot, CanActivateFn, Router, RouterStateSnapshot } from '@angular/router'

export const canActivateNoSession: CanActivateFn = async (
  route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot
) => {
  const router = inject(Router)
  const sessionService = inject(SessionService)
  const token = await sessionService.getSession()

  if (token) {
    router.navigateByUrl('/u/home')
    return false
  } else {
    return true
  }
}
