import { Injectable } from '@angular/core'
import { AvailableResult, BiometryType, NativeBiometric } from 'capacitor-native-biometric'
import { StorageService } from '@services/storage.service'
import { BiometricOptions, Credentials } from 'capacitor-native-biometric/dist/esm/definitions'
import { Capacitor } from '@capacitor/core'
import { Preferences } from '@capacitor/preferences'
import { NavController } from '@ionic/angular'
import { SessionService } from '@services/session.service'
import { CredentialsService } from '@modules/biometric/services/credentials.service'
import { IBiometricState } from '@modules/biometric/biometric.state'
import { Store } from '@ngrx/store'
import { activeBiometric, availableBiometric, doLoginBiometric } from '@modules/biometric/state/biometric.actions'
import { SplashScreen } from '@capacitor/splash-screen'
import { debounceTime, distinctUntilChanged, take } from 'rxjs/operators'
import { IConfig } from '@modules/config/interfaces/config'
import { Observable } from 'rxjs'
import posthog from 'posthog-js'
import { environment } from '@environments/environment'

@Injectable({
  providedIn: 'root',
})
export class BiometricService {
  login = false

  /**
   * Server to save the credentials (no remote only keychain name
   * @type {string}
   */
  static seed = 'monto.mx'

  /**
   * If biometric is available
   * @type {boolean}
   * @private
   */
  private _isAvailable: boolean = false

  /**
   * If preference is activated
   * @type {boolean}
   * @private
   */
  private _isActivated: boolean = false

  /**
   * Flag to know if biometric is available
   * @type {boolean}
   */
  private _isFlagBiometric: boolean = false

  /**
   * Available biometric type
   * @type {BiometryType}
   */
  availableBiometricType!: BiometryType

  /**
   * Config check flag is on
   * @type {Observable<IConfig>}
   */
  config$!: Observable<IConfig>

  constructor(
    private _navCtrl: NavController,
    private storage: StorageService,
    private _sessionService: SessionService,
    private _credentialService: CredentialsService,
    private _store: Store<{ biometric: IBiometricState; config: IConfig }>
  ) {
    ;(async () => {
      if (Capacitor.getPlatform() === 'web') return
      this.config$ = this._store.select('config')
      this.config$.pipe(debounceTime(500), distinctUntilChanged()).subscribe(async (response: IConfig) => {
        this._isFlagBiometric = !!response.FLAG_BIOMETRIC
        if (this._isFlagBiometric) {
          await this.loadBiometric()
          await this.getAvailableBiometricFace()
        } else {
          this._isAvailable = false
          this._store.dispatch(availableBiometric({ available: this._isAvailable }))
        }
      })
    })()
  }

  get isActivated(): boolean {
    return this._isActivated
  }

  get isAvailable(): boolean {
    return this._isAvailable
  }

  /**
   * Get available biometric
   * @returns {Promise<AvailableResult>}
   */
  private async loadBiometric(): Promise<AvailableResult> {
    const result = await NativeBiometric.isAvailable()
    this._isAvailable = result.isAvailable
    this._store.dispatch(availableBiometric({ available: this._isAvailable }))
    this._isActivated = !!(await Preferences.get({ key: 'biometric' })).value
    this._store.dispatch(activeBiometric({ activated: this._isActivated }))
    return result
  }

  /**
   * Set biometric active
   * @param {boolean} enable
   */
  setBiometricActivate(enable: boolean): void {
    if (enable) {
      Preferences.set({
        key: 'biometric',
        value: 'active',
      }).then()
      this._store.dispatch(activeBiometric({ activated: true }))
      if (environment.posthogKey && environment.production) {
        posthog.people.set({ biometric: true })
      }
    } else {
      Preferences.remove({
        key: 'biometric',
      }).then()
      this._store.dispatch(activeBiometric({ activated: false }))
      if (environment.posthogKey && environment.production) {
        posthog.people.set({ biometric: false })
      }
    }
  }

  /**
   * Get if biometric is activated
   * @returns {Promise<boolean>}
   */
  getIsActivated(): Promise<boolean> {
    return Preferences.get({ key: 'biometric' })
      .then(value => {
        this._isActivated = !!value.value
        if (environment.posthogKey && environment.production) {
          posthog.people.set({ biometric: this._isActivated })
        }
        return this._isActivated
      })
      .catch(() => {
        this._isActivated = false
        if (environment.posthogKey && environment.production) {
          posthog.people.set({ biometric: false })
        }
        return this._isActivated
      })
  }

  /**
   * Get available biometric face
   * @returns {Promise<boolean>}
   */
  async getAvailableBiometricFace() {
    const result = await this.loadBiometric()
    this.availableBiometricType = result.biometryType
  }

  /**
   * Bio metric verification
   * @returns {Promise<void>}
   */
  async performBiometricVerification(options: BiometricOptions = {}) {
    //verify if is available
    if (!this._isAvailable) return undefined
    const timeoutId = setTimeout(() => {
      SplashScreen.show({
        fadeInDuration: 0,
        showDuration: 0,
        autoHide: false,
      })
    }, 500)
    try {
      //verify bio metric
      const verified = await NativeBiometric.verifyIdentity({
        reason: 'Inicio de sesión',
        title: 'Monto Autenticación',
        maxAttempts: 5,
        negativeButtonText: 'Cancelar',
        useFallback: true,
        ...options,
      })
        .then(response => {
          this._store.dispatch(doLoginBiometric({ doLogin: true }))
          clearTimeout(timeoutId)
          SplashScreen.hide({
            fadeOutDuration: 0,
          })
          return true
        })
        .catch(reason => {
          clearTimeout(timeoutId)
          setTimeout(() => {
            SplashScreen.hide({
              fadeOutDuration: 0,
            })
          }, 500)
          return false
        })
      return verified
    } catch (e) {
      clearTimeout(timeoutId)
      setTimeout(() => {
        SplashScreen.hide({
          fadeOutDuration: 0,
        })
      }, 500)
      return false
    }
  }

  /**
   * Check biometric
   */
  checkBiometric(): void {
    if (Capacitor.getPlatform() === 'web') return
    this.config$.pipe(take(1)).subscribe((config: IConfig) => {
      if (!config.FLAG_BIOMETRIC) return
      //check if is native

      //load biometric, and check if is activated
      Promise.all([this.loadBiometric(), this.getIsActivated()])
        .then(values => {
          if (this.isAvailable && this.isActivated) {
            //verify biometric
            this.performBiometricVerification().then(response => {
              if (!response) {
                //remove token
                this.removeToken()
              }
            })
          }
        })
        .catch(error => {
          this.removeToken()
        })
    })
  }

  /**
   * Remove token and redirect to login
   */
  removeToken() {
    this._sessionService.removeToken()
    this._navCtrl.navigateRoot('/unauth/login-session', {
      replaceUrl: true,
      queryParams: { logout: true },
    })
  }

  /**********************************************************************
   * Manage Credentials
   * *******************************************************************
   */

  /**
   * Get credentials
   * @returns {Promise<any>}
   */
  async getCredentials(options: BiometricOptions = {}): Promise<Credentials | null> {
    try {
      const verified = await this.performBiometricVerification()
      let credentials = null
      if (verified) {
        credentials = await NativeBiometric.getCredentials({
          server: BiometricService.seed,
        })
        credentials = this._credentialService.decryptCredentials(credentials)
      }
      return credentials
    } catch (e) {
      this.setBiometricActivate(false)
      return null
    }
  }

  /**
   * Delete credentials
   */
  deleteCredentials(): Promise<any> {
    if (!this._isAvailable) return Promise.reject()
    return NativeBiometric.deleteCredentials({
      server: BiometricService.seed,
    })
      .then(response => {
        return response
      })
      .catch(error => {
        return null
      })
  }

  /**
   * Set credentials
   */
  veryfyCurrentCredentials(credentials: { username: string }): Promise<any> {
    if (!this._isAvailable) return Promise.reject()
    return NativeBiometric.getCredentials({
      server: BiometricService.seed,
    }).then(currentCredencial => {
      currentCredencial = this._credentialService.decryptCredentials(currentCredencial)
      if (currentCredencial && currentCredencial.username !== credentials.username) {
        this.setBiometricActivate(false)
        this.deleteCredentials()
      }
    })
  }

  /**
   * Set credentials
   */
  setCredentails(credentials: Credentials): Promise<any> {
    if (!this._isAvailable) return Promise.reject()
    return this.deleteCredentials()
      .then(() => {
        credentials = this._credentialService.encryptCredentails({
          username: credentials.username,
          password: credentials.password,
        })
        NativeBiometric.setCredentials({
          username: credentials.username,
          password: credentials.password,
          server: BiometricService.seed,
        })
          .then(async response => {
            return response
          })
          .catch(error => {
            return null
          })
      })
      .catch(error => {
        return null
      })
  }
}
