import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, Output } from '@angular/core'
import { INotification, type INotificationState } from '@modules/notification/interfaces/notification'
import { NotificationService } from '../../services/notification.service'
import { NotificationType } from '@modules/notification/enums/notification'
import { Badge } from '@capawesome/capacitor-badge'
import { notificationIndicatorNew } from '@modules/notification/actions/notification.actions'
import { Store } from '@ngrx/store'

@Component({
  selector: 'app-notification-card',
  templateUrl: './notification-card.component.html',
  styleUrls: ['./notification-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NotificationCardComponent {
  @Input() notification!: INotification
  @Input() date!: string
  @Input() swipe = true
  @Output() delete: EventEmitter<any> = new EventEmitter<any>()

  isLoading = true
  status: string | null = null

  NotificationType = NotificationType
  constructor(
    private _notificationService: NotificationService,
    private _crd: ChangeDetectorRef,
    private _store: Store<{ notification: INotificationState }>
  ) {}

  onDetail(): void {
    if (this.notification.notification_type === NotificationType.TOAST || !this.notification.notification_type) {
      this.notification.detail = !this.notification.detail
    } else {
      this._notificationService.createNotification(this.notification)
    }

    this.onRead()
  }

  onDelete(): void {
    this.notification.is_loading = true
    this.delete.emit({
      item: this.notification,
      date: this.date,
    })
    this._crd.detectChanges()
    this._notificationService.delete(this.notification.id).subscribe(
      response => {
        if (response?.data?.unread !== undefined) {
          Badge.isSupported().then(result => {
            if (result.isSupported) {
              Badge.set({ count: response.data.unread || 0 })
            }
          })
          this._store.dispatch(notificationIndicatorNew({ indicator: response.data.unread > 0 }))
        }

        this.delete.emit({
          item: this.notification,
          date: this.date,
        })
        this.notification.is_loading = false
        this._crd.detectChanges()
      },
      () => {
        this.notification.is_loading = false
        this._crd.detectChanges()
      }
    )
  }

  onRead(): void {
    if (this.notification.view_at) {
      return
    }
    this.notification.view_at = '.'
    this._notificationService.read(this.notification.id).subscribe(response => {
      if (response?.data?.unread !== undefined) {
        Badge.isSupported().then(result => {
          if (result.isSupported) {
            Badge.set({ count: response.data.unread || 0 })
          }
        })
        this._store.dispatch(notificationIndicatorNew({ indicator: response.data.unread > 0 }))
      }
      this.status = response?.data?.status
      if (response) {
        this._crd.detectChanges()
      }
    })
  }

  get bodyLength(): number | undefined {
    return this.notification.body?.replace(/(<([^>]+)>)/gi, '')?.length
  }
}
