import { inject } from '@angular/core'
import { ActivatedRouteSnapshot, CanActivateFn, RouterStateSnapshot } from '@angular/router'
import { NavController } from '@ionic/angular'
import { GlobalTutorialService } from '@modules/guide/services/global-tutorial.service'
import { ETutorialType } from '@modules/guide/tutorial/interfaces/tutorial.interface'
import { UpdateAppService } from '@services/update-app.service'

export const canActivateTutorial: CanActivateFn = async (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
  const tutorialService = inject(GlobalTutorialService)
  const updateAppService = inject(UpdateAppService)
  const navController = inject(NavController)

  const type: ETutorialType | undefined = route.data['tutorial']

  if (!type) {
    return true
  }

  const tutorial = await tutorialService.getTutorial(type)

  if (tutorial) {
    await navController.navigateForward(tutorial.route, { queryParams: { backTo: state.url } }).catch(() => {
      updateAppService?.updateOpen()
    })
    return false
  }
  return true
}
