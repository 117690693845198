<ion-card
  class="ion-margin-vertical sm:w-[424px] rounded-lg border-[3px] border-solid"
  (click)="onRead()"
  [ngClass]="{ 'border-solid border-primary': notification.status === 'unread' && status !== 'read' }"
  *ngIf="!notification.delete_at && !notification.is_loading"
>
  <ion-item-sliding>
    <ion-item lines="none" tappable>
      <ion-label class="ion-padding ion-no-margin">
        <div class="pb-[4px] flex justify-between">
          <ion-text
            slot="end"
            *ngIf="notification.title"
            color="dark"
            class="line-clamp-3 break-words whitespace-pre-wrap font-semibold tracking-widest"
          >
            <div [innerHTML]="notification.title_clean | sanitizeHtml: 'html'"></div>
          </ion-text>
          <small class="tracking-widest" data-test-id="date">{{ notification.created_at | date: 'h:mm a' }}</small>
        </div>
        <div *ngIf="notification?.body_clean">
          <ion-text
            slot="end"
            color="dark"
            class="text-ellipsis break-words whitespace-pre-wrap tracking-widest"
            [ngClass]="{ 'line-clamp-3 ': !notification.detail }"
          >
            <div [innerHTML]="notification?.body_clean | readMore: 70:notification.detail | sanitizeHtml: 'html'"></div>
          </ion-text>
        </div>
        <div class="pt-2 flex justify-end gap-4 items-center">
          <ion-text color="primary" expand="block" class="hidden md:flex items-center">
            <strong
              class="flex font-semibold tracking-widest"
              data-test-id="action-delete"
              (click)="onDelete()"
              tappable=""
            >
              <label class="sm:text-xs text-base tracking-widest">Eliminar</label>
            </strong>
          </ion-text>
          <ion-text color="primary" expand="block" *ngIf="notification?.body">
            <div
              class="flex items-center justify-end font-semibold tracking-widest"
              data-test-id="action-view-more"
              tappable
              (click)="onDetail()"
              *ngIf="
                bodyLength > 70 ||
                [
                  NotificationType.MODAL_PUSH,
                  NotificationType.MODAL,
                  NotificationType.FULLSCREEN_PUSH,
                  NotificationType.FULLSCREEN
                ].includes($any(notification.notification_type))
              "
            >
              <label class="sm:text-xs text-base tracking-widest" data-test-id="text-view-more">{{
                notification.detail ? 'Ver menos' : 'Ver más'
              }}</label>
              <ion-icon
                data-test-id="icon-view-more"
                color="medium"
                slot="end"
                size="small"
                [name]="notification.detail ? 'chevron-up-outline' : 'chevron-down-outline'"
              ></ion-icon>
            </div>
          </ion-text>
        </div>
      </ion-label>
    </ion-item>
    <ion-item-options *ngIf="swipe" class="md:hidden" side="end">
      <ion-item-option color="danger px-2" (click)="onDelete()" data-test-id="action-delete">
        <div class="flex flex-col">
          <ion-icon name="trash-outline" class="ion-align-self-center" size="large"></ion-icon>
          <label class="sm:text-xs text-base tracking-widest">Eliminar</label>
        </div>
      </ion-item-option>
    </ion-item-options>
  </ion-item-sliding>
</ion-card>
