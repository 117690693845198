import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http'

@Injectable({ providedIn: 'root' })
export class ClientTypeInterceptor implements HttpInterceptor {
  intercept(req: HttpRequest<string>, next: HttpHandler): Observable<HttpEvent<string>> {
    if (req.params.get('skipInterceptor')) {
      return next.handle(req)
    }
    const clientTypeHeaders = {
      headers: !req.headers.get('X-Client') ? req.headers.set('X-Client', 'app') : req.headers,
    }
    const request = req.clone(clientTypeHeaders)

    return next.handle(request)
  }
}
