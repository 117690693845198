<ng-container *ngIf="!isModal">
  <ion-toolbar
    [color]="color"
    class="main-toolbar ion-no-border"
    [ngClass]="{
      'with-menu': menu,
    }"
  >
    <div class="flex">
      <ion-title slot="start">
        @if (templateHeaderExtra) {
          <ng-container *ngTemplateOutlet="templateHeaderExtra"></ng-container>
        }
        <ion-text [color]="color === 'primary' ? 'light' : 'dark'">
          <ng-container *ngTemplateOutlet="templateHeader"></ng-container>
        </ion-text>
      </ion-title>
      <div
        slot="end"
        class="flex gap-3 md:items-center"
        [ngClass]="{
          'items-center': menu,
          'items-start': !menu,
        }"
      >
        <ng-container *ngTemplateOutlet="templateExtraButtons"></ng-container>
        <ng-container *ngIf="siteTreeHeader$ | async as siteHeader">
          <div
            class="relative"
            *ngIf="notifications && siteHeader.notifications"
            [ngClass]="{
              'hidden md:block': !menu,
              block: menu,
            }"
          >
            <ion-button fill="clear" (click)="openNotifications()">
              <monto-icon
                class="block md:hidden"
                [name]="'bell'"
                [color]="color === 'light' ? 'dark' : 'light'"
              ></monto-icon>
              <monto-icon class="hidden md:block" [name]="'bell'" [color]="'primary'"></monto-icon>
            </ion-button>
            <span
              class="absolute w-1 h-1 right-0 top-0 rounded-full bg-danger"
              *ngIf="indicatorNewNotification$ | async"
            >
            </span>
          </div>
          <ion-button
            *ngIf="siteHeader.guide"
            fill="clear"
            routerLink="/u/guide"
            [ngClass]="{
              'hidden md:block': !menu,
              block: menu,
            }"
          >
            <monto-icon
              class="block md:hidden"
              [color]="color === 'light' ? 'dark' : 'light'"
              [name]="'bulb'"
            ></monto-icon>
            <monto-icon class="hidden md:block" [color]="'primary'" [name]="'bulb'"></monto-icon>
          </ion-button>
          <ion-button class="block md:hidden" fill="clear" (click)="openMenu()" *ngIf="menu && siteHeader.menu">
            <monto-icon
              class="block md:hidden"
              [color]="color === 'light' ? 'dark' : 'light'"
              [name]="'menu'"
            ></monto-icon>
            <monto-icon class="hidden md:block" [color]="'primary'" [name]="'menu'"></monto-icon>
          </ion-button>
        </ng-container>
        <ion-button
          (click)="onClose()"
          fill="clear"
          class="block md:hidden"
          *ngIf="!menu && closable"
          [disabled]="disableClose"
        >
          <monto-icon [color]="color === 'light' ? 'dark' : 'light'" slot="icon-only" name="close"> </monto-icon>
        </ion-button>
      </div>
    </div>
  </ion-toolbar>
  <ng-content></ng-content>
</ng-container>
