export enum ICheckoutCardStatus {
  PENDING_VALIDATION = 'pending_validation',
  ACTIVE = 'active',
  CANCEL = 'cancel',
  CREATED = 'created',
}

export interface ICheckoutCard {
  id: string
  brand: string
  last4: string
  default: boolean
  token: string
  country: string
  status: ICheckoutCardStatus
  card_id: string
  exp_month: number
  exp_year: number
  funding: string
  validated: boolean
  validation_date: string | Date
}

export enum ETypePaymentMethod {
  SALARY = 'salary',
  GOOGLE_PLAY = 'google_play',
  OTHER = 'other',
  CARD = 'card',
  SALARY_VARIABLE = 'salary-variable',
  SALARY_FUTURE = 'salary-future',
}

export interface ICheckoutPaymentMethod {
  id?: ETypePaymentMethod | string
  image?: string
  name?: string
  description?: string
  amount_available?: number
  salary_future?: boolean
  type: ETypePaymentMethod
  default?: boolean
  disabled?: boolean
  country?: string
  status?: ICheckoutCardStatus
  validated?: boolean
  visible?: boolean
}
