import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http'

@Injectable({ providedIn: 'root' })
export class ApiVersionInterceptor implements HttpInterceptor {
  intercept(req: HttpRequest<string>, next: HttpHandler): Observable<HttpEvent<string>> {
    if (req.params.get('skipInterceptor')) {
      return next.handle(req)
    }
    const apiVersionHeaders = {
      headers: req.headers.append('Accept', 'application/vnd.mto.v1+json'),
    }
    const request = req.clone(apiVersionHeaders)

    return next.handle(request)
  }
}
