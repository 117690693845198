import type { Routes } from '@angular/router'
import { environment } from '@environments/environment'
import { ContainerBaseComponent } from './containers/container-base/container-base.component'
import { ContainerUnauthComponent } from './containers/container-unauth/container-unauth.component'
import { canActivateAuth } from '@guard/auth-guard.guard'
import { canActivateNoSession } from '@guard/no-session-guard.guard'
import { applicationROUTES } from './shared/routes/app-layout.routes'
import { unauthROUTES } from './shared/routes/unauth-layout.routes'

export const routes: Routes = [
  {
    path: '',
    redirectTo: 'unauth',
    pathMatch: 'full',
  },
  {
    path: 'unauth',
    component: ContainerUnauthComponent,
    children: unauthROUTES,
    data: { title: 'Monto', tokenName: environment.COOKIE.APPLICATION, layout: 'unauth' },
  },
  {
    path: 'web',
    component: ContainerUnauthComponent,
    children: unauthROUTES,
    data: { title: 'Monto', tokenName: environment.COOKIE.APPLICATION, layout: 'unauth' },
  },
  {
    path: '',
    component: ContainerUnauthComponent,
    children: [
      {
        path: 'signup',
        canActivate: [canActivateNoSession],
        loadChildren: () =>
          import('../app/pages/unauth/create-account/create-account.module').then(m => m.CreateAccountPageModule),
      },
    ],
    data: { title: 'Monto', tokenName: environment.COOKIE.APPLICATION, layout: 'unauth' },
  },
  {
    path: 'u',
    component: ContainerBaseComponent,
    children: applicationROUTES,
    data: { title: 'Monto', tokenName: environment.COOKIE.APPLICATION, layout: 'base' },
    canActivate: [canActivateAuth],
  },
  {
    path: 'loader',
    component: ContainerBaseComponent,
    loadChildren: () =>
      import('./modules/loader/pages/loader-routing.module').then(m => m.NotificationPageRoutingModule),
  },
  {
    path: 'validate-code',
    loadChildren: () => import('./pages/unauth/validate-code/validate-code.module').then(m => m.ValidateCodePageModule),
  },
  {
    path: '**',
    redirectTo: 'unauth',
    pathMatch: 'full',
  },
]
