import { Injectable } from '@angular/core'
import { MenuController } from '@ionic/angular'

@Injectable({
  providedIn: 'root',
})
export class SplitPaneService {
  isVisible = false
  isVisibleInfo = false

  constructor(private menu: MenuController) {}

  setPaneStatus(status: boolean): void {
    this.menu.enable(status, 'main')
    this.isVisible = status
  }

  hidePane(): void {
    this.menu.close('main')
  }

  getPaneStatus(): boolean {
    return this.isVisible
  }

  /**
   *
   * @param status
   * @deprecated
   */
  setPaneStatusInfo(status: boolean): void {
    this.menu.enable(status, 'info')
    this.isVisibleInfo = status
  }

  /**
   *
   * @param status
   * @deprecated
   */
  getPaneStatusInfo(): boolean {
    return this.isVisibleInfo
  }
}
