import { Injectable } from '@angular/core'
import { Storage } from '@ionic/storage-angular'

@Injectable({ providedIn: 'root' })
export class StorageService {
  private _storage: Storage | null = null

  constructor(private storage: Storage) {
    this.init()
  }

  async init(): Promise<any> {
    if (this._storage) {
      return
    }
    const storage = await this.storage.create()
    this._storage = storage
    return Promise.resolve(storage)
  }

  async set(key: string, value: any): Promise<any> {
    await this.init()
    return this._storage?.set(key, value)
  }

  async get(key: string): Promise<any> {
    await this.init()
    return this._storage?.get(key)
  }

  async remove(key: string): Promise<any> {
    await this.init()
    return this._storage?.remove(key)
  }
}
