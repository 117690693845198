import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { IonicModule } from '@ionic/angular'
import { InputComponent } from '@components/input/input.component'
import { ReactiveFormsModule } from '@angular/forms'

@NgModule({
  imports: [CommonModule, IonicModule, ReactiveFormsModule],
  exports: [InputComponent],
  declarations: [InputComponent],
})
export class InputModule {}
