import { inject } from '@angular/core'
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router'
import { servicesAvailableSelector } from '@modules/user/selectors/user.selectors'
import { Store } from '@ngrx/store'
import { map, take } from 'rxjs/operators'
import { lastValueFrom } from 'rxjs'
import { ServiceAvailableService } from 'src/app/pages/application/tabs/home/modal/intermittence-app-modal/service-available.service'

export const canIntermittence = async (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
  const _store = inject(Store)
  const services$ = _store.select(servicesAvailableSelector)
  const intermittenceService = inject(ServiceAvailableService)
  const _router = inject(Router)
  const service = route.data['service']
  return lastValueFrom(
    services$.pipe(
      take(1),
      map(async intermittence => {
        const showModals = intermittenceService.validateService(service, intermittence)
        if (showModals) {
          intermittenceService.showIntermittenceModal()
          _router.navigate(['/u/home'], {
            replaceUrl: true,
          })
          return false
        }
        return true
      })
    )
  )
}
