import { IVerifyInfo } from './../interfaces/user'
import { createAction, props } from '@ngrx/store'
import { UserModel } from '@modules/user/models/user.model'
import { ProfileModel } from '@modules/user/models/profile.model'
import { ETutorialType } from '@modules/guide/tutorial/interfaces/tutorial.interface'

export const userSession = createAction('[User] User Session', props<{ user: UserModel }>())
export const updateUserSession = createAction('[User] Update User Session', props<{ user: Partial<UserModel> }>())
export const updateProfileSession = createAction(
  '[User] Update User Session',
  props<{ profile: Partial<ProfileModel> }>()
)
/**
 * @deprecated
 * @type {ActionCreator<"[User] User Session Profile", (props: ({user: any} & NotAllowedCheck<{user: any}>)) => ({user: any} & TypedAction<"[User] User Session Profile">)>}
 */
export const userSessionProfile = createAction('[User] User Session Profile', props<{ user: any }>())
export const userPutBalance = createAction(
  '[User] User Set balance',
  props<{ balance: number | null | undefined; balanceFuture: number | null | undefined }>()
)
export const userLoading = createAction('[User] Set Loading', props<{ loading: boolean }>())
export const putIsFirstTimeLogin = createAction('[User] Set First Time Login', props<{ isFirstTimeLogin: boolean }>())
export const removeSession = createAction('[User] Session Remove')
export const deleteAccount = createAction('[User] Delete Account')
export const userLoadRemote = createAction('[User] User Session Loaded')
export const profileLoadRemote = createAction('[User] Profile Session Loaded')
export const userVerifyInfoUpdate = createAction('[User] User Verify Info Update', props<{ verifyInfo: IVerifyInfo }>())
export const userVerifyInfoClean = createAction('[User] User Verify Info Clean')

export const tutorialFinishedUpdate = createAction(
  '[User] User Tutorial Info Update',
  props<{ key: ETutorialType; value: boolean }>()
)
