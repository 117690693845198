import { createAction, props } from '@ngrx/store'
import type { IWithdrawal, IWithdrawalSearchParams } from '../interfaces/withdrawal'
import { IWithdrawalNew } from '../interfaces/withdrawal'

export const withdrawalsLoad = createAction('[Withdrawals List] Load Withdrawals via Service')
export const withdrawalsLoaded = createAction(
  '[Withdrawals List] Set list Withdrawals',
  props<{
    entities: null | IWithdrawal[]
    searchParams?: IWithdrawalSearchParams
  }>()
)
export const withdrawalsOriginLoaded = createAction(
  '[Withdrawals List] Set list Withdrawals Orgin',
  props<{
    origin: any
  }>()
)

export const goToWithdrawalCreate = createAction('[Withdrawal] Create withdrawal')
export const withdrawalAllow = createAction('[Withdrawal] If user allow withdrawal', props<{ allow: boolean }>())
export const withdrawalSetCycle = createAction('[Withdrawal] set period', props<{ cycle: any }>())
export const withdrawalClearCycle = createAction('[Withdrawal] set period')
export const withdrawalsClear = createAction('[Withdrawals List] Clear list')
export const withdrawalsLoading = createAction('[Withdrawals List] Set Loading', props<{ loading: boolean }>())
export const setSearchParams = createAction(
  '[Withdrawals] Set Search Params',
  props<{ searchParams: IWithdrawalSearchParams }>()
)
export const clearSearchParams = createAction('[Withdrawals] Clear Search Params')

//create withdrawal.actions.ts
export const setWithdrawalNew = createAction(
  '[Withdrawal New] Set data for create withdrawal',
  props<{ withdrawal: Partial<IWithdrawalNew> }>()
)
export const clearWithdrawalNew = createAction('[Withdrawal New] Clear data for create withdrawal')
