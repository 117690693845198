import type { IPayment, IPaymentState } from '../interfaces/payment'
import { createReducer, on } from '@ngrx/store'
import { clearPaymentNew, paymentsLoading, setPaymentNew } from '../actions/payment.actions'

import type { EntityAdapter } from '@ngrx/entity'
import { createEntityAdapter } from '@ngrx/entity'

export const adapter: EntityAdapter<IPayment> = createEntityAdapter<IPayment>()

export const initialState: IPaymentState = adapter.getInitialState({
  loading: true,
  entity: null,
  cycle: null,
  searchParams: null,
  entities: null,
  origin: null,
  paymentAllow: false,
  entityNew: null,
})

const _paymentReducer = createReducer(
  initialState,
  on(paymentsLoading, (state, { loading }) => ({
    ...state,
    loading,
  })),
  on(setPaymentNew, (state, { payment }) => ({
    ...state,
    entityNew: {
      ...state.entityNew,
      ...payment,
    },
  })),
  on(clearPaymentNew, state => ({
    ...state,
    entityNew: null,
  }))
)

export const paymentReducer = (state: any, action: any): any => _paymentReducer(state, action)
export const { selectAll, selectIds, selectEntities } = adapter.getSelectors()
