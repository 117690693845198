import { Injectable } from '@angular/core'
import * as CONFIG from '../../../../config.json'
import { EConfig } from '../interfaces/config'
import type { OnConfig } from '../interfaces/config'
import { ConfigService } from './config.service'

@Injectable({ providedIn: 'root' })
export class ConfigNativeService extends ConfigService implements OnConfig {
  /**
   * Fetch data remote
   */
  override fetch(): void {
    /* this.firebaseConfig
      .fetchAndActivate()
      .then(() => {
        this.firebaseConfig.fetch(this.remoteConfig.settings.minimumFetchIntervalMillis / 1000).then(
          async () => {
            // set state
            this.setConfig(await this.parse())
          },
          () => {}
        )
      })
      .catch(() => {
        this.defaultRemote()
      }) */
    this.defaultRemote()
  }

  /**
   * Parse data for native
   */
  override async parse(): Promise<any> {
    /* const config: Record<string, any> = {}
    let val
    for (const key of Object.keys(CONFIG)) {
      if (key in EConfig) {
        if (EConfig[key] === 'boolean') {
          val = await this.firebaseConfig.getBoolean(key)
        } else if (EConfig[key] === 'number') {
          val = await this.firebaseConfig.getNumber(key)
        } else if (EConfig[key] === 'string') {
          val = await this.firebaseConfig.getString(key)
        } else if (EConfig[key] === 'object') {
          val = JSON.parse(await this.firebaseConfig.getString(key))
        } else {
          val = await this.firebaseConfig.getString(key)
        }
        config[key] = val
      } else {
        console.warn(`${key} no defined in src/config.json`)
      }
    }
    return config */
  }
}
