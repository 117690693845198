import { HttpClient, HttpHeaders } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { environment } from '@environments/environment'
import { Observable } from 'rxjs'
import { map } from 'rxjs/operators'
import { IUser } from '@modules/user/interfaces/user'

@Injectable({ providedIn: 'root' })
export class UsersService {
  constructor(private httpClient: HttpClient) {
    /** Do nothing */
  }

  getUser(): Observable<IUser> {
    const headers = new HttpHeaders().set('Accept', 'application/vnd.mto.v2+json').set('X-Client', 'app')

    return this.httpClient.get(`${environment.API}/api/v2/users`, { headers }).pipe(map(response => response as IUser))
  }
}

export const parseIdentifier = (identifier: string): string => {
  if (identifier?.startsWith('+52')) {
    identifier = identifier.replace('+52', '')
  }

  return identifier
}
