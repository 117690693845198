import type { Routes } from '@angular/router'
import { canActivateNoSession } from '@guard/no-session-guard.guard'

export const unauthROUTES: Routes = [
  {
    path: '',
    redirectTo: 'login',
    pathMatch: 'full',
  },
  {
    path: 'login',
    canActivate: [canActivateNoSession],
    loadChildren: () =>
      import('../../pages/unauth/login-session/login-session.module').then(m => m.LoginSessionPageModule),
  },
  {
    path: 'forgot-password',
    loadChildren: () =>
      import('../../pages/unauth/forgot-password/forgot-password.module').then(m => m.ForgotPasswordPageModule),
  },
  {
    path: 'delete-account',
    loadChildren: () =>
      import('@modules/user/pages/delete-user-info/delete-user-info.module').then(m => m.DeleteUserInfoPageModule),
  },
  {
    path: 'email-sent',
    loadChildren: () => import('../../pages/unauth/email-sent/email-sent.module').then(m => m.EmailSentPageModule),
  },
  {
    path: 'reset-password',
    loadChildren: () =>
      import('../../pages/unauth/reset-password/reset-password.module').then(m => m.ResetPasswordPageModule),
  },
  {
    path: 'changed-password',
    loadChildren: () =>
      import('../../pages/unauth/changed-password/changed-password.module').then(m => m.ChangedPasswordPageModule),
  },
  {
    path: 'recommend-company',
    loadChildren: () => import('@modules/recommend/recommend.module').then(m => m.RecommendPageModule),
  },
  {
    path: 'recommend-company-inside',
    loadChildren: () => import('@modules/recommend/recommend.module').then(m => m.RecommendPageModule),
  },
  {
    path: 'recommend-success',
    loadChildren: () =>
      import('@modules/recommend/recommedation-success/recommendation-success.module').then(
        m => m.RecommendationSuccessPageModule
      ),
  },
  {
    path: 'login-session',
    canActivate: [canActivateNoSession],
    loadChildren: () =>
      import('../../pages/unauth/login-session/login-session.module').then(m => m.LoginSessionPageModule),
  },
  {
    path: 'create-account',
    canActivate: [canActivateNoSession],
    loadChildren: () =>
      import('../../pages/unauth/create-account/create-account.module').then(m => m.CreateAccountPageModule),
  },
  {
    path: 'create-account/:montoId',
    canActivate: [canActivateNoSession],
    loadChildren: () =>
      import('../../pages/unauth/create-account/create-account.module').then(m => m.CreateAccountPageModule),
  },
  {
    path: 'email-content',
    loadChildren: () =>
      import('@modules/recommend/email-content/email-content.module').then(m => m.EmailContentPageModule),
  },
  {
    path: 'email-content-inside',
    loadChildren: () =>
      import('@modules/recommend/email-content/email-content.module').then(m => m.EmailContentPageModule),
  },
  {
    path: 'update-password',
    loadChildren: (): Promise<any> =>
      import('../../modules/user/components/auth/reset-password/reset-password-routing.module').then(
        m => m.ResetPasswordRoutingModule
      ),
  },
  {
    path: 'validate-code',
    loadChildren: (): Promise<any> =>
      import('../../pages/unauth/validate-code/validate-code.module').then(m => m.ValidateCodePageModule),
  },
  {
    path: 'token',
    loadChildren: (): Promise<any> =>
      import('./../../modules/user/components/auth/check-token/check-token-routing.module').then(
        m => m.CheckTokenRoutingModule
      ),
  },
  {
    path: 'token/:token',
    loadChildren: (): Promise<any> =>
      import('./../../modules/user/components/auth/check-token/check-token-routing.module').then(
        m => m.CheckTokenRoutingModule
      ),
  },
]
