import { ChangeDetectionStrategy, ChangeDetectorRef, Component } from '@angular/core'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { Router, RouterModule } from '@angular/router'
import { IonicModule, ModalController } from '@ionic/angular'
import { CommonModule } from '@angular/common'
import { ContentWebModule } from '@components/content-web/content-web.module'
import { ToolbarModule } from '@components/toolbar/toolbar.module'
import { ConfigPipesModule } from '@modules/config/pipes/config-pipes.module'
import { LetDirective } from '@ngrx/component'
import { environment } from '@environments/environment'
import { IconModule } from '@atoms/icon/icon.module'
import { RedeemResource } from '@modules/redeem/resources/redeem.resource'
import { RedeemSuccessComponent } from '@modules/redeem/components/success/redeem-success.component'
import { RedeemAction } from '@modules/redeem/redeem'
import { InputTextModule } from '@atoms/input/input-text/input-text.module'
import { finalize } from 'rxjs/operators'
import { userLoadRemote } from '@modules/user/actions/user.actions'
import { Store } from '@ngrx/store'
import { IUserState } from '@modules/user/interfaces/user'
import { IErrorResponse } from '@declarations/error-response'
import { clearBenefitSubscriptionDetails } from '@modules/benefit/actions/benefit.actions'
import { BenefitService } from '@modules/benefit/services/benefit.service'

@Component({
  selector: 'app-redeem-request-code',
  templateUrl: './redeem-request-code.component.html',
  styleUrls: ['./redeem-request-code.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    IonicModule,
    ContentWebModule,
    ToolbarModule,
    ConfigPipesModule,
    RouterModule,
    LetDirective,
    IconModule,
    InputTextModule,
  ],
  providers: [RedeemResource, BenefitService],
})
export class RedeemRequestCodeComponent {
  environment = environment
  code: string = ''
  error = false
  action: RedeemAction = {
    text: '',
    button: '',
    url: '',
  }
  isLoading = false
  errorText = 'El código ingresado no es válido, inténtalo de nuevo.'

  constructor(
    protected _modalController: ModalController,
    protected _router: Router,
    protected _redeemResource: RedeemResource,
    protected _cdr: ChangeDetectorRef,
    protected _benefitService: BenefitService,
    private _store: Store<{
      user: IUserState
    }>
  ) {}

  onRedirect(): void {
    this.onDismiss()
    this._router.navigate(['/u/locate-your-company'])
  }

  onDismiss(): void {
    try {
      this._modalController.dismiss({
        dismissed: true,
      })
    } catch (e) {}
  }

  onRedeem(): void {
    this.isLoading = true

    this._redeemResource
      .create({
        code: this.code,
      })
      .pipe(
        finalize(() => {
          this.isLoading = false
          this._cdr.detectChanges()
        })
      )
      .subscribe({
        next: (response: RedeemAction) => {
          this.error = false
          this.onDismiss()
          this.showModalSuccess(response)
          this._cdr.detectChanges()
          this._store.dispatch(userLoadRemote())
          this._store.dispatch(clearBenefitSubscriptionDetails())
          this._benefitService.getRemoteBenefitByCategory(null).subscribe()
        },
        error: (error: IErrorResponse) => {
          this.error = true
          this.errorText = error?.body?.error || 'El código ingresado no es válido, inténtalo de nuevo.'
          this._cdr.detectChanges()
        },
      })
  }

  async showModalSuccess(redeemAction: RedeemAction) {
    const modal = await this._modalController.create({
      component: RedeemSuccessComponent,
      cssClass: 'modal-dialog',
      componentProps: {
        redeemAction,
      },
    })

    return await modal.present()
  }
}
