import {
  creditCashLoading,
  creditCashAllow,
  creditCashClear,
  setCreditLoan,
  updateCreditLoan,
  setCreditInfo,
} from '@modules/credit-cash/actions/credit-cash.actions'
import type { ICreditCashState, ICreditLoan } from '@modules/credit-cash/interfaces/credit-cash'
import { createReducer, on } from '@ngrx/store'

import type { EntityAdapter } from '@ngrx/entity'
import { createEntityAdapter } from '@ngrx/entity'

export const adapter: EntityAdapter<ICreditLoan> = createEntityAdapter<ICreditLoan>()

export const initialState: ICreditCashState = adapter.getInitialState({
  entity: null,
  loading: true,
  creditAllow: false,
  info: null,
})

const _creditCashReducer = createReducer(
  initialState,
  on(creditCashLoading, (state, { loading }) => ({
    ...state,
    loading,
  })),
  on(setCreditLoan, (state, { creditLoan }) => ({
    ...state,
    entity: creditLoan,
  })),
  on(setCreditInfo, (state, { creditInfo }) => ({
    ...state,
    info: creditInfo,
  })),
  on(updateCreditLoan, (state, { creditLoan }) => ({
    ...state,
    entity: { ...state.entity, ...(creditLoan as ICreditLoan) },
  })),
  on(creditCashAllow, (state, { allow }) => ({
    ...state,
    creditAllow: allow,
  })),
  on(creditCashClear, state => ({
    ...state,
    entity: null,
    info: null,
  }))
)

export const creditCashReducer = (state: any, action: any): any => _creditCashReducer(state, action)
export const { selectAll, selectIds, selectEntities } = adapter.getSelectors()
