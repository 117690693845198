import { Injectable, Injector } from '@angular/core'
import { ConfigWebService } from './config.web.service'

@Injectable({ providedIn: 'root' })
export class ConfigAdapterService {
  configService

  constructor(private injector: Injector) {
    this.configService = this.injector.get(ConfigWebService)
  }

  /**
   *Init
   */
  init(): void {
    this.configService.init()
  }
}
