import { Component, EventEmitter, Input, Output, OnInit } from '@angular/core'
import { BiometricService } from '@modules/biometric/services/biometric.service'
import { Observable } from 'rxjs'
import { selectBiometricIsAvailableAndActivated } from '@modules/biometric/state/biometric.selectors'
import { Store } from '@ngrx/store'
import { IBiometricState } from '@modules/biometric/biometric.state'
import { BiometryType } from 'capacitor-native-biometric'
import { Capacitor } from '@capacitor/core'

@Component({
  selector: 'app-biometric-login',
  templateUrl: './biometric-login.component.html',
  styleUrls: ['./biometric-login.component.scss'],
})
export class BiometricLoginComponent implements OnInit {
  @Input() error: boolean = true
  @Input() confirm: boolean = false
  @Output() loginBiometric: EventEmitter<boolean> = new EventEmitter<boolean>()
  biometricIsAvailableAndActivated$: Observable<boolean>
  platform: string = ''
  BiometryType = BiometryType

  constructor(public biometricService: BiometricService, protected _store: Store<{ biometric: IBiometricState }>) {
    this.biometricIsAvailableAndActivated$ = this._store.select(selectBiometricIsAvailableAndActivated)
  }

  ngOnInit() {
    this.platform = Capacitor.getPlatform()
  }
}
