import { inject, Injectable } from '@angular/core'
import { Store } from '@ngrx/store'
import { lastValueFrom } from 'rxjs'
import { map, take } from 'rxjs/operators'
import { IntermittenceAppModalComponent } from 'src/app/pages/application/tabs/home/modal/intermittence-app-modal/intermittence-app-modal.component'
import { servicesAvailableSelector } from '@modules/user/selectors/user.selectors'
import { ModalController } from '@ionic/angular'
import { EServicesAvailableStatus, IServicesAvailableStatus } from '@modules/user/models/services'

@Injectable({
  providedIn: 'root',
})
export class ServiceAvailableService {
  public _store = inject(Store)
  services$ = this._store.select(servicesAvailableSelector)
  modalController = inject(ModalController)

  async checkServicesAvailable(
    service: keyof IServicesAvailableStatus | keyof IServicesAvailableStatus[]
  ): Promise<boolean> {
    return await lastValueFrom(
      this.services$.pipe(
        take(1),
        map(async intermittence => {
          const showModals = this.validateService(service, intermittence)
          if (showModals) {
            this.showIntermittenceModal()
            return false
          }
          return true
        })
      )
    )
  }

  async showIntermittenceModal() {
    const prevModal = await this.modalController.getTop()
    if (prevModal?.id !== 'modal-intermittence') {
      const modal = await this.modalController.create({
        component: IntermittenceAppModalComponent,
        cssClass: 'modal-intermittence',
      })
      await modal.present()
    }
  }

  validateService(
    service: keyof IServicesAvailableStatus | keyof IServicesAvailableStatus[],
    intermittence: IServicesAvailableStatus
  ) {
    let showModals = false
    if (intermittence?.[EServicesAvailableStatus.ALL_SERVICES] === false) {
      showModals = true
    } else if (Array.isArray(service)) {
      const inactives = service.filter(s => intermittence?.[s as keyof IServicesAvailableStatus] === false)
      if (inactives?.length == service?.length) {
        showModals = true
      }
    } else {
      if (intermittence?.[service as keyof IServicesAvailableStatus] === false) {
        showModals = true
      }
    }
    return showModals
  }
}
