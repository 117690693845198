import { Injectable } from '@angular/core'
import { IResourceMethodObservable, ResourceAction, ResourceParams, ResourceRequestMethod } from '@ngx-resource/core'
import { ResourceBaseService } from '@services/resource.base.service'

@Injectable()
@ResourceParams({
  pathPrefix: 'monto_cash'
})
export class MontoCashResource extends ResourceBaseService {
  @ResourceAction({
    method: ResourceRequestMethod.Get,
    path: '/balance'
  })
  balance: IResourceMethodObservable<any, any> | any

  @ResourceAction({
    method: ResourceRequestMethod.Post,
    path: '/withdrawal'
  })
  withdrawal: IResourceMethodObservable<any, any> | any
}
