import { Injectable } from '@angular/core'
import { Capacitor } from '@capacitor/core'

@Injectable({ providedIn: 'root' })
export class PlatformService {
  /**
   * Get if application is running in browser or not. We only check if device
   * is not undefined because since cordova is not loaded on web we can assume
   * that if it's undefined then we're on a web browser.
   *
   * @return Whether the app is running on browser or not
   */
  get isBrowser(): boolean {
    return !Capacitor.isNativePlatform()
  }

  isMd(): boolean {
    return window.matchMedia('(min-width: 992px)').matches
  }

  isSm(): boolean {
    return window.matchMedia('(min-width: 768px)').matches
  }
}
