<div class="flex justify-end py-1.5 pb-0 z-10">
  <ion-button (click)="dismiss()" fill="clear">
    <ion-icon size="large" slot="icon-only" color="dark" name="close-outline"></ion-icon>
  </ion-button>
</div>
<div class="inner-content text-dark p-0 flex flex-col h-full">
  <div>
    <h2 class="text-center text-xl pb-3.5">Servicio no disponible</h2>
  </div>
  <div>
    <div class="w-[120px] h-[120px] mx-auto bg-primary rounded-full p-4 pt-3">
      <monto-icon name="intermittence" size="full" class="text-danger"></monto-icon>
    </div>
  </div>
  <div class="flex-grow pt-1.5">
    <p
      class="tracking-normal text-center text-md"
      *ngIf="(services$ | ngrxPush)[EServicesAvailableStatus.ALL_SERVICES] === false"
    >
      Lo sentimos, por el momento el servicio de Monto no se encuentra disponible, regresa más tarde.
    </p>
    <p
      class="tracking-normal text-center text-md"
      *ngIf="(services$ | ngrxPush)[EServicesAvailableStatus.ALL_SERVICES] === true"
    >
      Lo sentimos, por el momento este servicio no se encuentra disponible, regresa más tarde.
    </p>
  </div>
  <div class="w-full mx-auto py-1.5">
    <ion-button expand="block" color="light" (click)="dismiss()"> Entendido</ion-button>
  </div>
</div>
