import { inject } from '@angular/core'
import { SessionService } from '@services/session.service'
import { ActivatedRouteSnapshot, CanActivateFn, Router, RouterStateSnapshot } from '@angular/router'

export const canActivateAuth: CanActivateFn = async (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
  const router = inject(Router)
  const sessionService = inject(SessionService)
  const token = await sessionService.getSession()

  if (token) {
    return true
  } else {
    /**
     * IMPORTANT: Redirect to the delete account page info for Google play store requirement
     */
    //detect if the user is trying to delete his account
    if (state.url === '/u/user-profile/delete') {
      //if the user is not logged in, redirect to delete account page info
      router.navigateByUrl('/unauth/delete-account')
      return false
    }
    router.navigateByUrl('/unauth/login')
    return false
  }
}
