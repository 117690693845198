import { ChangeDetectionStrategy, Component, Input } from '@angular/core'

@Component({
  selector: 'app-content-web',
  templateUrl: './content-web.component.html',
  styleUrls: ['./content-web.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ContentWebComponent {
  @Input() customClass = ''
  @Input() containerClass = ''
  @Input() direction: 'center' | 'left' | 'full' = 'center'
  @Input() flat = true
  @Input() width = 'max-w-web-area'
}
