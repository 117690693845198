import type { EntityAdapter } from '@ngrx/entity'
import { createEntityAdapter } from '@ngrx/entity'
import { createReducer, on } from '@ngrx/store'
import {
  giftCardBiller,
  giftCardBillerGroup,
  giftCardClear,
  giftCardError,
  giftCardLoading,
  giftCardSetBalance,
  giftCardSetBillers,
  giftCardSetIdemPotency,
  giftCardSetSummary,
} from '@modules/gift-card/actions/gift-card.actions'
import { IGiftCardState } from '@modules/gift-card/interfaces/gift-card'

export const adapter: EntityAdapter<any> = createEntityAdapter<any>()

export const initialState: IGiftCardState = adapter.getInitialState({
  loading: false,
  entity: null,
  entities: null,
  balance: null,
  group: null,
  error: null,
  idemPotency: null,
})

const _giftCardReducer = createReducer(
  initialState,
  on(giftCardLoading, (state, { loading }) => ({
    ...state,
    loading,
  })),
  on(giftCardError, (state, { error }) => ({
    ...state,
    error,
  })),
  on(giftCardSetIdemPotency, (state, { idemPotency }) => ({
    ...state,
    idemPotency,
  })),
  on(giftCardSetBalance, (state, { balance }) => ({
    ...state,
    balance,
  })),
  on(giftCardClear, state => ({
    ...state,
    ...initialState,
  })),
  on(giftCardSetBillers, (state, { entities }) => ({
    ...state,
    entities,
  })),
  on(giftCardBillerGroup, (state, { group }) => ({
    ...state,
    group,
  })),
  on(giftCardBiller, (state, { entity }) => {
    if (entity == null) {
      return {
        ...state,
        entity: null,
      }
    }
    return {
      ...state,
      entity: {
        ...state.entity,
        ...entity,
      },
    }
  }),
  on(giftCardSetSummary, (state, { summary, card }) => {
    if (!summary) {
      return {
        ...state,
        entity: {
          ...state.entity,
          summary: null,
        },
      }
    }
    const commissionMonto = (summary.commission_monto || 0) + (summary.commission_monto_tax || 0)
    const commissionPaymentMethod = summary.commission_payment_method || 0
    const commissionSupplier = (summary.commission_supplier || 0) + (summary.commission_supplier_tax || 0)
    let commission = null

    let total = 0
    if (card) {
      commission = summary.commission || 0
      total = (state.entity?.amount || 0) + commissionPaymentMethod + summary.commission + commissionSupplier
    } else {
      total = (state.entity?.amount || 0) + commissionMonto + commissionSupplier
    }
    const discountDate = summary.discount_date

    return {
      ...state,
      entity: {
        ...state.entity,
        summary: {
          ...summary,
          commissionMonto,
          commissionSupplier,
          commissionPaymentMethod,
          total,
          discountDate,
          card,
          commission,
        },
      },
    }
  })
)

export const giftCardReducer = (state: any, action: any): any => _giftCardReducer(state, action)
export const { selectAll, selectIds, selectEntities } = adapter.getSelectors()
