<ion-header>
  <ion-toolbar color="white" class="pt-0">
    <ion-buttons slot="end">
      <ion-button slot="end" fill="clear" (click)="onDissmiss()">
        <ion-icon slot="icon-only" name="close-outline" color="dark" size="large"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>
<ion-content>
  <form *ngIf="formGroup" [formGroup]="formGroup" class="h-full">
    <div class="px-3 overflow-auto h-auto min-h-2/3">
      <h2 class="m-0 text-xl text-center leading-none">
        Confirmación de<br />
        seguridad
      </h2>
      <main class="flex h-max overflow-hidden">
        <ion-grid>
          <ion-row>
            <ion-col size="12">
              <div class="text-dark tracking-normal leading-[18px] py-3.5" [innerHTML]="text"></div>
            </ion-col>
            <ion-col size="12" *ngIf="error">
              <div class="alert-card--error mb-2">
                <img src="assets/icon/ic_alert-circle_red.svg" alt="" />
                <p>
                  <small> {{ error }} </small>
                </p>
              </div>
            </ion-col>
            <ion-col size="12">
              <monto-input-password
                label="Contraseña"
                placeholder="Ingresa tu contraseña"
                [autofill]="true"
                id="userPassword"
                name="userPassword"
                formControlName="userPassword"
              ></monto-input-password>
              <monto-input-error formControlName="userEmail"> </monto-input-error>
            </ion-col>
            <div
              class="flex-grow mt-auto flex items-center w-full justify-center pt-3.5"
              *ngIf="config$ | async | config: 'FLAG_BIOMETRIC'"
            >
              <app-biometric-login
                [confirm]="true"
                [error]="errorLoginBiometric"
                (loginBiometric)="loginWithBiometric()"
              ></app-biometric-login>
            </div>
          </ion-row>
        </ion-grid>
      </main>
    </div>
    <ion-footer class="px-[35px]">
      <ion-grid>
        <ion-row class="py-1">
          <ion-col size="12">
            <ion-button type="submit" (click)="onValidateCredentials()" [disabled]="formGroup.invalid">
              Confirmar
            </ion-button>
          </ion-col>
          <ion-col size="12">
            <ion-button color="light" type="button" (click)="onDissmiss()"> Cancelar </ion-button>
          </ion-col>
        </ion-row>
      </ion-grid>
    </ion-footer>
  </form>
</ion-content>
