import { UntypedFormGroup } from '@angular/forms'
import { IFbTokenForm } from '@declarations/fbToken-form.d'
import { IFbTokenPayload } from '@declarations/fbToken-payload'

export class TokenPayload {
  private tTokenForm: UntypedFormGroup

  private get tTokenFormValue(): IFbTokenForm {
    return this.tTokenForm.value
  }

  constructor(tokenForm: UntypedFormGroup) {
    this.tTokenForm = tokenForm
  }

  toJSONObject(): IFbTokenPayload {
    return {
      notification_token: {
        content: this.tTokenFormValue.content,
      },
    }
  }
}
