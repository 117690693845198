import { createReducer, on } from '@ngrx/store'
import { defaults, put, reset } from '../actions/config.actions'
import type { IConfig } from '../interfaces/config'

export const initialState: IConfig = <IConfig>{}
const _configReducer = createReducer(
  initialState,
  on(defaults, (state, props) => ({ ...props.payload })),
  on(reset, () => initialState),
  on(put, (state, props) => ({
    ...state,
    ...props.payload,
  }))
)

export const configReducer = (state: any, action: any): any => _configReducer(state, action)
