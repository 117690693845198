import { Component, Input, OnInit } from '@angular/core'
import { Router } from '@angular/router'
import { CommonModule } from '@angular/common'
import { IonButton, IonIcon, MenuController } from '@ionic/angular/standalone'

@Component({
  selector: 'btn-complete-profile',
  templateUrl: './btn-complete-profile.component.html',
  styleUrls: ['./btn-complete-profile.component.scss'],
  standalone: true,
  imports: [CommonModule, IonButton, IonIcon],
})
export class BtnCompleteProfileComponent {
  @Input() responsive: boolean = false
  constructor(private _menuController: MenuController, private _router: Router) {}

  onCompleteProfile() {
    this._router.navigate(['/u/locate-your-company'])
    this._menuController.close()
  }
}
