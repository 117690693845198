import { Injectable } from '@angular/core'
import { environment } from '@environments/environment'
import {
  IResourceMethodObservable,
  Resource,
  ResourceAction,
  ResourceGlobalConfig, ResourceHandler,
  ResourceParams,
  ResourceRequestMethod
} from '@ngx-resource/core'

export interface ISuccessResponse<T> {
  data: T
}

ResourceGlobalConfig.url = environment.API + '/api/v2/'

@Injectable()
@ResourceParams({
  pathPrefix: ''
})
export class ResourceBaseService extends Resource {
  @ResourceAction({
    method: ResourceRequestMethod.Get,
    path: '/'
  })
  list: IResourceMethodObservable<any, any> | any

  @ResourceAction({
    method: ResourceRequestMethod.Post,
    path: '/'
  })
  create: IResourceMethodObservable<any, any> | any

  override $getHeaders(headers: any): void {
    headers = {
      ...super.$getHeaders(),
      Accept: 'application/vnd.mto.v2+json,application/vnd.mto.v3+json',
      'X-Client': 'app'
    }

    return headers
  }

  constructor(requestHandler?: ResourceHandler) {
    super(requestHandler)
  }
}
