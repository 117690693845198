<ion-header>
  <ion-toolbar color="light" class="p-0">
    <ion-buttons slot="end">
      <ion-button (click)="onDismiss()" fill="clear">
        <ion-icon color="dark" size="large" slot="icon-only" name="close"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>
<div class="inner-content text-dark p-0 pb-[30px] gap-3.5">
  <div class="px-4 flex flex-col gap-3.5">
    <h2 class="text-center text-[22px] leading-7 m-0 p-0">¡Felicidades!</h2>
    <section class="rounded-full mx-auto w-[120px] h-[120px] bg-primary p-3.5">
      <monto-icon [size]="'full'" [color]="'light'" [name]="'trophy'"></monto-icon>
    </section>
    <div class="inner-content text-dark p-0 flex flex-col gap-3.5">
      <div class="text-center">
        <div [innerHTML]="redeemAction.text"></div>
      </div>

      <ion-button expand="block" color="light" (click)="onAction()">
        {{ redeemAction.button || 'Entendido' }}
      </ion-button>
    </div>
  </div>
</div>
