import {
  ClearJumpRecordCycle,
  clearSearchParams,
  pushRecords,
  recordClearPagination,
  recordNeedsRefresh,
  recordsClearAll,
  recordsClearAllEntities,
  recordsClearEntities,
  recordsLoading,
  setJumpRecordCycle,
  setRecordType,
  setRecords,
  setSearchParams,
} from '@modules/record/actions/record.actions'
import { createReducer, on } from '@ngrx/store'
import type { EntityAdapter } from '@ngrx/entity'
import { createEntityAdapter } from '@ngrx/entity'
import { ERecordOption, IRecordCycle, IRecordState } from '@modules/record/interfaces/record'

export const adapter: EntityAdapter<IRecordCycle> = createEntityAdapter<IRecordCycle>()

export const initialState: IRecordState = adapter.getInitialState({
  loading: true,
  searchParams: null,
  entities: {
    0: null,
    1: null,
    2: null,
  },
  type: +ERecordOption.GENERAL,
  pagination: {
    0: {
      limit: 5,
      offset: 0,
      count: 0,
    },
    1: {
      limit: 5,
      offset: 0,
      count: 0,
    },
    2: {
      limit: 5,
      offset: 0,
      count: 0,
    },
  },
  needsRefresh: false,
  jumpCycle: null,
})

const _recordReducer = createReducer(
  initialState,
  on(setRecords, (state, { entities }) => ({
    ...state,
    entities: { ...state.entities, [state.type]: entities },
  })),
  on(setJumpRecordCycle, (state, { entity }) => ({
    ...state,
    jumpCycle: entity,
  })),
  on(ClearJumpRecordCycle, state => ({
    ...state,
    jumpCycle: null,
  })),
  on(pushRecords, (state, { entities, pagination, option }) => ({
    ...state,
    entities: state.entities[option]
      ? { ...state.entities, [option]: [...(state.entities[option] || []), ...entities] }
      : { ...state.entities, [option]: entities },
    pagination: { ...state.pagination, [option]: pagination },
  })),
  on(recordsLoading, (state, { loading }) => ({
    ...state,
    loading,
  })),
  on(setRecordType, (state, { option }) => ({
    ...state,
    type: option,
  })),
  on(recordNeedsRefresh, (state, { needsRefresh }) => ({
    ...state,
    needsRefresh: needsRefresh,
    pagination: needsRefresh
      ? {
          ...state.pagination,
          [state.type]: {
            limit: 5,
            offset: 0,
            count: 0,
          },
        }
      : state.pagination,
  })),
  on(recordsClearAll, state => ({
    ...state,
    entities: {
      0: null,
      1: null,
      2: null,
    },
    searchParams: null,
    pagination: {
      0: {
        limit: 5,
        offset: 0,
        count: 0,
      },
      1: {
        limit: 5,
        offset: 0,
        count: 0,
      },
      2: {
        limit: 5,
        offset: 0,
        count: 0,
      },
    },
    needsRefresh: true,
  })),
  on(recordsClearAllEntities, state => ({
    ...state,
    entities: {
      0: null,
      1: null,
      2: null,
    },
    pagination: {
      0: {
        limit: 5,
        offset: 0,
        count: 0,
      },
      1: {
        limit: 5,
        offset: 0,
        count: 0,
      },
      2: {
        limit: 5,
        offset: 0,
        count: 0,
      },
    },
  })),
  on(recordsClearEntities, state => ({
    ...state,
    pagination: {
      ...state.pagination,
      [state.type]: {
        limit: 5,
        offset: 0,
        count: 0,
      },
    },
    entities: {
      ...state.entities,
      [state.type]: null,
    },
  })),
  on(recordClearPagination, state => ({
    ...state,
    pagination: {
      ...state.pagination,
      [state.type]: {
        limit: 5,
        offset: 0,
        count: 0,
      },
    },
  })),
  on(setSearchParams, (state, { searchParams }) => ({
    ...state,
    searchParams: {
      ...state.searchParams,
      ...searchParams,
    },
  })),
  on(clearSearchParams, state => ({
    ...state,
    searchParams: null,
  }))
)

export const recordReducer = (state: any, action: any): any => _recordReducer(state, action)
export const { selectAll, selectIds, selectEntities } = adapter.getSelectors()
