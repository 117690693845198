export const environment = {
  firebase: {
    apiKey: 'AIzaSyB_T_Vxj7gMzJvl7FBjcM0iWen0_G0SP6M',
    authDomain: 'monto-6af3f.firebaseapp.com',
    databaseURL: 'https://monto-6af3f.firebaseio.com',
    projectId: 'monto-6af3f',
    storageBucket: 'monto-6af3f.appspot.com',
    messagingSenderId: '773306973550',
    appId: '1:773306973550:web:b2513b03a5d554f98dd57f',
    measurementId: 'G-D5ETG7CJHB',
    vapidKey: 'BOk3cMdBrAu_mJBj6FjpPv9iMYRbquItrM_2CC2MrxcSNfb3zTlLv35C2l7oDK4wrR4zNainmHWv26a3IB4naK4',
  },
  production: true,
  develop: false,
  API: 'https://services.monto.mx/core',
  wepApp: 'https://app.monto.mx',
  cdnAssets: 'https://djmnx1dsj7ttn.cloudfront.net/',
  APP_VERSION: '4.6.6',
  COOKIE: {
    APPLICATION_TKN: '_tkn_',
    APPLICATION_TKN_REFRESH: '_tknr_',
    SESSION_TOKEN: '_session',
    SESSION_CHALLENGE_NAME: '_challenge_name',
    SESSION_IDENTIFIER: '_identifier',
    DASHBOARD: 'monto-session-dashboard',
    APPLICATION: 'monto-session-application',
    APPLICATION_REFRESH: 'monto-session-application-refresh',
  },
  posthogKey: 'phc_niW4w4tBKmtNhv6JEtWM7IwGmk5pZkrX4WE5QsDspV8',
  clarityKey: 'hng9lwk31c',
  stripeKey:
    'pk_live_51ON2IOAFmo99VLd20b4isVP3L0yada9wWrJZUlkeLqUT1qdtxvLmFNMP2jvYO9gyLh9jdVxH7WSuR0Mm6g8fgj8g00OEhITIQa',
}
