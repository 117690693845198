import type { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http'
import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { environment } from '@environments/environment'
import { NavController } from '@ionic/angular'
import { removeSession } from '@modules/user/actions/user.actions'
import { IUserState } from '@modules/user/interfaces/user'
import { Store } from '@ngrx/store'
import { SessionService } from '@services/session.service'
import { SplitPaneService } from '@services/split-pane.service'
import type { Observable } from 'rxjs'
import { from, throwError } from 'rxjs'
import { catchError, map, mergeMap, switchMap } from 'rxjs/operators'

@Injectable({ providedIn: 'root' })
export class AuthorizationInterceptor implements HttpInterceptor {
  constructor(
    private sessionService: SessionService,
    private _navCtrl: NavController,
    private _httpClient: HttpClient,
    private _splitPaneService: SplitPaneService,
    private _store: Store<{ user: IUserState }>
  ) {
    /** Do nothing */
  }

  /** NOTE: We change session from promise to observable because intercept
   *        needs an Observable as response and we can't use async/await
   *        here.
   */
  intercept(req: HttpRequest<string>, next: HttpHandler): Observable<HttpEvent<string>> {
    if (req.params.get('skipInterceptor')) {
      return next.handle(req)
    }
    return this.addHeader(req, next)
  }

  addHeader(req: HttpRequest<string>, next: HttpHandler): Observable<any> {
    const setAuthorizationHeader = (token: string): HttpRequest<string> => {
      const authorizationHeaders = {
        headers: req.headers.append('Authorization', `Bearer ${token}`),
      }
      return req.clone(authorizationHeaders)
    }
    const requestWithAuthorizationHeader = (request: HttpRequest<string>): Observable<HttpEvent<string>> =>
      next.handle(request)
    const requestObservable = from(this.sessionService.getSession()).pipe(
      map(setAuthorizationHeader),
      mergeMap(requestWithAuthorizationHeader),
      catchError((error: HttpErrorResponse) => {
        if (error.status === 403) {
          return this.refreshToken(req, next)
        }
        return throwError(error)
      })
    )

    return requestObservable
  }

  refreshToken(req: HttpRequest<string>, next: HttpHandler): Observable<any> {
    return from(this.sessionService.getSessionRefresh()).pipe(
      map(token => {
        if (!token) {
          this.removeSession()
          return throwError('no token for refresh')
        }
        return token
      }),
      mergeMap((token: string) =>
        this._httpClient.post(`${environment.API}/sessions/refresh_token`, { refresh_token: token })
      ),
      map((response: any) => {
        this.sessionService.setSession(response?.token)
        return response
      }),
      switchMap(() => this.addHeader(req, next)),
      catchError((errorRefresh: HttpErrorResponse) => {
        this.removeSession()
        return throwError(errorRefresh)
      })
    )
  }

  removeSession(): void {
    this._store.dispatch(removeSession())
    this._navCtrl.navigateRoot('/unauth/login')
  }
}
