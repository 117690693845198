import { NgModule } from '@angular/core'

import { SanitizeHtmlPipe } from '@pipes/sanitize-html.pipe'
import { ReadMorePipe } from '@pipes/read-more.pipe'
import { CurrencyCustomPipe } from '@pipes/currency.pipe'
import { DateCustomPipe } from '@pipes/date.pipe'

@NgModule({
  imports: [SanitizeHtmlPipe, ReadMorePipe, CurrencyCustomPipe, DateCustomPipe],
  exports: [SanitizeHtmlPipe, ReadMorePipe, CurrencyCustomPipe, DateCustomPipe],
})
export class SharedPipesModule {}
