<ion-card class="ion-margin-vertical">
  <ion-item-sliding>
    <ion-item lines="none">
      <ion-label class="ion-padding ion-no-margin">
        <p class="ion-text-uppercase ion-text-end">
          <small>
            <ion-skeleton-text animated class="w-1/6" class="ion-float-right"></ion-skeleton-text>
          </small>
        </p>
        <p>
          <ion-skeleton-text animated class="w-full"></ion-skeleton-text>
        </p>
        <p>
          <ion-skeleton-text animated class="w-full"></ion-skeleton-text>
        </p>
        <p class="ion-text-end">
          <ion-text slot="end" color="primary" expand="block">
            <strong
              ><small> <ion-skeleton-text animated class="w-1/6" class="ion-float-right"></ion-skeleton-text> </small
            ></strong>
          </ion-text>
        </p>
      </ion-label>
    </ion-item>
  </ion-item-sliding>
</ion-card>
