import { Component, inject, Input } from '@angular/core'
import { IonicModule, ModalController } from '@ionic/angular'
import { CommonModule } from '@angular/common'
import { App } from '@capacitor/app'
import { IconModule } from '@atoms/icon/icon.module'
import { ContentMainComponent } from '@components/content-main/content-main.component'
import { SanitizeHtmlPipe } from '@pipes/sanitize-html.pipe'
import { servicesAvailableSelector } from '@modules/user/selectors/user.selectors'
import { Store } from '@ngrx/store'
import { PushPipe } from '@ngrx/component'
import { EServicesAvailableStatus } from '@modules/user/models/services'

@Component({
  selector: 'app-intermittence-app-modal',
  templateUrl: './intermittence-app-modal.component.html',
  styleUrls: ['./intermittence-app-modal.component.scss'],
  standalone: true,
  imports: [CommonModule, IonicModule, IconModule, ContentMainComponent, SanitizeHtmlPipe, PushPipe],
})
export class IntermittenceAppModalComponent {
  @Input() force = false
  @Input() url = ''
  _store = inject(Store)
  services$ = this._store.select(servicesAvailableSelector)
  EServicesAvailableStatus = EServicesAvailableStatus
  public modalController = inject(ModalController)

  dismiss(): void {
    this.modalController.dismiss({
      dismissed: true,
    })
  }
}
