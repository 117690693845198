import { createAction, props } from '@ngrx/store'
import {
  IBenefit,
  IBenefitCategory,
  IBenefitPlanSummary,
  IBenefitPurchase,
  IBenefitSubscriptionDetails,
  IBenefitSuccess,
} from '@modules/benefit/interfaces/benefit'
import { IGiftCard } from '@modules/benefit/interfaces/gift-card'
import { ICard } from '@modules/benefit/interfaces/card'
import { Update } from '@ngrx/entity'

export const benefitLoading = createAction('[Benefits] Set Loading', props<{ loading: boolean }>())
export const setBenefit = createAction('[Benefits] Set Benefit', props<{ benefit: IBenefit }>())
export const likeBenefit = createAction('[Benefits] Like Benefit', props<{ benefit: IBenefit }>())
export const unlikeBenefit = createAction('[Benefits] Unlike Benefit', props<{ benefit: IBenefit }>())
export const setBenefitConfirmSubscriptionMode = createAction('[Benefits] Set Benefit', props<{ confirm: boolean }>())
export const setBenefitPurchase = createAction('[Benefits] Set Purchase', props<{ purchase: IBenefitPurchase }>())
export const clearBenefitPurchase = createAction('[Benefits] Clear Purchase')
export const setBenefitList = createAction('[Benefits] Set Benefits List', props<{ benefits: IBenefit[] }>())
export const setBenefitCategories = createAction(
  '[Benefits] Set Benefits Categories',
  props<{ categories: IBenefitCategory[] }>()
)
export const setBenefitCategory = createAction(
  '[Benefits] Set Benefits Category',
  props<{ category: IBenefitCategory }>()
)
export const setBenefitPremiumPlanSummary = createAction(
  '[Benefits] Set Premium Plan Summary',
  props<{ summary: IBenefitPlanSummary }>()
)
export const setBenefitSubscriptionDetails = createAction(
  '[Benefits] Set Benefits Subscription Details',
  props<{ details: IBenefitSubscriptionDetails }>()
)
export const clearBenefitCategory = createAction('[Benefits] Clear Benefits Category')
export const updateBenefit = createAction('[Benefit] Update Benefit', props<{ update: Update<IBenefit> }>())
export const setBenefitCards = createAction('[Benefits] Set Benefit Cards', props<{ cards: IGiftCard[] | ICard[] }>())
export const setBenefitSuccess = createAction('[Benefits] Set Benefit Success', props<{ success: IBenefitSuccess }>())
export const clearBenefitCards = createAction('[Benefits] Clear Benefit Cards')
export const clearBenefit = createAction('[Benefits] Clear Benefit')
export const clearBenefitPremiumPlanSummary = createAction('[Benefits] Clear Premium Plan Summary')
export const clearBenefitSubscriptionDetails = createAction('[Benefits] Clear Benefit Subscription Details')
