import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostListener,
  Input,
  OnInit,
  Renderer2,
  RendererStyleFlags2,
  AfterViewInit,
} from '@angular/core'
import { Router } from '@angular/router'
import { Browser } from '@capacitor/browser'
import { ModalController } from '@ionic/angular'
import { montoCashLoadBalance } from '@modules/monto-cash/actions/monto-cash.actions'
import { INotification } from '@modules/notification/interfaces/notification'
import { IWithdrawalState } from '@modules/withdrawal/interfaces/withdrawal'
import { Store } from '@ngrx/store'
import { CommonModule } from '@angular/common'
import { SanitizeHtmlPipe } from '@pipes/sanitize-html.pipe'
import { IonButton, IonContent, IonIcon } from '@ionic/angular/standalone'

export interface IStylePartsCustom {
  notification?: { [klass: string]: any }
  close?: { [klass: string]: any }
  buttonClose?: { [klass: string]: any }
  container?: { [klass: string]: any }
  title?: { [klass: string]: any }
  body?: { [klass: string]: any }
  image?: { [klass: string]: any }
  footer?: { [klass: string]: any }
  buttons?: { [klass: string]: any }
  button?: { [klass: string]: any }
  main?: { [klass: string]: any }
  imageBottom?: { [klass: string]: any }
  imageTop?: { [klass: string]: any }
}

export interface IPropsStyle {
  template: 'default' | 'only-body' | 'only-image' | 'image-background'
  theme?: 'white' | 'dark'
  media?: {
    [resolution: number]: IStylePartsCustom
  }
  style?: IStylePartsCustom
}

@Component({
  selector: 'app-notification-modal',
  templateUrl: './notification-modal.component.html',
  styleUrls: ['./notification-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [CommonModule, SanitizeHtmlPipe, IonContent, IonButton, IonIcon],
  providers: [SanitizeHtmlPipe],
})
export class NotificationModalComponent implements OnInit, AfterViewInit {
  custom: IPropsStyle = {
    template: 'default',
    theme: 'white',
  }

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.onMedia(window.innerWidth)
  }

  @Input() notification!: INotification
  customBack!: IPropsStyle

  image = false
  imageBottom = false
  imageTop = false

  constructor(
    protected _modalController: ModalController,
    protected _router: Router,
    private _store: Store<{ withdrawal: IWithdrawalState }>,
    protected el: ElementRef,
    protected _cdr: ChangeDetectorRef,
    private _renderer: Renderer2
  ) {}

  callActions(action: string): void {
    switch (action) {
      //update monto cash when receive notification
      case 'CALL_UPDATE_MONTO_CASH':
        this._store.dispatch(montoCashLoadBalance())
        break
      default:
        break
    }
  }

  /**
   * Handle action when click in button
   * @param {string} link
   * @returns {Promise<void>}
   */
  async onAction(link: string): Promise<void> {
    //close modal
    this.onDismiss()
    if (link && link.startsWith('CALL_UPDATE')) {
      this.callActions(link)
      return
    }

    if (!link) {
      return
    }
    //return if dismiss
    if (link === 'dismiss') {
      return
    }

    //check if url external open browser
    if (link.startsWith('https://') || link.startsWith('http://')) {
      await Browser.open({ url: link })
      return
    }

    //if navigate in app navigate
    setTimeout(() => {
      this._router.navigateByUrl(link)
    }, 700)
  }

  onDismiss(): void {
    this._modalController.dismiss({
      dismissed: true,
    })
  }

  /**
   * Check if the image is a valid URL, if not, set the imageBottom flag to false, for render in innerHTML
   */
  chekImageBottom() {
    if (this.custom?.style?.imageBottom?.src) {
      try {
        new URL(this.custom?.style?.imageBottom?.src)
        this.imageBottom = true
      } catch (_) {
        this.imageBottom = false
      }
    }
  }

  /**
   * Check if the image is a valid URL, if not, set the imageTop flag to false, for render in innerHTML
   */
  chekImageTop() {
    if (this.custom?.style?.imageTop?.src) {
      try {
        new URL(this.custom?.style?.imageTop?.src)
        this.imageTop = true
      } catch (_) {
        this.imageTop = false
      }
    }
  }

  /**
   * Check if the image is a valid URL, if not, set the image flag to false, for render in innerHTML
   */
  chekImage() {
    if (this.notification && this.notification.image) {
      try {
        new URL(this.notification.image)
        this.image = true
      } catch (_) {
        this.image = false
      }
    }
  }

  ngOnInit(): void {
    this.custom = {
      ...this.custom,
      ...this.notification.event_data,
    }
    this.customBack = {
      ...this.custom,
    }
    this.chekImage()
    this.chekImageBottom()
    this.chekImageTop()
    this.onMedia(window.innerWidth)
    this._cdr.detectChanges()
  }

  /**
   * Check media query for override style
   * @param {number} width
   */
  onMedia(width: number): void {
    try {
      if (!this.custom.media) {
        return undefined
      }
      //get medias
      const keysResolution = Object.keys(this.custom.media)
      //get custom media
      const resolution = keysResolution.find(res => +res <= width)
      if (resolution) {
        //get keys custom media
        const mediaCustomMedia = this.custom.media[+resolution]
        const keysCustomMedia = Object.keys(mediaCustomMedia)

        //iterate keys parts for override style
        keysCustomMedia.forEach(key => {
          //override style with custom media
          const styelNew = {
            ...this.customBack.style[key as keyof IStylePartsCustom],
            ...mediaCustomMedia[key as keyof IStylePartsCustom],
          }
          //set new style
          this.custom.style = {
            ...this.custom.style,
            [key as keyof IStylePartsCustom]: styelNew,
          }
        })
      } else {
        //if not found resolution set default
        this.custom = { ...this.customBack }
      }
    } catch (e) {
      this.custom = this.customBack
      console.warn(e)
    }
    this._cdr.detectChanges()
    this.renderStyle()
  }

  ngAfterViewInit() {
    this.renderStyle()
  }

  /**
   * Renderiza los estilos de las partes del componente
   */
  renderStyle() {
    this.el.nativeElement.querySelectorAll('[part]').forEach((el: HTMLElement) => {
      el.removeAttribute('style')
      const namePart = el.getAttribute('part')
      const style = this.custom?.style?.[namePart as keyof IStylePartsCustom]
      if (style) {
        Object.keys(style).forEach(key => {
          this._renderer.setStyle(
            el,
            key
              .replace(/([a-z])([A-Z])/g, '$1-$2')
              .replace(/([A-Z])([A-Z][a-z])/g, '$1-$2')
              .toLowerCase(),
            style[key],
            RendererStyleFlags2.Important
          )
        })
      }
    })
  }
}
