import { createAction, props } from '@ngrx/store'
import type { INotification } from '../interfaces/notification'

export const notificationsLoad = createAction('[Notification List] Load Notification via Service')
export const notificationsLoaded = createAction(
  '[Notification List] Set list Notification',
  props<{
    entities: null | INotification[]
  }>()
)
export const notificationsClear = createAction('[Notification List] Clear list')
export const notificationsLoading = createAction('[Notification List] Set Loading', props<{ loading: boolean }>())
export const notificationIndicatorNew = createAction('[Notification] Indicator New', props<{ indicator: boolean }>())

export const notificationReceived = createAction(
  '[Notification] Notification Received',
  props<{ notification: INotification }>()
)
