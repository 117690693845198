import { ChangeDetectionStrategy, Component, Input } from '@angular/core'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { Router, RouterModule } from '@angular/router'
import { IonicModule, ModalController } from '@ionic/angular'
import { CommonModule } from '@angular/common'
import { ContentWebModule } from '@components/content-web/content-web.module'
import { ToolbarModule } from '@components/toolbar/toolbar.module'
import { IconHelpModule } from '@components/icon-help/icon-help.component.module'
import { LetDirective } from '@ngrx/component'
import { environment } from '@environments/environment'
import { IconModule } from '@atoms/icon/icon.module'
import { RedeemAction } from '@modules/redeem/redeem'

@Component({
  selector: 'app-redeem-success',
  templateUrl: './redeem-success.component.html',
  styleUrls: ['./redeem-success.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    IonicModule,
    ContentWebModule,
    ToolbarModule,
    RouterModule,
    IconHelpModule,
    LetDirective,
    IconModule,
  ],
})
export class RedeemSuccessComponent {
  @Input() redeemAction!: RedeemAction

  constructor(protected _modalController: ModalController, protected _router: Router) {}

  /**
   * Dismiss the modal
   */
  onDismiss(): void {
    this._modalController.dismiss({
      dismissed: true,
    })
  }

  /**
   * Redirect to the action URL
   */
  onAction(): void {
    this.onDismiss()

    if (this.redeemAction.url) {
      this._router.navigate([this.redeemAction.url])
    }
  }
}
