import { Injectable } from '@angular/core'
import { removeSession } from '@modules/user/actions/user.actions'
import { Store } from '@ngrx/store'
import { IConfig } from '@modules/config/interfaces/config'
import { IUserState } from '@modules/user/interfaces/user'
import { SplitPaneService } from '@services/split-pane.service'
import { NavController } from '@ionic/angular'
import { SessionService } from '@services/session.service'

@Injectable()
export class LogoutService {
  constructor(
    private _store: Store<{ config: IConfig; user: IUserState }>,
    public splitPaneService: SplitPaneService,
    protected _navCtrl: NavController,
    protected _sessionService: SessionService
  ) {}

  async logOut() {
    this._store.dispatch(removeSession())
    await this._sessionService.destroySession()
    this._navCtrl.navigateRoot('/', {
      replaceUrl: true,
      queryParams: { logout: true },
    })
  }
}
