<div
  class="flex-grow mt-auto flex items-center w-full justify-center"
  *ngIf="biometricIsAvailableAndActivated$ | async"
>
  <div class="flex flex-col gap-3.5">
    <a
      href="javascript:"
      class="flex items-center font-normal gap-1 underline justify-center"
      (click)="loginBiometric.emit()"
    >
      <ng-container [ngSwitch]="biometricService.availableBiometricType">
        <ng-container *ngSwitchCase="BiometryType.FACE_ID">
          <monto-icon *ngIf="platform === 'android'" name="face"></monto-icon>
          <monto-icon *ngIf="platform === 'ios'" name="faceID"></monto-icon>
          {{ error ? 'Reintentar' : (confirm ? 'Confirmar' : 'Entrar') + ' con Face ID' }}
        </ng-container>
        <ng-container *ngSwitchCase="BiometryType.FACE_AUTHENTICATION">
          <monto-icon *ngIf="platform === 'android'" name="face"></monto-icon>
          <monto-icon *ngIf="platform === 'ios'" name="faceID"></monto-icon>
          {{ error ? 'Reintentar' : (confirm ? 'Confirmar' : 'Entrar') + ' con reconocimiento facial' }}
        </ng-container>
        <ng-container *ngSwitchDefault>
          <ion-icon name="finger-print-outline"></ion-icon>
          {{ error ? 'Reintentar' : (confirm ? 'Confirmar' : 'Entrar') + ' con huella digital' }}
        </ng-container>
      </ng-container>
    </a>
    <div>
      <ng-container *ngIf="error">
        <div class="text-danger text-center">
          <ng-container [ngSwitch]="biometricService.availableBiometricType">
            <ng-container *ngSwitchCase="BiometryType.FACE_ID">No pudimos autenticar tu Face ID</ng-container>
            <ng-container *ngSwitchCase="BiometryType.FACE_AUTHENTICATION">No pudimos autenticar tu Face </ng-container>
            <ng-container *ngSwitchDefault>No pudimos autenticar tu huella digital</ng-container>
          </ng-container>
        </div>
      </ng-container>
    </div>
  </div>
</div>
