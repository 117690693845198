import type { ILoginResponse } from '@declarations/login-response'
import { UserStatus } from '@modules/user/interfaces/user'

export class UserToken {
  isFirstTimeLogin: boolean
  session: string
  has_mfa: boolean
  status: UserStatus
  challenge_name: string

  token: string

  changePassword: string
  refresh_token: string

  constructor(atrributes: ILoginResponse) {
    this.isFirstTimeLogin = atrributes.is_first_time_login
    this.token = atrributes.token
    this.session = atrributes.session
    this.challenge_name = atrributes.challenge_name
    this.has_mfa = atrributes.has_mfa
    this.status = atrributes.status
    this.refresh_token = atrributes.refresh_token
    this.changePassword = atrributes.change_password
  }
}
