import { Injectable } from '@angular/core'
import { fetchAndActivate } from '@angular/fire/remote-config'
import type { AllParameters } from 'rxfire/remote-config'
import { getAll } from 'rxfire/remote-config'
import type { IConfig, OnConfig } from '../interfaces/config'
import { EConfig } from '../interfaces/config'
import { ConfigService } from './config.service'
import { environment } from '@environments/environment'

@Injectable({ providedIn: 'root' })
export class ConfigWebService extends ConfigService implements OnConfig {
  /**
   * Fetch data remote
   */
  override fetch(): void {
    fetchAndActivate(this.remoteConfig)
      .then(async () => {
        getAll(this.remoteConfig).subscribe(
          (config: any) => {
            // set state
            config = this.parse(config)
            this.setConfig(config)
          },
          () => {
            this.defaultRemote()
          }
        )
      })
      .catch(() => {
        this.defaultRemote()
      })
  }

  /**
   * Parse data for web
   *
   * @param config
   */
  override parse(config: AllParameters): any {
    Object.keys(config).forEach(key => {
      let val: any = config[key]
      if (key in EConfig) {
        if (EConfig[key as keyof typeof EConfig] === 'boolean') {
          val = val.asBoolean()
        } else if (EConfig[key as keyof IConfig] === 'number') {
          val = val.asNumber()
          // @ts-ignore
        } else if (EConfig[key] === 'string') {
          val = val.asString()
        } else if (EConfig[key as keyof IConfig] === 'object') {
          if (val.asString()) {
            val = JSON.parse(val.asString())
          } else {
            val = null
          }
        } else {
          val = val.asString()
        }
      } else {
        if (environment.develop) {
          console.warn(`${key} no defined in src/config.json`)
        }
      }
      config[key] = val
    })
    return config
  }
}
