<label *ngIf="label" [for]="id" [class]="customClass">
  <div class="input-title">
    <span class="input-name">
      {{ label }}
      <span *ngIf="required" class="text-danger">*</span>
    </span>
  </div>
</label>
<div class="input-group">
  <ion-input
    [attr.maxlength]="maxlength"
    [attr.name]="name ? name : id"
    [type]="showPassword ? 'text' : 'password'"
    [readonly]="readonly"
    [disabled]="!!disabled"
    [id]="id"
    [value]="value"
    [autocomplete]="autocomplete"
    (input)="changed($event)"
    [placeholder]="placeholder"
  >
  </ion-input>
  <div>
    <div class="input-group-append hide-password">
      <span class="input-group-text">
        <img
          class="mr-1 mt-[3px]"
          (click)="toggleShowPasswordType()"
          *ngIf="showPassword"
          src="assets/icon/ic_eye_open_gray.svg"
          alt=""
        />
        <img
          class="mr-1"
          (click)="toggleShowPasswordType()"
          *ngIf="!showPassword"
          src="assets/icon/ic_closed_eye_gray.svg"
          alt=""
        />
      </span>
    </div>
  </div>
</div>
