import { Component, Input, OnInit, ViewChild, forwardRef } from '@angular/core'
import { NG_VALIDATORS, NG_VALUE_ACCESSOR } from '@angular/forms'
import { InputMixinClass } from '@components/atoms/shared/inputMixinClass'
import { IonInput } from '@ionic/angular'

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'monto-input-text',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      // eslint-disable-next-line no-use-before-define
      useExisting: forwardRef(() => InputTextComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      // eslint-disable-next-line no-use-before-define
      useExisting: InputTextComponent,
      multi: true,
    },
  ],
  templateUrl: './input-text.component.html',
  styleUrls: ['./input-text.scss'],
})
export class InputTextComponent extends InputMixinClass {
  @Input() type: 'text' | 'email' | 'file' | 'hidden' | 'tel' | 'url' = 'text'

  @Input() override inputmode?:
    | 'decimal'
    | 'email'
    | 'none'
    | 'numeric'
    | 'search'
    | 'tel'
    | 'text'
    | 'url'
    | undefined = undefined

  @Input() customClass?: string
}
